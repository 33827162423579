import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UploadModern from '../components/UploadModern';
import {useDropzone} from 'react-dropzone';
// import PreviewThumb from '../components/PreviewThumb';
import AppGrid from '@crema/core/AppGrid';
import { uploadService } from 'redux/actions';
// import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {Box, alpha} from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

const SimpleUploadComponent = ({schema}) => {
  const [error, setError] = useState("");
  const [fileId, setFileId] = useState (null);
  const dispatch = useDispatch();
  const max_size = schema.settings.maxSize || 10
  const dropzone = useDropzone({
    accept: schema.settings.type || 'text/csv',
    multiple: false,
    maxSize: max_size * 1024 * 1024,
    onDropRejected: () => {
      schema.getParent ().__setError__ (schema.__name, "Maximum File size limit " + max_size + "mb");
    },
    onDrop: (acceptedFiles) => {
      setError ("")
      acceptedFiles.forEach ((acceptedFile) => {
        let form_data = new FormData();
        form_data.append("file", acceptedFile);
        schema.getParent().__backdrop__ ({ isOpen: true, message: "Uploading ... "});
        dispatch (uploadService ("__upload__", form_data, 
          (success) => {
            schema.setPropertyValue (success.data);
            setFileId (success.data);
            schema.getParent ().__setError__ (schema.__name, "");
            schema.getParent().__backdrop__ ({ isOpen: false});
          }, (failure)=> {
            schema.getParent().__backdrop__ ({ isOpen: false});
            console.log (failure)
            setError ("File not uploaded ... Something went wrong")
          }));
      })
    },
  });

  return (
    <>
      {schema && !schema.behaviour?.hidden && <>
        <Typography variant='h5' gutterBottom color='text.secondary'>
          {schema.settings.label}
        </Typography>
        <section className='container' style={{cursor: 'pointer'}}>
        {!fileId && <UploadModern
          uploadText={(schema.settings.help || "Drag n drop some files here, or click to select files")}
          dropzone={dropzone}
        />}
        {(schema.error || error) && <Typography sx={{fontSize: 12}} color="#ef5350" gutterBottom >
            {schema.error || error}
        </Typography>}
        <AppGrid
          sx={{
            width: '100%',
            position: 'relative',
            transition: 'all 0.5s ease'
          }}
          data={fileId?[fileId]:[]}
          column={1}
          itemPadding={5}
          renderRow={(fileId) => (
            <Box
              sx={{
                borderBottom: (theme) => `solid 1px ${alpha(theme.palette.common.black, 0.15)}`,
                backgroundColor: (theme) => schema.settings?.backgroundColor || theme.palette.primary.main,
                color: (theme) => schema.settings?.color || theme.palette.primary.contrastText,
              }}
            >
              <Box sx={{ width: '100%', maxWidth: {lg: 1140, xl: 1420}, mx: 'auto'}}>
                <Alert
                  sx={{
                    backgroundColor: 'transparent !important',
                    textAlign: schema.settings?.textAlign || 'center',
                    color: 'inherit',
                    '& .MuiAlert-message': {
                      flex: 1,
                    },
                    '& .MuiAlert-action': {
                      ml: 2.5,
                    },
                  }}
                  icon={false}
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => {
                      schema.setPropertyValue (null);
                      setFileId (null);
                    }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  <Box sx={{p:1}}><Typography variant="h6" sx={{whiteSpace: 'pre-line'}}>{fileId.filename} successfully uploaded ...</Typography></Box>
                </Alert>
              </Box>
            </Box>
          )}
        />
        </section> </>}
    </>
  )
}

export default SimpleUploadComponent;

SimpleUploadComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};