import React from 'react';
import Typography from '@mui/material/Typography';
import Editor from "@monaco-editor/react";
import PropTypes from 'prop-types';

export default function JSEditor ({rule, onUpdateRule, onInit, readonly=false}) {
    const validateRule = (rule, newValue) => {
        try{
            rule.script = newValue;
            rule.compiled = window.Babili.transform (newValue).code;
            rule.error = null;
            // console.log (rule.compiled)
            onUpdateRule ();
        } catch (e){
            // console.log (e.message);
            rule.error = e.message;
            rule.compiled = null;
            onUpdateRule ();
        }
    }
    const handleEditorDidMount = (editor, monaco) => {
        if (onInit) onInit (editor, monaco);
    }
  
  return (
    <>
        <Typography variant='h6' gutterBottom component='div' color='grey.500'>Please write your rule below (Javascript)</Typography>
        <Editor
            theme="vs-dark"
            language="javascript"
            key={rule.id  + "_script"}
            value={rule.script}
            height="50vh"
            options = {{
                minimap: { enabled: false },
                readOnly: readonly
            }}
            onChange={(script) => validateRule (rule, script)}
            onMount={handleEditorDidMount}
        />
        <Typography variant='h6' gutterBottom  component='div' color="red"><pre>{rule.error || ""}</pre></Typography>
    </>
  );
}

JSEditor.propTypes = {
    onUpdateRule: PropTypes.func.isRequired,
    onInit: PropTypes.func,
    rule: PropTypes.object.isRequired,
    readonly: PropTypes.bool
};