import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export default function Placeholder({value, onChange}) {
  return (
    <TextField fullWidth
      sx={{
        width: '100%',
        mt:3,
        position: 'relative',
        transition: 'all 0.5s ease'
      }}
      id='label'
      label='Attribute Placeholder'
      variant='outlined'
      value={value}
      onChange={(e)=>onChange ("placeholder", e.target.value)}
      placeholder="Enter placeholder here"
      helperText="Enter placeholder to display inside the widget greyed out"
      InputProps={{ inputProps: { maxLength: 100 } }}
    />
  );
}

Placeholder.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};