import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UploadModern from '../components/UploadModern';
import {useDropzone} from 'react-dropzone';
import PreviewThumb from '../components/PreviewThumb';
import AppGrid from '@crema/core/AppGrid';
import { uploadService } from 'redux/actions';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';

const PreviewImageUploadComponent = ({schema}) => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const dropzone = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setError ("")
      acceptedFiles.forEach ((acceptedFile) => {
        let form_data = new FormData();
        form_data.append("file", acceptedFile);
        dispatch (uploadService ("__upload__", form_data, (success) => {
          let value = schema.getPropertyValue () || [];
          value.push (success.data);
          schema.setPropertyValue (value);
        }, (failure)=> {
          console.log (failure)
          setError ("File not uploaded ... Something went wrong")
        }));
      })
    },
  });

  const onDeleteUploadFile = (fileId) => {
    schema.setPropertyValue (_.remove (schema.getPropertyValue (), fileId));
  };
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
  }, []);
  return (
    <>
      {schema && !schema.behaviour?.hidden && <section className='container' style={{cursor: 'pointer'}}>
      <UploadModern
        uploadText={(schema.settings.help || "Drag n drop some files here, or click to select files")}
        dropzone={dropzone}
      />
      {<Typography sx={{fontSize: 12}} color="#ef5350" gutterBottom >
          {error}
      </Typography>}
      <AppGrid
        sx={{
          width: '100%',
          position: 'relative',
          transition: 'all 0.5s ease'
        }}
        data={schema.getPropertyValue () || []}
        column={4}
        itemPadding={5}
        renderRow={(fileId) => (
          <PreviewThumb
            fileId={fileId}
            onDeleteUploadFile={onDeleteUploadFile}
            key={fileId}
          />
        )}
      />
      </section>}
    </>
  )
}

export default PreviewImageUploadComponent;

PreviewImageUploadComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};