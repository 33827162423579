import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import highchartsMore from "highcharts/highcharts-more"
import solidGauge from "highcharts/modules/solid-gauge";
import * as _ from "lodash";

const BasicChart = ({schema}) => {
  const [show, setShow] = useState(false);
  const [option, setOption] = useState({});
  schema.settings?.is3d && highcharts3d(Highcharts);
  HighchartsHeatmap(Highcharts);
  highchartsMore(Highcharts);
  solidGauge(Highcharts);
  
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
  }, []);
  
  useEffect (() => {
    if (schema.settings?.options){
      if (!schema.settings.options.plotOptions) schema.settings.options.plotOptions = {}
      if (!schema.settings.options.plotOptions.series) schema.settings.options.plotOptions.series = {};
      if (schema.onClick){
        schema.settings.options.plotOptions.series.cursor = 'pointer';
        schema.settings.options.plotOptions.series.events = { click: (e)=>{ schema.onClick (e.point)} }
      }
      _.transform (schema.settings.options.plotOptions, (result, v, k)=>{
        result [k] = _.assign (v || {}, { animation: false})
      }, {})
      setOption (schema.settings.options);
      setShow (true);
    }
  },[schema.settings]);
    
  return (
      <>
        {show && <HighchartsReact highcharts={Highcharts} options={_.assign ({credits: { enabled: false }}, option)} />}
      </>
  )
}

export default BasicChart;

BasicChart.propTypes = {
    schema: PropTypes.object.isRequired
};