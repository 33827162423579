import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Editor from "@monaco-editor/react";
import Box from '@mui/material/Box';

const TextPad = ({schema}) => {
    const [value, setValue] = useState ("");
    useEffect (()=>{
        let text = "";
        (schema.getPropertyValue() || []).map ((v)=>{
            text += v
        })
        setValue (text);
    }, [schema]);
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Paper elevation={schema.settings?.elevation || 1}
                    sx={{m:2, p:2}}
                >
                    <>
                        <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                            {schema.settings.label || ""}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color={(schema.error)?"red":"text.secondary"} gutterBottom component={'span'} variant={'body2'}>
                            <pre>{(schema.settings.help || "") + " " + (schema.error || "") }</pre>
                        </Typography>
                        <Box mt={3}>
                            <Editor  
                                theme={schema.settings.theme || "vs-dark"}
                                language={schema.settings.language}
                                value={value}
                                height="50vh"
                                options = {{
                                    minimap: { enabled: false },
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </>
                </Paper>
            }
        </>
    )
}

export default TextPad;

TextPad.propTypes = {
    schema: PropTypes.object.isRequired,
};
