import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { useJsApiLoader } from '@react-google-maps/api';
import * as _ from "lodash";
import { useSelector } from 'react-redux';

const AddressSearch = ({schema}) => {
    const googleMapApiKey = useSelector(({content}) => content.googleMapApiKey);
    const [options, setOptions] = useState([]);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapApiKey,
        libraries: ["places", "drawing"]
    })

    const displaySuggestions = (predictions, status) => {
        if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            setOptions ([]);
            return;
        }
        let data = []
        predictions.map ((p)=>{
            data.push ({ id: p.place_id, name: p.description})
        })
        setOptions (_.cloneDeep (data));
    };

    const handleLookup = (event) => {
        if (event.target.value<3) return;
        const service = new window.google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: event.target.value }, displaySuggestions);
    }

    const onSelectAddress = (e, place) => {
        if (!place){ schema.setPropertyValue (""); if (schema.onChange) schema.onChange(true); return; }
        const geocoder = new window.google.maps.Geocoder(); 
        geocoder.geocode({ placeId: place.id })
                .then ((resp) => {
                    if (resp?.results?.length > 0 ){
                        place.name = resp.results [0].formatted_address;
                        place.location = { type: "Point", coordinates: [resp.results [0].geometry.location.lng(), resp.results [0].geometry.location.lat()]};
                        place.address_components = resp.results [0].address_components;
                        place.types = resp.results [0].types;
                        schema.setPropertyValue (place);
                        if (schema.onChange) schema.onChange(true);
                    } else {
                        console.log ("Something went wrong in geocoding");
                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e));
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && isLoaded && 
            <Box sx={{display:"flex" }}>
                <Autocomplete
                    id={schema.__id}
                    sx={{width: 1}}
                    options={options}
                    getOptionLabel={(option) => {
                        return "" + (option?.value || option?.name || option?.description || option?.id || option)
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return value === undefined || value === "" || option.id == value.id
                    }}
                    filterOptions={(options)=>{
                        return options;
                    }}
                    value={schema.getPropertyValue()||""}
                    disabled={schema.behaviour?.readonly}
                    onChange={onSelectAddress}
                    renderInput={(params) => (
                        <TextField
                            sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                            {...params}
                            variant={schema.settings.variant || 'outlined'}
                            label={schema.settings.label}
                            placeholder={schema.settings.placeholder}
                            helperText={(schema.settings.help || "") + (schema.error || "")}
                            required={schema.behaviour?.mandatory}
                            error={schema.error?true:false}
                            onChange={handleLookup}
                        />
                    )}
                />
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Box>
            }
        </>
    )
}

export default AddressSearch;

AddressSearch.propTypes = {
    schema: PropTypes.object.isRequired,
};