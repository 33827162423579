import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomWidget from './Custom';
import { Box } from '@mui/material';

export default function Settings ({schema, onChange, readonly}) {
    const [settings, setSettings] = useState({});
    useEffect (()=>{
        setSettings (schema);
    }, [schema]);

    return (
        <Box sx={{ width: '100%', mt:5 }}>
            {settings.widget == "standalone" && settings && <CustomWidget settings={settings} onChange={onChange} readonly={readonly}/>}
            {settings.widget == "composite" && settings && <CustomWidget settings={settings} onChange={onChange} readonly={readonly}/>}
        </Box>
    )
}

Settings.propTypes = {
    onChange: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired
};
