import React, {useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';
import * as _ from "lodash";
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';

export default function LayoutRenderer ({schema}) {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    useEffect (()=>{
        setTimeout (()=> { schema.onInit && schema.onInit (); })
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(items).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setItems (schema.datasource);
            organizeSubforms (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const organizeSubforms = (layout) => {
        // Preparing the forms based on the cardinality
        !schema.settings.hideLoading && schema.getParent().__backdrop__ ({ isOpen: true, message: (schema.settings.loadingText || "Loading ...")});
        if (_.isArray (layout) && layout.length>0){
            dispatch (callService (schema.settings.href.getName, {
                model: _.map(layout, "name")
            }, (success) => {
                if (_.isArray(success.data)){
                    handleSelection (layout, success.data);
                } else schema.getParent().__backdrop__ ({ isOpen: false});
            }, (failure)=> {
                console.log (failure)
            }));
        } else schema.getParent().__backdrop__ ({ isOpen: false});
    }

    const handleSelection = (layout, formIds) => {
        dispatch (callService (schema.settings.href.getSchema, {
            model: _.map(formIds, "id")
        }, (response) => {
            try{
                let properties = [];
                _.each (layout, (l)=>{
                    let sch = _.cloneDeep (_.find (response.data, (d)=> {
                        return d.identifier === l.name
                    }));
                    if (!sch.settings) sch.settings = {}
                    sch.settings.width = l.width || 12;
                    properties.push (sch);
                });
                schema.__addItem__ (properties);
                schema.getParent().__backdrop__ ({ isOpen: false});
            } catch (e){
                console.log ("Error", e)
            }
        }))
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Grid container spacing={0} justifyContent="center"
                    sx={{mt:schema.settings?.margin?.top || "0px", mb:schema.settings?.margin?.bottom || "5px"}}>
                    {schema.properties?.map((prop) => {
                        if (prop)
                        return (
                            <Grid item key={prop.__id} xs={12} sm={12} md={prop.settings.width} lg={prop.settings.width} xl={prop.settings.width} style={prop.settings.style}>
                                {Registry.createElement(prop.widget, {schema: prop, model: prop.value})}
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </>
    );
}

LayoutRenderer.propTypes = {
  schema: PropTypes.object.isRequired,
};