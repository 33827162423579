import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { DataGridPro, useGridApiRef, GridActionsCellItem, gridFilteredDescendantCountLookupSelector, useGridSelector, useGridApiContext, getDataGridUtilityClass, useGridRootProps  } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';
import IconButton from '@mui/material/IconButton';
import { unstable_composeClasses as composeClasses } from '@mui/material';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';

const useUtilityClasses = (ownerState) => {
    const { classes } = ownerState;
    const slots = {
        root: ['treeDataGroupingCell'],
        toggle: ['treeDataGroupingCellToggle'],
    };
    return composeClasses(slots, getDataGridUtilityClass, classes);
};  

function GridTreeDataGroupingCell(props) {
    const rootProps = useGridRootProps();
    const { id, rowNode, formattedValue, hideDescendantCount } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
    const classes = useUtilityClasses({ classes: rootProps.classes });
    const Icon = rowNode.childrenExpanded
        ? rootProps.slots.treeDataCollapseIcon
        : rootProps.slots.treeDataExpandIcon;

    const handleClick = (event) => {
        if (rowNode.type !== 'group') return;
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        event.stopPropagation();
    };

    return (
        <Box className={classes.root} sx={{ ml: rowNode.depth * 2 }}>
            <div className={classes.toggle}>
                {filteredDescendantCount > 0 && (
                <IconButton size="small" onClick={handleClick} tabIndex={-1}>
                    <Icon fontSize="inherit" />
                </IconButton>
                )}
            </div>
            <span>
                {formattedValue}
                {!hideDescendantCount && filteredDescendantCount > 0
                ? ` (${filteredDescendantCount})`
                : ''}
            </span>
        </Box>
    );
}
const getTreeDataPath = (row) => row.hierarchy;
GridTreeDataGroupingCell.propTypes = {
    id: PropTypes.any,
    rowNode: PropTypes.any,
    formattedValue : PropTypes.any,
    hideDescendantCount: PropTypes.any
};

export function CustomFooterComponent({value}) {
    return (
        <Box sx={{ p: 2, display: 'flex', border: 1, borderColor: 'grey.300', mt:5 }}>
            <Typography align="center">{value || "Loading ..."}</Typography>
        </Box>
    );
}
CustomFooterComponent.propTypes = {
    value: PropTypes.string
};

const HierarchicalGrid = ({schema}) => {
    let state = true;
    const apiRef = useGridApiRef();
    const {theme} = useThemeContext();
    const [rows, setRows] = useState([]);
    // const [value, setValue] = useState ();
    const [columnVisible, setColumnVisible] = useState({});
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [style, setStyle] = useState({
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: schema.settings?.header?.backgroundColor || theme.palette.primary.main,
          color: schema.settings?.header?.color || theme.palette.primary.contrastText
        }
    });
    const dispatch = useDispatch();
    useEffect(() => {
        (schema?.settings?.style) && setStyle (_.assign (style, schema?.settings?.style));
        if (schema?.settings?.columns){
            schema.settings.columns.map ((col)=> {
                if (col.field.includes ("."))
                    col.valueGetter = (params) => {
                        return _.get (params.row, params.field)
                    }
                if (col.special?.applyLocalDate){
                    col.renderCell = (params) => {
                        return (<>{params.value && moment.utc(params.value).format(col.special.format || 'llll').split(',')}</>)
                    }
                    }
            })
            if (schema.settings.action && _.filter(schema.settings.columns, a => a.field === 'actions').length === 0){
                schema.settings.columns.push({
                    field: "actions",
                    type: "actions",
                    headerName: schema.settings.action.headerName || "Operations",
                    width: schema.settings.action.width || 150,
                    getActions: (params) => {
                        const userActions = [];
                        schema.settings.action.actions.forEach ((action) => {
                            !action.hidden && userActions.push (
                                <GridActionsCellItem
                                    icon={<Tooltip title={action.label} placement="top"><FontAwesomeIcon icon={action.icon?.code} color={action.icon?.color}/></Tooltip>}
                                    label={action.label}
                                    onClick={() => {
                                        params?.row?.id && apiRef?.current?.setRowSelectionModel ([params.row.id]);
                                        action.event && schema[action.event] && schema[action.event] (params.row)
                                    }}
                                />
                            )
                        });
                        return userActions;
                    }
                });
            }
            setColumns(schema.settings.columns);
        }
        if (schema.settings?.columnVisibilityModel && !_.isEqual(schema.settings?.columnVisibilityModel, columnVisible)){
            setColumnVisible (schema.settings?.columnVisibilityModel);
        }
        setTimeout (()=> {
            schema.onInit && schema.onInit ();
            if (schema.onLoad) {
                let prevData = [];
                // setValue ("Loading ..."); 
                schema.onLoad (prevData, loadRows);
            }
            
        }, 100);
        schema.href && dispatch (callService (schema.href, null, (success) => {
                if (_.isArray(success.data)){
                    schema.getParent ().__setDataSource__ (schema.__name, success.data);
                }
            }, (failure)=> {
                console.log (failure)
            }));
        schema.getParent ().__setSettings__ (schema.__name, schema.settings);
        return () => { state = false }
    },[]);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            updateRows (schema.datasource);
        }
    },[schema.datasource]);

    const loadRows = (datasource) => {
        if (schema.settings.lazyloading && !_.isArray (datasource)){
            setLoading (!datasource.isFinal);
            if (datasource.info){
                // setValue (datasource.info);
                if (datasource.data && datasource.data.length>0 && state) {
                    let prevData = datasource.data;
                    setRows (prevData);
                    setTimeout (() => {
                        !schema.settings.lazyloading.manual && !datasource.isFinal && schema.onLoad (prevData, loadRows);
                    })
                } else return;
            }
        }
    }

    const updateRows = (datasource) => {
        if (_.isArray (datasource) 
            && schema.settings.__hasDataSourceUpdated 
            && !_(rows).xorWith(datasource, _.isEqual).isEmpty()){
                setRows (datasource);
                schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }
    
    const handleRowClick = (params) => {
        schema.setPropertyValue (params);
        schema.onRowClick && schema.onRowClick (params);
    };
      
    return (
        <>
        {schema && !schema.behaviour?.hidden && 
        <Box sx={schema.settings.sx || {height: schema.settings?.height || "80vh", width: "100%", m:1, p:1, mb:5 }}>
            <Typography variant='h5' gutterBottom color='text.secondary'>
                {schema.settings.label}
            </Typography>
            <Typography variant='h6' gutterBottom color='text.secondary'>
                {schema.settings.help}
            </Typography>
            <DataGridPro
                loading={loading}
                slots={{ loadingOverlay: LinearProgress, }}
                sx={style}
                treeData
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                onFilterModelChange={(filter)=>{
                    schema.onFilter && schema.onFilter (filter)}
                }
                groupingColDef={{
                    headerName: schema.settings?.group?.headerName || "",
                    headerAlign: schema.settings?.group?.headerAlign || "center",
                    flex: schema.settings?.group?.flex,
                    width: schema.settings?.group?.width,
                    renderCell: (params) => {
                        params.formattedValue = schema.settings?.group?.linkTo?<a href={schema.settings.group.linkTo + params.id }>{params.row [schema.settings?.group?.field]}</a>:params.row [schema.settings?.group?.field] || "Group"
                        params.hideDescendantCount = schema.settings?.group?.hideDescendantCount
                        return (<GridTreeDataGroupingCell {...params}/>)
                    },
                }}
                columnVisibilityModel = {columnVisible}
                onColumnVisibilityModelChange={(newModel) =>setColumnVisible (newModel)}
                disableMultipleRowSelection={schema.settings?.disableMultipleRowSelection || false}
                hideFooterSelectedRowCount={schema.settings?.disableMultipleRowSelection || false}
                initialState={{ pinnedColumns: schema.settings.pinnedColumns || {} }}
                getRowClassName={(params)=>{
                    for (let rule in (schema.settings?.applyStyleIf || [])){
                      if (params.row[schema.settings?.applyStyleIf[rule].field] == schema.settings?.applyStyleIf[rule].value){
                        return schema.settings?.applyStyleIf[rule].className;
                      }
                    }
                }}
                rowHeight={38}
                onRowClick={handleRowClick}
                slotProps={{  
                    // footer: { value }, 
                }}
            />
        </Box>}
        </>
    );
}

export default HierarchicalGrid;

HierarchicalGrid.propTypes = {
  schema: PropTypes.object.isRequired,
};