import React, { useEffect, useState } from 'react';
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import PropTypes from 'prop-types';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';
import moment from 'moment';

const BasicGrid = ({schema}) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const dispatch = useDispatch();
    const {theme} = useThemeContext();
    const [columnVisible, setColumnVisible] = React.useState({});
    const [style, setStyle] = useState({
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: schema.settings?.header?.backgroundColor || theme.palette.primary.main,
        color: schema.settings?.header?.color || theme.palette.primary.contrastText
      }
    });
    useEffect(() => {
      (schema?.settings?.style) && setStyle (_.assign (style, schema?.settings?.style));
      if (!_.isEqual(schema.settings?.columnVisibilityModel, columnVisible)){
        setColumnVisible (schema.settings?.columnVisibilityModel);
      }
      if (schema?.settings?.columns){
        schema.settings.columns.map ((col)=> {
          if (col.field.includes ("."))
              col.valueGetter = (params) => {
                  return _.get (params.row, params.field)
              }
        })
        if (schema.settings.action && _.filter(schema.settings.columns, a => a.field === 'actions').length === 0){
          schema.settings.columns.push({
            field: "actions",
            type: "actions",
            headerName: schema.settings.action.headerName || "Operations",
            width: schema.settings.action.width || 150,
            getActions: (params) => {
              const userActions = [];
              schema.settings.action.actions.forEach ((action) => {
                (!params.row.hideAction && !action.hidden) && userActions.push (
                      <GridActionsCellItem
                        icon={<Tooltip title={action.label} placement="top"><FontAwesomeIcon icon={action.icon?.code} color={action.icon?.color || theme.palette.primary.main}/></Tooltip>}
                        label={action.label}
                        onClick={() => (action.event && schema[action.event] && schema[action.event] (params.row))}
                      />
                  )
              });
              return userActions;
            }
          });
        }
        schema.settings.columns.forEach ((c) => {
          if (c.linkTo){
            c.renderCell = (params) => {
              return (<a href={c.linkTo + params.id }>{params.row.text || params.row.name || params.row.value || params.row.description}</a>)
            }
          }
          if (c.special?.applyLocalDate){
            c.renderCell = (params) => {
              return (<>{params.value && moment.utc(params.value).format(c.special.format || 'llll').split(',')}</>)
            }
          }
        })
        setColumns(schema.settings.columns);
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
        if (schema.href)
            dispatch (callService (schema.href, null, (success) => {
              if (_.isArray(success.data)) setRows (success.data);
            }, (failure)=> {
                console.log (failure)
            }));
      }
    },[]);

    useEffect(() => {
      if (_.isArray (schema.datasource) && schema.settings.__hasDataSourceUpdated && !_(rows).xorWith(schema.datasource, _.isEqual).isEmpty()){
        schema.datasource = _.uniqBy (schema.datasource, "id");
        setRows (schema.datasource);
        schema.onDataLoad && schema.onDataLoad ();
        schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
      }
    },[schema.datasource]);

    const handleRowClick = (params) => {
      schema.setPropertyValue (params);
      schema.onRowClick && schema.onRowClick (params);
    };


    return (
      <>
        {schema && !schema.behaviour?.hidden && 
          <Box sx={schema.settings.sx || { width: '100%', height: schema.settings?.height || "80vh", mb:5 }}>
            {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
              {schema.settings.label}
            </Typography>}
            {schema.settings.help && <Typography sx={{fontSize: 11, pb:1}} color="text.secondary" gutterBottom>
              {schema.settings.help}
            </Typography>}
            <DataGridPro
              sx={style}
              rows={rows}
              columns={columns}
              columnVisibilityModel = {columnVisible}
              onColumnVisibilityModelChange={(newModel) =>setColumnVisible (newModel)}
              disableMultipleRowSelection={schema.settings?.disableMultipleRowSelection || false}
              hideFooterSelectedRowCount={schema.settings?.disableMultipleRowSelection || false}
              initialState={{ pinnedColumns: schema.settings.pinnedColumns || {} }}
              onFilterModelChange={(filter)=>{schema.onFilter && schema.onFilter (filter)}}
              rowHeight={38}
              onRowClick={handleRowClick}
              getRowClassName={(params)=>{
                for (let rule in (schema.settings?.applyStyleIf || [])){
                  if (params.row[schema.settings?.applyStyleIf[rule].field] == schema.settings?.applyStyleIf[rule].value){
                    return schema.settings?.applyStyleIf[rule].className;
                  }
                }
              }}
            />
          </Box>
        }
      </>
    )
}

export default BasicGrid;

BasicGrid.propTypes = {
  schema: PropTypes.object.isRequired,
};