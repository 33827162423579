import KeyboardBindings from 'diagram-js/lib/features/keyboard/KeyboardBindings';
// This is done to override delete and validate
export default class CustomKeyboard extends KeyboardBindings {
    registerBindings(keyboard) {
    // (1) register another listener with a higher priority so it's called first
    keyboard.addListener(10000, context => {
        const event = context.keyEvent;
        if (keyboard.isKey(['Backspace', 'Delete', 'Del' ], event)) {
        return false;
        }
    });
    }
}