import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import {Box, alpha} from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const NotificationBar = ({schema}) => {
  const [open, setOpen] = React.useState(true);
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);
  
  return (
    <Collapse in={open}>
      {schema && !schema.behaviour?.hidden && schema.getPropertyValue() && <Box
        sx={{
          borderBottom: (theme) =>
            `solid 1px ${alpha(theme.palette.common.black, 0.15)}`,
          backgroundColor: (theme) => schema.settings?.backgroundColor || theme.palette.primary.main,
          color: (theme) => schema.settings?.color || theme.palette.primary.contrastText,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: {lg: 1140, xl: 1420},
            mx: 'auto'
          }}
        >
          <Alert
            sx={{
              backgroundColor: 'transparent !important',
              padding: 0,
              textAlign: schema.settings?.textAlign || 'center',
              color: 'inherit',
              '& .MuiAlert-message': { flex: 1, },
              '& .MuiAlert-action': { ml: 2.5, },
            }}
            icon={false}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            <Box sx={{pt:1}}><Typography variant="h6" sx={{whiteSpace: 'pre-line'}}>{schema.getPropertyValue()}</Typography></Box>
          </Alert>
        </Box>
      </Box>}
    </Collapse>
  );
};

export default NotificationBar;
NotificationBar.propTypes = {
  schema: PropTypes.object.isRequired
};