import React, { useRef } from 'react';

import 'tui-image-editor/dist/tui-image-editor.css';
import "tui-color-picker/dist/tui-color-picker.css";
import ImageEditor from '@toast-ui/react-image-editor';
import PropTypes from 'prop-types';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import uuid from 'react-uuid';

const Editor = ({item, onSave, onCancel}) => {
    const editor = useRef();
    const saveImage = () => {
        let base64 = editor.current.getInstance ().toDataURL({"format": "png"});
        const trimmedString = base64.replace('data:image/png;base64,', '');
        const imageContent = atob(trimmedString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);

        for (let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }
        const type = 'image/png';
        const blob = new Blob([buffer], { type });
        var file = new File([blob], uuid () + ".png", { lastModified: new Date().getTime(), type });
        onSave (file, item.id);
    }

    return (
        <>
            {item && <ImageEditor
                ref={ editor }
                includeUI={{
                    loadImage: {
                        path: item.uri + "&__random=" + uuid (),
                        name: 'UploadedImage',
                    },
                    menu: ["shape", "filter", "text", "draw", "crop", "flip", "rotate", "icon", "mask"],
                    initMenu: 'filter',
                    uiSize: {
                        width: '100%',
                        height: "80vh"
                    },
                    menuBarPosition: 'bottom',
                }}
                cssMaxHeight={500}
                cssMaxWidth={700}
                selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                }}
                usageStatistics={true}
            />}
            <Stack direction={"row"} spacing={2} justifyContent={"right"} alignItems={"center"} sx={{m2: 2, pt: 2}} >
                <Button  key={"close"} variant='contained' endIcon={<FontAwesomeIcon icon={"fas fa-xmark"} size={"xs"} />} sx={{backgroundColor: "#B71C1C" }}
                    onClick={onCancel}>Cancel
                </Button>
                <Button  key={"save"} variant='contained' endIcon={<FontAwesomeIcon icon={"fas fa-check"} size={"xs"} />} sx={{backgroundColor: "#00695C" }}
                    onClick={saveImage}>Save
                </Button>
            </Stack>
        </>
    );
}

export default Editor;

Editor.propTypes = {
    item: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};
