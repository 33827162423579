import React from 'react';
import {Box} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import Members from './Members';
import Labels from './Labels';
import {Fonts} from 'shared/constants/AppEnums';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

const CardDetail = ({
  item,
  onClick,
}) => {
  return (
    <Box onClick={(e) => { e.stopPropagation(); onClick && onClick(item); }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', }} >
        <Box sx={{ mr: 2, fontWeight: Fonts.MEDIUM, flexGrow: 1, color: item.textColor }} >
          {item.name}
        </Box>
        {item.modifiedBy && 
          <Tooltip title={"Last Updated By: " + item.modifiedBy.name} key={item.modifiedBy.id}>
            {item.modifiedBy.avatar ? 
              (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white" }} src={item.modifiedBy.avatar} alt={item.modifiedBy.name} />) : 
              (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white" }}>{item.modifiedBy.name[0].toUpperCase()}</Avatar>)}
          </Tooltip>
        }
      </Box>
      {item.description && <Typography sx={{fontSize: 12, color: item.textColor || "#6B7280" }} gutterBottom >{item.description}</Typography>}
      {item.label && item.label.length > 0 ? <Labels labels={item.label} /> : null}
      {item.modifiedOn && <Typography sx={{fontSize: 10, color: item.textColor || "#3F51B5" }}  gutterBottom >Last Updated On: { moment.utc(item.modifiedOn).format('D/MM/YYYY, h:mm:ss a').split(',') }</Typography>}
      {item.assign && item.assign.length > 0 && 
        <Box sx={{ mt:2, display: 'flex', alignItems: 'center', }} >
          <Members members={item.assign} />
        </Box>
      }
    </Box>
  );
};

export default CardDetail;

CardDetail.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
