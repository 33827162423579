import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

const TimelineComponent = ({schema}) => {
  const [data, setData] = useState([]);

  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
  }, []);
  
  useEffect (()=>{
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(data).xorWith(schema.datasource, _.isEqual).isEmpty()){
        setData (schema.datasource);
        schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  }, [schema.datasource]);
    
  return (
    <>
      {schema && !schema.behaviour?.hidden && 
        <Box sx={{ width: '100%' }}>
          {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
            {schema.settings.label}
          </Typography>}
          {schema.settings.help && <Typography sx={{fontSize: 11, pb:1}} color="text.secondary" gutterBottom>
            {schema.settings.help}
          </Typography>}
          <Timeline position={schema.settings.position} sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2, },
      }}>
          {data?.map((d, index) => {
            return (
              <TimelineItem key={data.id + "-" + index}>
                <TimelineOppositeContent>
                  {d.label && <Box component='h6' sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.secondary.main}`}}>{d.label}</Box>}
                  {d.date && <Box component='h6' sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.secondary.main}`}}>{moment.utc(d.date).local().format(schema.settings.dateFormat || 'llll').split(',')}</Box>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot variant="outlined" color="primary"/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {d.profile && 
                    <Tooltip title={d.profile.name} key={d.profile.id}>
                      {d.profile.avatar ? 
                        (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white" }} src={d.profile.avatar} alt={d.profile.name} />) : 
                        (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white" }}>{d.profile.name[0].toUpperCase()}</Avatar>)}
                    </Tooltip>
                  }
                  {d.title && <Box sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.primary.main}`}} component='h5'>{d.title}</Box>}
                  {d.help && <Box component='h6' sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.secondary.main}`}}>{d.help}</Box>}
                  {d.info1 && <Box component='h6' sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.grey[600]}`}}>{d.info1}</Box>}
                  {d.info2 && <Box component='h6' sx={{ whiteSpace: 'pre-line', color:(theme) => `${theme.palette.grey[600]}`}}>{d.info2}</Box>}
                </TimelineContent>
              </TimelineItem>
            )
          })}
          </Timeline>
        </Box>
      }
    </>
  )
}

export default TimelineComponent;

TimelineComponent.propTypes = {
    schema: PropTypes.object.isRequired
};