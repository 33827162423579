import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const BarcodeComponent = ({schema}) => {
    const [show, setShow] = useState (false);
    const videoRef = useRef(null);
    const reader = useRef(new BrowserMultiFormatReader());
    useEffect (()=>{
        setShow (false);
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    useEffect(() => {
        if (!videoRef.current) return;
        reader.current.decodeFromConstraints({
            audio: false,
            video: {
                facingMode: 'environment',
            },
        },
        videoRef.current,
        (result) => {
            if (result){
                setShow (false);
                schema.setPropertyValue (result.text);
            }
        });
        return () => {
            reader.current.reset();
        }
    }, [videoRef, show]);
    return (
        <>
            {schema && !schema.behaviour?.hidden && <TextField
                sx={{
                    width: '100%',
                    position: 'relative',
                    transition: 'all 0.5s ease'
                }}
                required={schema.behaviour?.mandatory}
                disabled={schema.behaviour?.readonly}
                id={schema.__id}
                label={schema.settings.label}
                helperText={(schema.settings.help || "") + " " + (schema.error || "") }
                value={schema.getPropertyValue()}
                multiline={schema.settings.multiline}
                placeholder={schema.settings.placeholder || ""}
                minRows={1}
                maxRows={4}
                onChange={(e) => {
                    schema.setPropertyValue (e.target.value);
                    schema.getParent().__isValid__ ();
                    if (schema.onChange) schema.onChange();
                }}
                onBlur={(e) => {
                    schema.setPropertyValue (e.target.value);
                    schema.getParent().__isValid__ ();
                    if (schema.onBlur) schema.onBlur();
                }}
                variant={schema.settings.variant || 'outlined'}
                error={schema.error?true:false}
                InputProps={{
                    maxLength: schema.settings.maxLength, minLength: schema.settings.minLength,
                    endAdornment: <InputAdornment position="end">
                        <IconButton size='small' onClick={() => { setShow (!show); }} edge='end'>
                            {!show ? <Tooltip title="Camera On" placement="top"><FontAwesomeIcon icon="fas fa-barcode-read"/></Tooltip> :
                                    <Tooltip title="Camera Off" placement="top"><FontAwesomeIcon icon="fas fa-camera-slash"/></Tooltip>}
                        </IconButton>
                    </InputAdornment>,
                }}
            />}
            {show && <video ref={videoRef} />}
        </>
    )
}

export default BarcodeComponent;

BarcodeComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};