import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';

export default function VerticalLinearStepper({schema}) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
    {schema && !schema.behaviour?.hidden && 
      <Stepper activeStep={activeStep} orientation='vertical' sx={{width:"100%"}}>
        {(schema.settings.steps || []).map((step, index) => (
          <Step key={step.label}>
            <StepLabel sx={{'&:hover': { cursor: 'pointer'}}} onClick={handleStep(index)}>
              {step.label}
            </StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              <Typography>{step.help}</Typography>
              {schema.fieldsets?.map((fieldset, index) => {
                return (<div key={schema.__id + "-" + index}> 
                  {fieldset.fields.map ((field, idx) => {
                      if (schema.properties [field] && idx === activeStep)
                      return (
                          <div key={schema.properties [field].__id}> 
                          {Registry.createElement(schema.properties [field].widget, {schema: schema.properties [field], model: schema.properties [field].value})}
                          </div>
                      )
                  })}
                </div>)
              })}
              {!schema.settings.hideNavigation && <Box sx={{mb: 2}}>
                <div>
                  <Button
                    variant='contained'
                    onClick={()=>schema.onNext && schema.onNext (step.name, handleNext)}
                    sx={{mt: 3, mr: 1}}
                  >
                    {index === schema.settings.steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{mt: 3, mr: 1}}
                  >
                    Back
                  </Button>
                </div>
              </Box>}
            </StepContent>
          </Step>
        ))}
      </Stepper>}
      </>
  );
}

VerticalLinearStepper.propTypes = {
  schema: PropTypes.object.isRequired,
};