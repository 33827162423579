import React from 'react';
import Typography from '@mui/material/Typography';
import WidgetType from './Type';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const AttributeBuilder = ({schema}) => {
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                {schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>{schema.settings.label}</Typography>}
                {(schema.settings?.help || schema.error) && <pre>
                    <Typography sx={{fontSize: 12, mb:5}} color={(schema.error)?"#ef5350":"text.secondary"} gutterBottom component='div'>
                        {(schema.settings.help || "") + "\n" + (schema.error||"")}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>}
                <WidgetType schema={schema}/>
            </>}
        </>
    )
}

export default AttributeBuilder;

AttributeBuilder.propTypes = {
    schema: PropTypes.object.isRequired,
};
