import ContextPad from "diagram-js/lib/features/context-pad/ContextPad";
import * as _ from "lodash";

export default class CustomContextPad extends ContextPad {
  getEntries (element) {
    var providers = this._getProviders();
    var entries = {};
    // loop through all providers and their entries.
    // group entries by id so that overriding an entry is possible
    _.forEach(providers, (provider) => {
      if (!_.isFunction (provider.getContextPadEntries)) return;
      var entriesOrUpdater = provider.getContextPadEntries(element);
      if (_.isFunction(entriesOrUpdater)) { entries = entriesOrUpdater(entries); } 
      else {
        _.forEach(entriesOrUpdater, (entry, id) => {
          entries[id] = entry;
          if (id == "settings" && !entry.action.click) {
            entry.action.click = () => this._eventBus.fire ("onSettings", { element: element });
          }
        });
      }
    });
    if (!element.type) delete entries ["delete"];

    delete entries ["append.gateway"];
    delete entries ["append.intermediate-event"];
    delete entries ["append.append-task"];
    delete entries ["append.subprocess-expanded"];
    delete entries ["replace"];
    delete entries ["append.end-event"];

    if (!["bpmn:ServiceTask", "bpmn:UserTask", "bpmn:StartEvent", "bpmn:EndEvent" ].includes (element.type)){
      delete entries ["connect"];
      delete entries ["settings"];
    }

    // Start Event will not have any settings
    if (["bpmn:StartEvent" ].includes (element.type)){
      delete entries ["settings"];
    }
    // End event will not have connect
    if (["bpmn:EndEvent" ].includes (element.type)){
      delete entries ["connect"];
    }
    
    return entries;
  }
}