import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";

export default class CustomRuleProvider extends RuleProvider {
    init (){
        this.addRule("connection.create", 2000, function (context) {
            if (context.target?.type == "bpmn:Participant" || context.source?.type == "bpmn:Participant")
                return false;
            if (context.source?.type == "bpmn:EndEvent")
                return false;
            if (context.source?.id == context.target?.id)
                return false;
            if (context.target?.type == "bpmn:StartEvent")
                return false;
            if (context.target?.type == "bpmn:EndEvent" && context.source?.type == "bpmn:StartEvent")
                return false;
            
        });      
    }
}

CustomRuleProvider.$inject = ["eventBus", "elementRegistry"];


