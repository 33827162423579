import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WidgetType from './Type';
import PropTypes from 'prop-types';

const WidgetComponent = ({schema}) => {
    return (
        <>
            <Box sx={{width: '100%'}}>
                <Typography variant='h5' gutterBottom component='div'>
                {schema.settings.label}
                </Typography>
                <Typography variant='h6' gutterBottom component='div'>
                {schema.settings.help}
                </Typography>
            </Box>
            <WidgetType schema={schema}/>
        </>
    )
}

export default WidgetComponent;

WidgetComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};
