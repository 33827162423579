import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleListItem from './SimpleListItem';
import Box from '@mui/material/Box';
import AppList from '@crema/core/AppList';
import PropTypes from 'prop-types';
import { callService } from 'redux/actions';
import * as _ from "lodash";
import AppSearch from '@crema/core/AppSearchBar';
import Typography from '@mui/material/Typography';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      background: isDragging ? "#BBDEFB" : null,
      // styles we need to apply on draggables
      ...draggableStyle
  }
};

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#E8EAF6" : null
});


const SimpleList = ({schema}) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect (() => {
    setTimeout (()=> {  schema.onInit && schema.onInit ((data) => _.isArray(data) && setData (data));  }, 100);
    if (schema.datasource instanceof Array) setData (schema.datasource);
    if (schema.href ){
        dispatch (callService (schema.href, null, (success) => {
          if (_.isArray(success.data)){
              setData (success.data);
          }
        }, (failure)=> {
            console.log (failure)
        }));
    }
  }, []);
  useEffect (()=>{
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(data).xorWith(schema.datasource, _.isEqual).isEmpty()){
      setData (schema.datasource);
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  }, [schema.datasource]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    let newItems = reorder( data, result.source.index, result.destination.index);
    setData(newItems);
    schema.getParent ().__setDataSource__ (schema.__name, newItems);
    schema.onChange && schema.onChange ();
    
  }
  const onSearch = (title) => {
    setData (_.filter (schema.datasource, (d)=>d.name.toLowerCase().trim().replace(/ /g,'').includes (title.toLowerCase ().trim().replace(/ /g,''))))
  };
  return (
    <>
      {schema && !schema.behaviour?.hidden && <>
        {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
          {schema.settings.label}
        </Typography>}
        {schema.settings.help && <Typography sx={{fontSize: 11, pb:1, mt:5}} color="text.secondary" gutterBottom>
          {schema.settings.help}
        </Typography>}
        <Box flex={1}>
          {schema.settings?.showSearch && <Box sx={{ display: 'flex', flex: 1}}>
            <Box sx={{mr: 3}}>
              <AppSearch placeholder='Search' onChange={(e) => onSearch(e.target.value)} />
            </Box>
          </Box>}
          {_.isArray(data) && data.length>0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} 
                    {...provided.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}>
                      <AppList  data={data} renderRow={(d, index) => 
                          (<Draggable key={d.id} draggableId={d.id} index={index} isDragDisabled={!schema.settings.allowReorder}>
                            {(provided, snapshot) => {
                              return (
                                <div ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                  <SimpleListItem data={d} key={d.id} schema={schema}/>
                                </div>
                              )
                            }}
                            </Draggable>
                          )
                        }/>
                    {provided.placeholder}
                    </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
      </Box></>}
    </>
  );
};

export default SimpleList;

SimpleList.propTypes = {
  schema: PropTypes.object.isRequired,
};

