import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import { useLayoutActionsContext } from '@crema/utility/AppContextProvider/LayoutContextProvider';
import { useNavigate } from 'react-router-dom';
import { useSidebarActionsContext } from '@crema/utility/AppContextProvider/SidebarContextProvider';
import { useThemeActionsContext, useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();
export const useJWTAuth = () => useContext(JWTAuthContext);
export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const navigate = useNavigate();
  const [authData, setJWTAuthData] = useState({
    user: null,
    menu: null,
    ui: null,
    isAuthenticated: false,
    isLoading: true,
    settings: null
  });
  const {
    setFooter,
    setFooterType,
    updateNavStyle,
    updateLayoutType,
    setHeaderType,
  } = useLayoutActionsContext();
  const {updateSidebarBgImage, setSidebarBgImage, updateSidebarColorSet} = useSidebarActionsContext();
  const {theme} = useThemeContext();
  const {updateTheme} = useThemeActionsContext();
  const dispatch = useDispatch();

  useEffect(() => {
    reload (false)
  }, []);

  const reload = (showLanding) => {
    dispatch (callService ("/", {}, (response)=>{
      setJWTAuthData({
        user: response.data.party,
        menu: response.data.menu,
        ui: response.data.settings,
        isLoading: false,
        isAuthenticated: true,
      });

      let settings = response.data.settings;
      if (settings?.layout){
        settings.layout.footer && setFooter (settings.layout.footer);
        settings.layout.footerType && setFooterType (settings.layout.footerType);
        settings.layout.headerType && setHeaderType (settings.layout.headerType);
        settings.layout.layoutType && updateLayoutType (settings.layout.layoutType);
        settings.layout.navStyle && updateNavStyle (settings.layout.navStyle);
        settings.layout.isSidebarBgImage && setSidebarBgImage (settings.layout.isSidebarBgImage);
        settings.layout.sidebarBgImage && updateSidebarBgImage (settings.layout.sidebarBgImage);
        if (settings.layout.themeDirection){
          theme.direction = settings.layout.themeDirection;
          updateTheme({...theme});
        }
        settings.layout.sidebarColors && updateSidebarColorSet (settings.layout.sidebarColors)
      }
      settings?.theme &&  updateTheme (settings.theme);
      settings?.title && (document.title = settings.title);
      if (settings?.favicon){
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = settings?.favicon;
      }
      showLanding && settings.landing && navigate(settings.landing);

    }, (error)=>{
      console.log (error);
      setJWTAuthData({
        user: undefined,
        menu: undefined,
        ui: undefined,
        settings: undefined,
        isLoading: false,
        isAuthenticated: false,
      })
    }));
  }

  const updateSettings = async (model) => {
    setJWTAuthData({
      ...authData,
      settings: model,
    });
  };

  const updateMenu = async (menu) => {
    setJWTAuthData({
      ...authData,
      menu: menu,
    });
  };

  const updateUser = async (user) => {
    setJWTAuthData({
      ...authData,
      user: user,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...authData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          updateSettings,
          updateMenu,
          updateUser,
          // updateToken
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
