import React, { useEffect, useState } from 'react';
import StringWidget from './String';
import NumberWidget from './Number';
import DropdownWidget from './Dropdown';
import PropTypes from 'prop-types';
import SwitchWidget from './Switch';
import CustomWidget from './Custom';

export default function Settings ({schema, onChange}) {
    const [settings, setSettings] = useState();
    useEffect (()=>{
        setSettings (schema.settings);
    }, [schema]);

    return (
        <>
            {schema.widget == "string" && settings && <StringWidget settings={settings} onChange={onChange}/>}
            {schema.widget == "number" && settings && <NumberWidget settings={settings} onChange={onChange}/>}
            {schema.widget == "dropdown" && settings && <DropdownWidget settings={settings} onChange={onChange}/>}
            {schema.widget == "switch" && settings && <SwitchWidget settings={settings} onChange={onChange}/>}
            {schema.widget == "custom" && settings && <CustomWidget settings={settings} onChange={onChange}/>}
        </>
    )
}

Settings.propTypes = {
    onChange: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired
  };
