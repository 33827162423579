import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';
import { FormLabel } from '@mui/material';
import Selection from './Selection';
import * as _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';

export default function Array({schema}) {
    const [activeStep, setActiveStep] = useState(0);
    const [auto, setAuto] = useState(false);
    const [manual, setManual] = useState(false);

    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();}, 100);
    }, []);

    useEffect (()=>{
        if (activeStep>=schema.properties.length && schema.properties.length>0) setActiveStep (schema.properties.length-1);
        let missing = validateCardinality (schema.settings.items, schema.properties);
        schema.getParent().__setSettings__ (schema.__name, schema.settings);
        setTimeout (()=> {  missing.length>0 && organizeSubforms (); }, 100);
    }, [schema.properties.length]);

    const organizeSubforms = () => {
        // Preparing the forms based on the cardinality
        if (_.isArray (schema.settings.items) && schema.settings.items.length>0){
            let missing = validateCardinality (schema.settings.items, schema.properties);
            let toBePreLoaded = _.map (missing, (o)=> o.id);
            if (toBePreLoaded.length>0) handleSelection (toBePreLoaded);
        }
    }

    const validateCardinality = (its, props) => {
        let missing = [];
        its.map ((i) => {
            let pc = _.filter (props || [], (c) => c.identifier == i.id).length;
            let missingCount = i.cardinality.min - pc;
            // Missing count is based on cardanality
            if ((i.cardinality.min || 0) > pc) missing.push ({
                id: i.id, name: i.name, missing: missingCount, cardinality: i.cardinality
            });
            // Check Maximum
            i.disabled = (i.cardinality.max <= pc || i.cardinality?.max === 0);
        });
        setAuto (missing.length>0);
        setManual (_.filter (its, (i)=>!i.disabled).length>0);
        return missing;
    }

    const handleSelection = (id) => {
        let sch = _.cloneDeep (schema);
        (_.isArray(id)?id:[id]).map ((id) => {
            let s = schema.settings.schemas [id];
            if (!s) return;
            if (!sch.properties) sch.properties = [];
            let i = _.find (schema.settings.items, { id: id});
            let p = _.filter (sch.properties, (c) => c.identifier == id).length;
            if (!s.settings.label) s.settings.label = i.name;
            s.identifier = id; 
            // Check min number reached
            if (i.cardinality.min > 0 && p < i.cardinality.min) _.times (i.cardinality.min - p, () => sch.properties.push (s));
            else sch.properties.push (s);

            p = _.filter (sch.properties, (c) => c.identifier == id).length;
            // No check missing based on configured value
            if (_.isArray (schema.getPropertyValue()) && schema.getPropertyValue().length > 0){
                let noOfValues = _.filter (schema.getPropertyValue(), (c) => _.has (c, id)).length;
                if (noOfValues > p) _.times (noOfValues - p, () => sch.properties.push (s));
            }
        });
        schema.__addItem__ (sch.properties);
    }

    const handleDelete = (index) => {
        schema.getParent().__confirmation__ ({
            title: "Warning",  
            message: "Are you sure, you want to delete this item, this action cannot be undone?",
            events: [{
                label: "Cancel"
            },  {
                label: "Yes, Please",
                onClick: () => {
                    schema.getParent().__backdrop__ ({ isOpen: true, message: schema.settings.loadingText || "Organizing the subform ... please wait"});
                    schema.__removeItem__ (index);
                    schema.onChange && schema.onChange ();
                    schema.getParent().__backdrop__ ({ isOpen: false});
                }
            }]
        });
    }

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <>
            {schema && !schema.behaviour?.hidden && <>
            {schema.settings.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings.label}</FormLabel>}
            <Box sx={ schema.settings.style || schema.settings.label?{width: '100%', border: 1, borderRadius: 2, borderColor: 'grey.400', p:5}:{width: '100%', mt:-5}}>
                {(schema.settings.help || schema.error) &&
                    <pre><Typography sx={{fontSize: 12}} gutterBottom color={(schema.error)?"#ef5350":"text.secondary"}>
                        {(schema.settings.help || "") || (schema.error || "")}
                    </Typography></pre>
                }
                {auto && !schema.behaviour?.readonly && 
                <Button startIcon={<SyncIcon />} sx={{float: 'left'}} 
                    aria-controls='subform-menu'
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={organizeSubforms}
                >Auto Organize</Button>}
                {manual && !schema.behaviour?.readonly && <Selection items={schema.settings.items} handleSelection={handleSelection}/>}
                <Stepper activeStep={activeStep} orientation='vertical' sx={{width:"100%"}}>
                    {schema.properties?.map((prop, index) => {
                        prop.settings.showLabel = false;
                        return (
                        <Step key={prop.__id}>
                            <StepLabel sx={{width:"100%", '&:hover': { cursor: 'pointer'}}} onClick={handleStep(index)}>
                                <Typography sx={{fontSize: 14, fontWeight: 600}} color={(prop.error)?"#ef5350":"text.secondary"}>
                                    {(prop.settings.label || prop.settings.step.label)  + (prop.error || "")}
                                </Typography>
                            </StepLabel>
                            <StepContent TransitionProps={{ unmountOnExit: schema.settings.unmountOnExit}}>
                                {!schema.settings.hideDelete && !schema.behaviour?.readonly && <Button endIcon={<DeleteIcon />} size="small" color='secondary' sx={{mt:"-10px", float: 'right', color:'#F04F47'}} onClick = {()=>handleDelete(index)}>Delete</Button>}
                                
                                {Registry.createElement(prop.widget, {schema: prop, model: prop.value})}
                            </StepContent>
                        </Step>)
                    })}
                </Stepper>
            </Box>
            </>}
        </>
    );
}

Array.propTypes = {
    schema: PropTypes.object.isRequired,
};