import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "@bpmn-io/properties-panel/dist/assets/properties-panel.css";
import './../styles.css';

const xml = `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.3.5">
        <bpmn:collaboration id="Collaboration_1nn1a0w">
            <bpmn:participant id="Participant_0uac119" processRef="Process_1" />
        </bpmn:collaboration>
        <bpmn:process id="Process_1" isExecutable="true" />
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_1nn1a0w">
                <bpmndi:BPMNShape id="Participant_0uac119_di" bpmnElement="Participant_0uac119" isHorizontal="true">
                    <dc:Bounds x="150" y="30" width="600" height="250" />
                </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
    </bpmn:definitions>`;

const BPMNViewer = ({schema}) => {
    const bpmnContainer = useRef();
    const [viewer, setViewer ] = useState ("");
    const [data, setData ] = useState ({});

    useEffect (() => {
        updateMarker (true);
    }, [data]);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isObject (schema.datasource) && !_.isEqual (data, schema.datasource)){
            setData (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const updateMarker = () => {
        data?.data?.map ((elm) => {
            const element = viewer.get('elementRegistry')?.get(elm ["@id"]);
            element && setMarker (element, elm.marker);
        })
    }

    const setMarker = (element, marker="success") => {
        if (!viewer) return;
        const canvas = viewer.get('canvas');
        canvas.addMarker (element, marker);
    }
    
    useEffect (() => {
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
        const container = bpmnContainer.current;
        const bpmnViewer = new BpmnViewer({ container, 
            keyboard: { bindTo: document }
        });
        const model = schema.getPropertyValue () || xml;
        bpmnViewer
            .importXML(model)
            .then(({ warnings }) => {
                if (warnings.length) {
                    console.log(warnings);
                }
                const canvas = bpmnViewer.get("canvas");
                canvas.zoom("fit-viewport");
            })
            .catch((err) => {
                console.log(err);
            });
            
        bpmnViewer.on('element.click', (event) => {
            schema.onClick && schema.onClick (event.element);
        });
        setViewer (bpmnViewer);
        return () => {
            bpmnViewer.destroy ();
        }
    }, []);
        
    return (
        <>
            {schema.settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                {schema.settings.label}
            </Typography>}
            {(schema.error || schema.settings.help) && <Typography sx={{fontSize: 12}} color={(schema.error?.message || schema.error)?"red":"text.secondary"} gutterBottom >
                {(schema.settings.help || "") + ((schema.error)? ".. Compilation Error occured, please fix":"") }
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Typography>}
            {schema && 
            <Box m={1} position="relative">
                <Box ref={ bpmnContainer } height={schema.settings.height || "70vh"}></Box>
            </Box>}
        </>
    )
}

export default BPMNViewer;
BPMNViewer.propTypes = {
    schema: PropTypes.object.isRequired,
};