import {initialUrl} from 'shared/constants/AppConst';
import {Navigate} from 'react-router-dom';
import Error403 from './errorPages/Error403';
import React from 'react';
import {errorPagesConfigs} from './errorPages';
import { dynamicPagesConfigs } from './dynamic/dynamicRoutesConfig';

const authorizedStructure = {
  fallbackPath: '*',
  unAuthorizedComponent: <Error403 />,
  routes: [...dynamicPagesConfigs],
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={initialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};

export {authorizedStructure, anonymousStructure};
