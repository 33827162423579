import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import JoditEditor from 'jodit-react';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@mui/material/InputAdornment';
import {Form, Formik} from 'formik';
import Divider from '@mui/material/Divider';
import {useAuthUser} from '@crema/utility/AuthHooks';

const CommentComponent = ({schema}) => {
  const {user} = useAuthUser();
  const [data, setData] = useState([]);
  const config = useMemo(() => ({ readonly: true,  toolbar: false, statusbar: false, toolbarAdaptive: true, minHeight: 0}), []);

  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
  }, []);
  
  useEffect (()=>{
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(data).xorWith(schema.datasource, _.isEqual).isEmpty()){
        setData (schema.datasource);
        schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  }, [schema.datasource]);
    
  return (
    <>
      {schema && !schema.behaviour?.hidden && data.length>0 && 
        <Box sx={{ width: '100%' }}>
          {schema.settings.label && <Typography sx={{mb:5}} variant='h5' gutterBottom component='div'>
            {schema.settings.label}
          </Typography>}
          {schema.settings.help && <Typography sx={{fontSize: 11, pb:1, mb:5}} color="text.secondary" gutterBottom >
            {schema.settings.help}
          </Typography>}
          {data?.map((d) => {
            return (
              <Box sx={{ '&:not(:last-of-type)': { marginBottom: 5, }, }} key={d.id}>
                <Box display='flex'>
                  <Tooltip title={d.createdBy.name} key={d.createdBy.id}>
                    <Avatar sx={{ width: 44, height: 44, }} src={d.createdBy.avatar} alt={d.createdBy.name}/>
                  </Tooltip>
                  <Box ml={3.5} sx={{width: '100%'}}>
                    <Box sx={{ padding: '10px 20px', wordBreak: "break-word", border: (theme) => `solid 1px ${theme.palette.divider}`, borderRadius: 10, borderTopLeftRadius: 0, }}>
                      <Box sx={{ pl:2, pt:2}}>
                        {user.id == d.createdBy.id && !schema.behaviour.readonly && <IconButton size="small" sx={{float: 'right', mr:5, color: "#F04F47"}} onClick={() => schema.onDelete (0, d.id) }>
                          <Tooltip title="Delete Comment" placement="top">
                            <FontAwesomeIcon icon="fas fa-trash" sx={{backgroundColor: "#FF0000"}} />
                          </Tooltip>
                        </IconButton>}
                        <Typography color="primary" variant='h5' gutterBottom component='div' >{d.createdBy.name}</Typography>
                        <Typography variant='h6' gutterBottom component='div'color="text.secondary" >{ moment.utc(d.createdOn).format('D/MM/YYYY, h:mm:ss a').split(',') }</Typography>
                      </Box>
                      {d.mode == "rte" ? (<JoditEditor value={d.comment} tabIndex={-1} config={config} />) : (<Typography variant='h6' gutterBottom component='div'color="text.secondary">{d.comment}</Typography>) }
                      {!schema.behaviour.readonly && schema.settings?.reply?.enabled && schema.onReply && 
                        <Box sx={{m:5}}>
                          <Formik autoComplete="off" onSubmit={(data, { resetForm })=>{ 
                              schema.onReply (_.clone(data));
                              resetForm ();
                            }} initialValues={{id: d.id, reply:""}}>
                            {
                              (data)=>{
                                return (<Form noValidate autoComplete='off'>
                                  <TextField
                                    sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                                    label={schema.settings.reply.label}
                                    variant={'outlined'}
                                    name="reply"
                                    id="reply"
                                    value={data.values?.reply || ""}
                                    onChange={data.handleChange}
                                    multiline
                                    minRows={1}
                                    maxRows={10}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                type="submit"
                                                size='small'
                                                edge='end'
                                            >
                                              <Tooltip title={schema.settings.reply.help || "reply"} placement="top">
                                                <FontAwesomeIcon icon={schema.settings.reply.icon?.code || "fas fa-send"} 
                                                  sx={{backgroundColor: schema.settings.reply.icon?.color}}/>
                                              </Tooltip>
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                  />
                                </Form>)
                              }
                            }
                          </Formik>
                        </Box>}
                    </Box>
                  </Box>
                </Box>
                {d.replies?.map((r) => {
                  return (
                    <Box key={r.reply_id}>
                      <Box display='flex' sx={{ ml:10, mt:5 }}>
                        <Tooltip title={r.createdBy.name}>
                          <Avatar sx={{ width: 44, height: 44, }} src={r.createdBy.avatar} alt={r.createdBy.name}/>
                        </Tooltip>
                        <Box ml={3.5} mr={3.5} sx={{width: '100%'}}>
                          <Box sx={{ padding: '10px 20px', wordBreak: "break-word" }}>
                            <Box sx={{ pl:2, pt:2}}>
                              {!schema.behaviour.readonly && user.id == r.createdBy.id && <IconButton size="small" sx={{float: 'right', mr:5, color: "#F04F47"}} onClick={() => schema.onDelete (1, d.id, r.reply_id) }>
                                <Tooltip title="Delete Reply" placement="top">
                                  <FontAwesomeIcon icon="fas fa-trash" sx={{backgroundColor: "#FF0000"}} />
                                </Tooltip>
                              </IconButton>}
                              <Typography color="primary" variant='h5' gutterBottom component='div' >{r.createdBy.name}</Typography>
                              <Typography variant='h6' gutterBottom component='div'color="text.secondary" >{ moment.utc(r.createdOn).format('D/MM/YYYY, h:mm:ss a').split(',') }</Typography>
                              <pre><Typography variant='h6' gutterBottom component='div'color="text.secondary">{r.reply}</Typography></pre>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Divider sx={{ mt: 2, mb:2, mr:5 }} variant='inset'/>
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      }
    </>
  )
}

export default CommentComponent;

CommentComponent.propTypes = {
    schema: PropTypes.object.isRequired
};