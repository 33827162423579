import * as _ from "lodash";
export default class CustomTaskPalette {
    constructor(create, elementFactory, palette, translate) {
        this.create = create;
        this.elementFactory = elementFactory;
        this.translate = translate;
        palette.registerProvider(this);
    }

    getPaletteEntries() {        
        const { create, elementFactory, translate } = this;
        const createUserTask = (event) => {
            const shape = elementFactory.createShape({ type: 'bpmn:UserTask' });
            create.start(event, shape);
        }

        const createServiceTask = (event) => {
            const shape = elementFactory.createShape({ type: 'bpmn:ServiceTask' });
            create.start(event, shape);
        }

        return (entries) => {
            delete entries ["create.intermediate-event"];
            delete entries ["create.exclusive-gateway"];
            delete entries ["create.task"];
            delete entries ["create.data-object"];
            delete entries ["create.data-store"];
            delete entries ["create.subprocess-expanded"];
            return _.assign (entries, {
                'create.user-task': {
                    group: 'activity',
                    className: 'bpmn-icon-user',
                    title: translate('Append UserTask (Activity)'),
                    action: {
                        dragstart: createUserTask,
                        click: createUserTask
                    }
                },
                'create.service-task': {
                    group: 'activity',
                    className: 'bpmn-icon-gateway-xor',
                    title: translate('Append Service (Activity)'),
                    action: {
                        dragstart: createServiceTask,
                        click: createServiceTask
                    }
                }
            });
        }
    }
}
  
CustomTaskPalette.$inject = [
    'create',
    'elementFactory',
    'palette',
    'translate'
];