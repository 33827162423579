import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UploadModern = ({uploadText, dropzone}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '& ul': {
          listStyle: 'none',
          padding: 0,
        },
      }}
    >
      <Box
        {...dropzone.getRootProps({className: 'dropzone'})}
        sx={{
          cursor: 'pointer',
          border: (theme) => `dashed 2px ${theme.palette.divider}`,
          borderRadius: 2.5,
          p: 5,
          textAlign: 'center',
          mb: 4,
          color: 'text.secondary',
          backgroundColor: 'background.default',
        }}
      >
        <input {...dropzone.getInputProps()} />
        <FontAwesomeIcon icon="fas fa-folder-open" size={"xl"} style={{ marginBottom: 5}}/>
        <p>{uploadText}</p>
      </Box>
    </Box>
  );
};

export default UploadModern;

UploadModern.propTypes = {
  uploadText: PropTypes.string,
  dropzone: PropTypes.object,
};
