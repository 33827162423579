import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import * as _ from "lodash";
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const filter = createFilterOptions();
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    width: "100%",
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    fontWeight: "bold"
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const ImageDropdownComponent = ({schema}) => {
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    useEffect (()=> {
        setTimeout (()=> {  
            schema.onInit && schema.onInit ((data) => _.isArray(data) && setOptions (data));
            if (schema.href && !schema.settings?.lookup ){
                let url=schema.href; 
                let model = null;
                if (_.isObject (schema.href)){
                    url = schema.href.id || null;
                    model = schema.href.model || null
                }
                // let url = _.isObject (schema.href)?"/api/"+schema.href.id:schema.href;
                dispatch (callService (url, model, (success) => {
                    if (_.isArray(success.data)){
                        setOptions (success.data);
                        validate (success.data);
                    }
                }, (failure)=> {
                    console.log (failure)
                }));
            }
        }, 100);
    }, []);
    
    useEffect (()=>{
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty()){
            setOptions (schema.datasource);
            validate (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }, [schema.datasource]);
    
    const validate = (data) => {
        let value = schema.getPropertyValue ();
        if (_.isArray (value)){
            let finalValue = []
            value.forEach (val => {
                let item = _.find (data, (v)=> v.id == val);
                if (_.isObject (item)){
                    finalValue.push (item);
                }
            });
            if (finalValue.length > 0){
                schema.setPropertyValue (finalValue);
            } else {
                (schema.settings.deleteOnDestroy) && schema.setPropertyValue ("");
            }
        }
        else {
            if (_.isObject (value)) value = value.id;
            if (_.isInteger (value) || (_.isString (value) && value.length>0)){
                let item = _.find (data, (v)=> v.id == value);
                if (_.isObject (item)){
                    schema.setPropertyValue (item);
                } else {
                    (schema.settings.deleteOnDestroy || item?.length > 0) && schema.setPropertyValue ("");
                }
                if (schema.onChange) schema.onChange();
            }
        }
    }
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Autocomplete
                    id={schema.__id}
                    options={options}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => {
                        return option?.value || option?.name || option?.description || option?.id || option
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return value === undefined || value === "" || option.id == value.id
                    }}
                    value={schema.getPropertyValue()|| (schema.settings.multiple?[]:"")}
                    disabled={schema.behaviour?.readonly}
                    onChange={(e, newValue) => {
                        schema.setPropertyValue (newValue);
                        schema.getParent().__isValid__ ();
                        if (schema.onChange) schema.onChange(true);
                    }}
                    filterOptions={(options, params)=>{
                        return schema.onSearch?options:filter(options, params);
                    }}
                    multiple={schema.settings.multiple}
                    renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.image && <img
                            loading="lazy"
                            width="20"
                            srcSet={`${option.image} 2x`}
                            src={option.image}
                            alt=""
                          />}
                          {option.icon && 
                            <IconButton size='small' aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={option.description || option.name} placement="top"><FontAwesomeIcon icon={"fas fa-" + option.icon}/></Tooltip>
                            </IconButton>
                          }
                          {option?.value || option?.name || option?.description || option?.id || option}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            sx={{
                                width: '100%',
                                position: 'relative',
                                transition: 'all 0.5s ease'
                            }}
                            {...params}
                            variant={schema.settings.variant || 'outlined'}
                            label={schema.settings.label}
                            placeholder={schema.settings.placeholder}
                            helperText={(schema.settings.help || "") + (schema.error || "")}
                            required={schema.behaviour?.mandatory}
                            error={schema.error?true:false}
                        />
                    )}
                />
            }
                
        </>
    )
}

export default ImageDropdownComponent;

ImageDropdownComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};