import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import ThemeColors from '@crema/core/AppThemeSetting/ThemeColors';
// import ThemeModes from '@crema/core/AppThemeSetting/ThemeModes';
import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useThemeContext, 
  useThemeActionsContext 
} from '@crema/utility/AppContextProvider/ThemeContextProvider';

export default function Theme({schema}) {
  const { theme } = useThemeContext();
  const { updateTheme } = useThemeActionsContext();
  useEffect (() => {
    if (!schema.getPropertyValue ()) schema.setPropertyValue (theme);
    else updateTheme (schema.getPropertyValue ());
  }, []);

  return (
    <>
    {schema && !schema.behaviour?.hidden && 
    <Box sx={{mt:5}}>
      {schema.settings?.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings.label}</FormLabel>}
      <Box sx={{width: '100%', border: schema.settings?.label?1:0, borderRadius: 2, borderColor: 'grey.400', p:schema.settings?.label?5:0}}> 
        {schema.settings?.help && <pre><Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom >
            {schema.settings.help || ""}
        </Typography></pre>}
        <Box
          sx={{
            padding: {xs: '20px', xl: '28px 22px'},
          }}
        >
          {/* <ThemeModes /> */}
          <ThemeColors />
        </Box>
      </Box>
    </Box>}
    </>
  );
}

Theme.propTypes = {
  schema: PropTypes.object.isRequired
};