import React, { useEffect, useState } from 'react';
import Editor from "@monaco-editor/react";
import Typography from '@mui/material/Typography';
import RuleComponent from './RuleComponent';
import uuid from 'react-uuid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';

const EditorComponent = ({schema}) => {
    const [value, setValue] = useState({});
    const dispatch = useDispatch();
    useEffect (() => {
        setValue (schema.getPropertyValue());
    }, [schema]);
    
    const onChange = (newValue) => {
        value.script = newValue;
        setValue (value);
        schema.setPropertyValue (value);
    }
    const handleEditorValidation = (markers) => {
        value.errors = [];
        markers.forEach(marker => {
            value.errors.push (marker.message);
        });
        setValue (value);
        schema.setPropertyValue (value);
        if (value.errors.length>0){
            schema.getParent ().__setError__ (schema.__name, "Error in the configuration");
        } else schema.getParent ().__setError__ (schema.__name, null);
    }
    const onAddRule = () => {
        if (!value?.rules) value.rules = [];
        value.rules.push ({
            id: uuid (),
            name: "Set Name",
            description: "Set Description",
            script: "// Write your rules here\n\n\n",
            compiled: "",
            isActive: true
        });
        schema.setPropertyValue (value);
    }

    const onReOrderRule = (rules) => {
        value.rules = rules;
        schema.setPropertyValue (value);
    }

    const onDeleteRule = (id) => {
        schema.getParent().__confirmation__ ({
            title: "Warning",  
            message: "Are you sure, you want to delete this rule?",
            events: [{
                label: "Cancel"
            },  {
                label: "Yes, Please",
                onClick: () => {
                    value.rules = value.rules.filter ((rule) => rule.id !== id);
                    schema.setPropertyValue (value);
                }
            }]
        });
    }

    const suggestions = [];
    const updateSuggestions = (suggestion) => {
        suggestions.push (suggestion)
    }

    const generateJSONSuggestion = (monaco) => {
        let suggs=[];
        (schema.getPropertyValue().rules || []).forEach ((rule)=>{
            if (!rule.error)
            suggs.push ({
                label: rule.name + " (Rule)",
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: '{"id": "'+rule.id+'", "name":"'+ rule.name +'"}'
            });
        })
        return { suggestions: suggs };
    }
    let jsProvider, jsonProvider;
    
    useEffect( () => () => {
        jsProvider?.dispose ();
        jsonProvider?.dispose ();
    }, [] );

    const handleEditorDidMount = (editor, monaco) => {
        updateSuggestions ({
            label: "get / access attribute value",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "let value = __getValue__ ('<key>'); /*Please provide key to access the value (key is the attribute identifier)*/"
        });
        updateSuggestions ({
            label: "set / update attribute value",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__setValue__ ('<key>', '<value>'); /*Please provide key to update the value (key is the attribute identifier)*/"
        });
        updateSuggestions ({
            label: "Validate Form / isValid",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__isValid__ (); /*This function return the oject level all attribute's validation result*/"
        });
        updateSuggestions ({
            label: "Set Error at attribute level",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__setError__ ('<key>', '<message>'); /*To manually update the error at attribute level, please select the key and update the message to be printed*/"
        });
        updateSuggestions ({
            label: "Create a base function / evaluate function",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__evaluate__ = (data) => /*This is a root function; which is called from the form, please do not add var / let infront of this function*/\r{\r}"
        });
        updateSuggestions ({
            label: "Set / Update datasource function",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__setDataSource__ ('key', []); /*Please select the key for which you want to update the datasource, the data element must be an array and the attribute whould be able to load from datasource*/"
        });
        updateSuggestions ({
            label: "Backdrop function",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__backdrop__ ({ isOpen: <true / false>, message: '<text to appear>'}); /*isOpen to show or hide the backdrop and messahe is to display information*/"
        });
        updateSuggestions ({
            label: "Reload the page",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "__reload__ ({ schema: <schema>, model: <model || null>}) /* This function will reload the page with a given schema and model, please use this very caryfully*/"
        });
        updateSuggestions ({
            label: "Confirmation Dialog",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "/*This function shows confirmation dialog like Yes, No, Cancel*/\r__confirmation__ ({\r\ttitle: \"Set a Title\",\r\tmessage: \"Your Message\",\r\tevents: [{\r\t\tlabel: \"Cancel\",\r\t\tonClick: <Your Function>\r\t}]\r});"
        });
        if (schema.href)
            dispatch (callService (schema.href, null, (success) => {
                if (_.isArray(success.data)){
                success.data.forEach ((o) => {
                    updateSuggestions ({
                    label: o.name + " (" + o.description + ")",
                    kind: monaco.languages.CompletionItemKind.Text,
                    insertText: "__callService__ ('/api/"+o.id+"', /* " + o.name + " ( " + o.description + " )"+ " */\r\tnull, /* Send an JSON object to the API or send null*/\r\t(success) => /* API response, please for data use success.data and code success.code */\r\t{},\r\t(failure) => /* In case faulure API send error as string */\r\t{});"
                    })
                });
                }
            }, (failure)=> {
                console.log (failure)
            }));
        jsProvider?.dispose ();
        jsonProvider?.dispose ();
        jsonProvider = monaco.languages.registerCompletionItemProvider('json', {
            provideCompletionItems: () => generateJSONSuggestion(monaco)
        });
        jsProvider = monaco.languages.registerCompletionItemProvider('javascript', {
            provideCompletionItems: () => {return { suggestions: _.cloneDeep (suggestions) }}
        });
    }

    const onUpdateRule = () => schema.setPropertyValue (value);
    return (
        <>
            <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                {schema.settings.label}
            </Typography>
            
            <RuleComponent onAddRule={onAddRule} rules={value.rules} onDeleteRule={onDeleteRule} onUpdateRule={onUpdateRule} onReOrderRule={onReOrderRule}/>
            <Typography sx={{fontSize: 12}} color={(schema.error || (value?.errors||[]).length>0)?"red":"text.secondary"} gutterBottom >
                {schema.settings.help + ((schema.error || (value?.errors||[]).length>0)? ".. Compilation Error occured, please fix":"") }
            </Typography>
            {schema && 
            <Box m={1} >
                <Editor
                    theme={schema.settings.theme || "vs-dark"}
                    language={schema.settings.language || "json"}
                    value={value.script}
                    height="80vh"
                    options = {{
                        minimap: { enabled: false }
                    }}
                    onValidate={handleEditorValidation}
                    onChange={onChange}
                    onMount={handleEditorDidMount}
                />
                {schema.error && <Typography variant='h6' gutterBottom  component='div' color="red"><pre>{schema.error}</pre></Typography>}
                {(value.errors || []).map ((error, index) => {
                    return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
                })}
            </Box>}
        </>
    )
}

export default EditorComponent;

EditorComponent.propTypes = {
    schema: PropTypes.object.isRequired
  };