import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import Members from './Members';
import {Fonts} from 'shared/constants/AppEnums';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const EventDetail = ({ event }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', }} >
        <Box sx={{ fontSize: Fonts.SMALL, flexGrow: 1, color: "white" }} >{event.title}</Box>
        {event.resources && event.resources.length > 0 && 
          <Box sx={{ display: 'flex', alignItems: 'center', }} >
            <Members members={event.resources} />
          </Box>
        }
      </Box>
      <Typography sx={{fontSize: 10, color: "white" }} gutterBottom >{moment(event.start).format ("HH:mm")} - {moment(event.end).format ("HH:mm")}</Typography>
    </Box>
  );
};

export default EventDetail;

EventDetail.propTypes = {
  event: PropTypes.object.isRequired
};
