import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@mui/material/Link';
import {useThemeContext} from '@crema/utility/AppContextProvider/ThemeContextProvider';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';

const SimpleListItem = ({data,schema}) => {
    const {theme} = useThemeContext();
    return (
    <>
        <ListItem alignItems='flex-start'>
            {data.profile && 
            <ListItemAvatar>
                <Tooltip title={data.profile.name} key={data.profile.id}>
                    <Avatar alt={data.profile.name} src={data.profile.avatar || data.profile.name.toUpperCase()} />
                </Tooltip>
            </ListItemAvatar>}
            <ListItemText
                sx={{ '.newline': { "whiteSpace": "pre-wrap", "wordWrap": "break-word" }, }}
                primary={schema.settings.linkTo?
                    <a href={schema.settings.linkTo + data.id }>{data.label}</a>:
                    <Link component='button' onClick={() => { schema.onClick && schema.onClick (data); }}>{data.label}</Link>}
                secondary={<>
                        {data.modifiedOn && <span className='newline' style={{fontSize: 11, color:theme.palette.text.disabled}}>{ moment.utc(data.modifiedOn).format('D/MM/YYYY, h:mm:ss a').split(',') + "\n" }</span>}
                        {data.description && <span className='newline' style={{color:theme.palette.secondary.main}}>{schema.settings.item?.description?.icon?.code && <><FontAwesomeIcon color={theme.palette.secondary.main} icon={"fas fa-"+schema.settings.item.description.icon.code}/>&nbsp;</>}<span className='newline'>{data.description + "\n\n"}</span></span>}
                        {data.help && <span className='newline' style={{color:theme.palette.primary.main}}>{schema.settings.item?.help?.icon?.code && <><FontAwesomeIcon color={theme.palette.primary.main} icon={"fas fa-"+schema.settings.item.help.icon.code}/>&nbsp;</>}<span className='newline'>{data.help + "\n"}</span></span>}
                        {data.moreInfo?.map ((d, i) => <span className='newline' style={{fontSize: 11, color:theme.palette.text.primary}} key={i}>{d + "\n"}</span>)}
                    </>
                }
            />
            
            {data.image && <Box sx={{ xs: -1, xl: -2, height: {xs: 140}, display: 'flex', flexDirection: 'column', 
                    alignItems: 'center', justifyContent: 'top', '& img': { maxHeight: '100%', maxWidth: '100%', overflow:"hidden"}, }}>
                <img src={data.image} loading="lazy" 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/app/getImage?id=404";
                    }} />
            </Box>}
        </ListItem>
        <Divider variant='inset' />
      </>
  );
};

export default SimpleListItem;

SimpleListItem.propTypes = {
  data: PropTypes.object,
  schema: PropTypes.object.isRequired,
};
