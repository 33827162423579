import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import BpmnModeler from "bpmn-js/lib/Modeler";
// import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule} from 'bpmn-js-properties-panel';
// import propsModdleDescriptor from '../resources/props';
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "@bpmn-io/properties-panel/dist/assets/properties-panel.css";
import customContextPadModule from "./context-pad";
import customRules from "./rules";
// import customPropertiesProviderModule from "./property";
import './../styles.css';

const xml = `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.3.5">
        <bpmn:collaboration id="Collaboration_1nn1a0w">
            <bpmn:participant id="Participant_0uac119" processRef="Process_1" />
        </bpmn:collaboration>
        <bpmn:process id="Process_1" isExecutable="true" />
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_1nn1a0w">
                <bpmndi:BPMNShape id="Participant_0uac119_di" bpmnElement="Participant_0uac119" isHorizontal="true">
                    <dc:Bounds x="150" y="30" width="600" height="250" />
                </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
    </bpmn:definitions>`;

const BPMNModelerEditor = ({schema}) => {
    const bpmnContainer = useRef();
    // const propsContainer = useRef();
    const [modeler, setModeler] = useState ("");
    const [data, setData ] = useState ({});
    const marker = schema.settings?.marker?.default || { stroke: "black", fill: "white" };
    const error = schema.settings?.marker?.error || { stroke: "#B71C1C", fill: "#FFCDD2" };
    const success = schema.settings?.marker?.success || { stroke: "#004D40", fill: "#E0F2F1" };

    useEffect (() => {
        updateMarker (true);
    }, [data]);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isObject (schema.datasource) && !_.isEqual (data, schema.datasource.markers)){
            updateMarker ();
            setData (schema.datasource.markers);
            // This is to hold the change in datasource every time
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const updateMarker = (isSet) => {
        data?.error && setMarker (data.error, isSet?error:marker);
        data?.data?.map ((elm) => {
            let element = modeler.get('elementRegistry')?.get(elm ["@id"]);
            element && setMarker (elm ["@id"], isSet?success:marker);
        })
    }

    const setMarker = (elementId, color) => {
        if (!modeler) return;
        let modelling = modeler.get('modeling');
        if (!modelling) return;
        let element = modeler.get('elementRegistry')?.get(elementId);
        if (!element) return;
        modelling.setColor ([element], color);
    }
    
    useEffect (() => {
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
        const container = bpmnContainer.current;
        // const pContainer = propsContainer.current;
        const bpmnModeler = new BpmnModeler({ container, 
            keyboard: { bindTo: document },
            // propertiesPanel: { parent: pContainer },
            additionalModules: [ customContextPadModule, customRules, 
                // BpmnPropertiesPanelModule, BpmnPropertiesProviderModule, 
                // customPropertiesProviderModule
            ],
            // moddleExtensions: { props: propsModdleDescriptor }
        });
        const model = schema.getPropertyValue () || xml;
        bpmnModeler
            .importXML(model)
            .then(({ warnings }) => {
                if (warnings.length) {
                    console.log(warnings);
                }
                const canvas = bpmnModeler.get("canvas");
                canvas.zoom("fit-viewport");
            })
            .catch((err) => {
                console.log(err);
            });
            
        bpmnModeler.on('element.changed', () => {
            bpmnModeler.saveXML()
                .then((data) => {  schema.setPropertyValue (data.xml);})
                .catch(function (err) { console.log (err); });
        });

        bpmnModeler.on ("onSettings", 10000, (e)=>{
            if (schema.onSettings){
                schema.onSettings ({ id:e.element.id, name:e.element.businessObject.name, type: e.element.type });
            }
            return true;
        });

        setModeler (bpmnModeler);
        return () => {
            bpmnModeler.destroy ();
        }
    }, []);
        
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                <Box sx={{width: '100%', border: (!schema.settings?.variant || schema.settings?.variant=="outlined")?1:0, borderRadius: 2, borderColor: 'grey.400', mt:5, p:3, pl:5, pr:5}}>
                    {schema.settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                        {schema.settings.label}
                    </Typography>}
                    {(schema.error || schema.settings.help) && <Typography sx={{fontSize: 12}} color={(schema.error?.message || schema.error)?"red":"text.secondary"} gutterBottom >
                        {(schema.settings.help || "") + ((schema.error)? ".. Compilation Error occured, please fix":"") }
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>}
                    {schema && 
                    <Box m={1} position="relative">
                        <Box ref={ bpmnContainer } height={schema.settings.height || "70vh"}></Box>
                        {/* <Box ref={ propsContainer } width="300px" sx={{ border: '1px dashed grey'}} position="absolute" top="0px" right="0px"></Box> */}
                        {schema.error && <Typography variant='h6' gutterBottom  component='div' color="red"><pre>{schema.error.message || schema.error}</pre></Typography>}
                    </Box>}
                </Box>
            </>}
        </>
    )
}

export default BPMNModelerEditor;
BPMNModelerEditor.propTypes = {
    schema: PropTypes.object.isRequired,
};