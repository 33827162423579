import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export default function Label({value, onChange}) {
  return (
    <TextField fullWidth sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
      }}
      required
      id='label'
      label='Attribute Label'
      variant='outlined'
      value={value}
      onChange={(e)=>onChange ("label", e.target.value)}
      helperText="Enter Label to display on the attribute"
      InputProps={{ inputProps: { maxLength: 30 } }}
    />
  );
}

Label.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};