import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const StringComponent = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Box sx={{display:"flex" }}>
                    <TextField
                        sx={{
                            width: '100%',
                            position: 'relative',
                            transition: 'all 0.5s ease'
                        }}
                        required={schema.behaviour?.mandatory}
                        disabled={schema.behaviour?.readonly}
                        id={schema.__id}
                        label={schema.settings.label}
                        type={schema.settings.type || "string"}
                        helperText={(schema.settings.help || "") + " " + (schema.error || "")}
                        value={schema.getPropertyValue()}
                        multiline={schema.settings.multiline}
                        placeholder={schema.settings.placeholder || ""}
                        minRows={1}
                        maxRows={schema.settings.maxRows || 4}
                        onChange={(e) => {
                            schema.setPropertyValue (e.target.value);
                            schema.getParent().__isValid__ ();
                            if (schema.onChange) schema.onChange();
                        }}
                        onBlur={(e) => {
                            schema.setPropertyValue (e.target.value);
                            schema.getParent().__isValid__ ();
                            if (schema.onBlur) schema.onBlur();
                        }}
                        variant={schema.settings.variant || 'outlined'}
                        error={schema.error?true:false}
                    />
                    {schema.settings.helplink && 
                        <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                            <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                        </IconButton>
                    }
                </Box>
            }
        </>
    )
}

export default StringComponent;

StringComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};