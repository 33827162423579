import React from 'react';
import Box from '@mui/material/Box';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';

const ContentItem = ({data}) => {
  return (
    <>
    {data && <Box sx={{ display: 'flex', alignItems: 'center', mb:4}}>
      {data.cover_image && 
      <Box sx={{fontSize: {xs: 36, md: 48}}}>
        <Avatar sx={{ height: {xs: 50, md: 60}, width: {xs: 50, md: 60}, backgroundColor: 'transparent', }}>
          <img src={data.cover_image} alt={data.title} />
        </Avatar>
      </Box>}
      <Box sx={{ml: data.cover_image?4:0,}}>
        {data.linkTo ? 
          <Link sx={{ fontSize: 16, fontWeight: Fonts.MEDIUM, mb: 2, }} href={data.linkTo} underline="hover">{data.title}</Link> :
          <Box component='p' sx={{ fontSize: 16, fontWeight: Fonts.MEDIUM, mb: 2, }} >{data.title}</Box>}
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', }} >
          <Box component='p'>{data.description}</Box>
        </Box>
      </Box>
    </Box>}
    </>
  );
};

export default ContentItem;

ContentItem.propTypes = {
  data: PropTypes.object,
};
