import React, { useEffect, useState } from 'react';
import AppAnimate from '@crema/core/AppAnimate';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageItem from './ImageItem';
import * as _ from "lodash";

const ImagePortfolio = ({schema}) => {
    const [images, setImages] = useState([]);
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(images).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setImages (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);
    return (
        <>
        {schema && !schema.behaviour?.hidden && <AppAnimate animation='transition.slideUpIn' delay={200}>
            <ImageList variant={schema.settings.variant || "standard"} cols={schema.settings.cols || 4} gaps={schema.settings.gaps || 4}>
                {images.map((item) => {
                    return (
                    <ImageItem key={item.id} item={item} schema={schema}/>
                )}
            )}
            </ImageList>
        </AppAnimate>}
        </>
    );
};

export default ImagePortfolio;
ImagePortfolio.propTypes = {
    schema: PropTypes.object.isRequired,
};