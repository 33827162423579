import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppAnimate from '@crema/core/AppAnimate';
import AppGridContainer from '@crema/core/AppGridContainer';
import {Grid} from '@mui/material';
import { Registry } from 'react-registry';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as _ from "lodash";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

export default function  GridLayoutContainer ({schema}) {
    useEffect(() => {
        setTimeout (()=> {  schema.onInit && schema.onInit ();  });
    },[]);
    return (
        <Box sx={{width: '100%', border: schema.settings?.border?1:0, borderRadius: 2, borderColor: 'grey.400', p:schema.settings?.border?5:0}}> 
            {(!_.has (schema.settings, "showLabel") || schema.settings.showLabel) && (schema.settings?.label || schema.settings?.help) && 
            <Box sx={{width: '100%'}}>
                {schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom component='div'>
                    {schema.settings.label}
                </Typography>}
            </Box>}
            {schema.settings.help && <Box sx={{width: '100%', mb:5}}>
                <pre><Typography sx={{fontSize: 12}} color={"text.secondary"} gutterBottom component='div'>
                    {schema.settings.help}
                    {schema.settings.helplink && 
                        <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                            <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                        </IconButton>
                    }
                </Typography></pre>
            </Box>}
            <AppAnimate animation={schema.settings?.animation || "transition.slideUpIn"} delay={schema.settings?.delay || 200}>
                <AppGridContainer sx={{mt:schema.settings?.margin?.top || "-40px", mb:schema.settings?.margin?.bottom || "0px"}}>
                    {!schema.behaviour?.hidden && schema.fieldsets?.map((fieldset) => {
                        return (fieldset.fields?.map ((field, index) => {
                            if (schema.properties [field]  && !schema.properties [field].behaviour?.hidden)
                            return (
                                <Grid item xs={fieldset.settings?.xs || 12} sm={fieldset.settings?.sm} lg={fieldset.settings?.lg} md={fieldset.settings?.md} xl={fieldset.settings?.xl} key={schema.__id + "-" + index}>
                                    {Registry.createElement(schema.properties [field].widget, {schema: schema.properties [field], model: schema.properties [field].value})}
                                </Grid>
                            )
                        }))
                    })}
                </AppGridContainer>
            </AppAnimate>
        </Box>
    )
}

GridLayoutContainer.propTypes = {
    schema: PropTypes.object.isRequired,
};