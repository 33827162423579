import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import {Box, Typography} from '@mui/material';
import {Fonts} from 'shared/constants/AppEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoWidgetCard1 = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    return (
        <AppCard
            sxStyle={{height: schema.settings?.height || 1, backgroundColor: schema.settings?.backgroundColor}}
            contentStyle={{px: 2}}
        >
            <Box sx={{ display: 'flex', }}>
                {schema.settings?.icon?.code && <Box sx={{  ml:2, mr: 3, alignSelf: 'center',}}>
                    <FontAwesomeIcon icon={schema.settings.icon.code} size={schema.settings.icon.size || "xs"} color={schema.settings.icon.color}/>
                </Box>}
                <Box sx={{ width: 'calc(100% - 50px)', display: 'flex', alignItems: 'center', }}>
                    <Box sx={{ flex: 1, color: schema.settings?.color || 'white', overflow: 'hidden', mr: 2, }}>
                        {schema.settings?.label && <Typography sx={{ fontWeight: Fonts.BOLD, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%',}}
                            component='h4'
                            variant='inherit'
                            color='inherit'
                        >
                            {schema.settings.label} 
                        </Typography>}
                        {schema.settings?.help && <Box component='p'
                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%'}}>
                            {schema.settings.help} 
                        </Box>}
                    </Box>
                    <Box
                        sx={{
                            height: 50,
                            width: 50,
                            ml: 'auto',
                            mr:2,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '50%',
                            justifyContent: 'center',
                            fontWeight: Fonts.BOLD,
                            color: (theme) => theme.palette.text.secondary,
                            backgroundColor: (theme) => theme.palette.background.paper,
                            '@media screen and (max-width: 1600px) and (min-width: 1200px)': {
                                display: 'none',
                            },
                        }}
                    >
                        {schema.getPropertyValue()}
                    </Box>
                </Box>
            </Box>
        </AppCard>
    )
}
export default InfoWidgetCard1;
InfoWidgetCard1.propTypes = {
    schema: PropTypes.object.isRequired
};