import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';

export default function Variant ({value, onChange}) {
  return (
    <FormControl fullWidth required 
      sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
      }}>
      <InputLabel>
        Input Variant
      </InputLabel>
      <Select
        labelId='variant-select-helper-label'
        id="variant-select-helper"
        value={value || "outlined"}
        label="Input Variant"
        onChange={(e) => onChange ("variant", e.target.value)}
      >
        <MenuItem value={"outlined"}>Outlined</MenuItem>
        <MenuItem value={"standard"}>Standard</MenuItem>
        <MenuItem value={"filled"}>Filled</MenuItem>
      </Select>
      <FormHelperText>This option to create outline / filled with grey of the input</FormHelperText>
    </FormControl>
  );
}

Variant.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};