import React, {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import * as _ from "lodash";

export default function Selection ({items, handleSelection}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    const {value} = e.currentTarget.dataset;
    if (value) handleSelection (value);
  };

  return (
    <>
      <Button endIcon={<AddIcon />} sx={{float: 'right'}} 
        aria-controls='subform-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >Organize Manually</Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {_.map (items, (item, index) => (!item.disabled && <MenuItem key={item.id + "" + index} data-value={item.id} disabled={item.disabled || false} onClick={handleClose}>{item.name}</MenuItem>))}
        {_.filter (items, (i) => !i.disabled).length == 0 && <MenuItem key={"NA"} disabled={true} >No Option Available</MenuItem>}
      </Menu>
    </>
  );
}

Selection.propTypes = {
  items: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired
};