import React from 'react';
import Box from '@mui/material/Box';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';

const ContentItem = ({data}) => {
  return (
    <Box sx={{ px: {sm: 3}, m:1}}>
      {data.cover_image && <Box sx={{ mb: 2, '& img': { width: '100%', }, }}>
        <img src={data.cover_image} alt={data.title} />
      </Box>}
      {data.linkTo ? 
        <Link sx={{ fontSize: 16, fontWeight: Fonts.MEDIUM, mb: 2, }} href={data.linkTo} underline="hover">{data.title}</Link> :
        <Box component='p' sx={{ fontSize: 16, fontWeight: Fonts.MEDIUM, mb: 2, }} >{data.title}</Box>}
      <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', }} >
        <Box component='p'>{data.description}</Box>
      </Box>
    </Box>
  );
};

export default ContentItem;

ContentItem.propTypes = {
  data: PropTypes.object,
};
