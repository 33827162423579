import React, { memo, useEffect } from "react";
import { Handle, Position, NodeResizer } from "reactflow";
import PropTypes from 'prop-types';

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const Rectangle =  ({ data, isConnectable=true, selected }) => {
    useEffect (() => {
        console.log (selected);
    }, [selected])
    return (
        <div>
            <NodeResizer color="#ff0071" isVisible={selected} minWidth={100} minHeight={30} />
            <div>{data.label}</div>
            <Handle
                type="source"
                id="red"
                position={Position.Bottom}
                style={{ ...DEFAULT_HANDLE_STYLE, left: "15%", background: "red" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
        </div>
    );
};

// Rectangle.displayName = "Rectangle";
export default memo (Rectangle);

Rectangle.propTypes = {
    data: PropTypes.object.isRequired,
    isConnectable: PropTypes.bool,
    selected: PropTypes.bool,
};