import React, { useEffect } from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath, useReactFlow, } from 'reactflow';
import PropTypes from 'prop-types';

export default function Bezier ({ id, data, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, selected }) {
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, });
    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };
    useEffect (() => {
        console.log (selected);
    }, [selected])

    return (
        <>
        <BaseEdge path={edgePath} style={style} onEdgeClick={onEdgeClick}/>
        <EdgeLabelRenderer>
            <div
                style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    fontSize: 10,
                    pointerEvents: 'all',
                }}
                className="nodrag nopan"
                >
                {data.label || ""}
            </div>
        </EdgeLabelRenderer>
        </>
    );
}

Bezier.propTypes = {
    id: PropTypes.string, 
    data: PropTypes.object.isRequired,
    sourceX: PropTypes.number, 
    sourceY: PropTypes.number, 
    targetX: PropTypes.number, 
    targetY: PropTypes.number, 
    sourcePosition: PropTypes.string, 
    targetPosition: PropTypes.string, 
    style: PropTypes.object,
    selected: PropTypes.bool,
};