export default class CustomTaskPad {
    constructor(config, contextPad, create, elementFactory, injector, translate) {
        this.create = create;
        this.elementFactory = elementFactory;
        this.translate = translate;
        if (config.autoPlace !== false) {
            this.autoPlace = injector.get('autoPlace', false);
        }
        contextPad.registerProvider(this);
    }

    getContextPadEntries() {
        const { translate } = this;

        return {
            'settings': {
                group: 'model',
                className: 'bpmn-icon-service',
                title: translate('Configure Activity'),
                action: {}
            }
        };
    }
}

CustomTaskPad.$inject = [
    'config',
    'contextPad',
    'create',
    'elementFactory',
    'injector',
    'translate'
];