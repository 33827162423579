import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { FormLabel } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { Registry } from 'react-registry';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';

export default function  ListLayoutContainer ({schema}) {
    const [childId, setChildId] = useState (null);
    const [title, setTitle] = useState ("");
    const child = useMemo (() => {
        if (!childId) return;
        const prop = schema.properties [childId];
        prop.settings.showLabel = false;
        setTitle (prop.settings.label || "");
        return Registry.createElement(prop.widget, {schema: prop, model: prop.value})
    }, [schema, childId]);

    useEffect(() => {
        setTimeout (()=> {  schema.onInit && schema.onInit ();  });
    },[]);
    return (
        <>
            {schema && !schema.behaviour?.hidden && <>
                {schema.settings.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings.label}</FormLabel>}
                <Box sx={ schema.settings.style || schema.settings.label?{width: '100%', border: 1, borderRadius: 2, borderColor: 'grey.400', p:5}:{width: '100%', mt:-5}}>
                    {schema.settings.help && <pre><Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                        {schema.settings.help}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography></pre>}
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="list"
                    >
                        {!schema.behaviour?.hidden && schema.fieldsets?.map((fieldset) => {
                            return (fieldset.fields?.map ((field) => {
                                if (schema.properties [field]  && !schema.properties [field].behaviour?.hidden)
                                return (
                                    <Box key={field}>
                                        <ListItemButton onClick={()=>{ setChildId (field)}}>
                                            <ListItemText primary={schema.properties [field].settings?.label || "Undefined"} secondary={schema.properties [field].settings?.help} />
                                            <ListItemIcon>
                                                <ArrowForwardIosIcon />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                    </Box>
                                )
                            }))
                        })}
                    </List>
                </Box>
                <Drawer anchor={"right"} open={childId?true:false} disableEnforceFocus>
                    <AppBar position="sticky">
                        <Toolbar color="primary" sx={{backgroundColor: "#FFF"}}>
                            {title && 
                            <Typography sx={{ color: "#000", flexGrow: 1, }} variant="h4" component="span">
                                {title}
                            </Typography>}
                            <Stack direction="row" spacing={0}>
                                <IconButton size={"xs"} color="primary" onClick={() => { setChildId (null); setTitle (""); } }>
                                    <Tooltip title={"Close"} placement="left" arrow><FontAwesomeIcon icon={"xmark"} /></Tooltip>
                                </IconButton>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{width: {"sm": "100%", "lg": "70vw"}, p:5}} role="presentation">
                        {child}
                    </Box>
                </Drawer>
            </>}
        </>
    )
}

ListLayoutContainer.propTypes = {
    schema: PropTypes.object.isRequired,
};