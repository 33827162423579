import React from 'react';
import {alpha, Box, Stack} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PlaceIcon from '@mui/icons-material/Place';
import AppsIcon from '@mui/icons-material/Apps';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';

const IconBtn = styled(IconButton)(({theme}) => {
    return {
        color: theme.palette.text.disabled,
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        padding: 8,
        '&:hover, &:focus': { color: theme.palette.primary.main, },
        '&.active': { color: theme.palette.primary.main, },
    };
});
const Header = ({ onSearch, viewType, setModeView }) => {

    const onChangeMode = (mode) => {
        setModeView (mode)
    }
    return (
        <Box  sx={{ display: 'flex', flex: 1, alignItems: 'center', }}>
        <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', mr: 'auto', }}>
            <Tooltip title={"List View"} placement="top">
                <IconBtn onClick={() => onChangeMode ("block")} className={clsx({ active: viewType === "block", })}><ListIcon /></IconBtn>
            </Tooltip>
            <Tooltip title={"Grid View"} placement="top">
                <IconBtn onClick={() => onChangeMode ("grid")} className={clsx({ active: viewType === "grid" })}><AppsIcon /></IconBtn>
            </Tooltip>
            <Tooltip title={"Map View"} placement="top">
                <IconBtn onClick={() => onChangeMode ("map")} className={clsx({ active: viewType === "map" })}><PlaceIcon /></IconBtn>
            </Tooltip>
        </Stack>
        <Box sx={{mr: 3}}>
            <Tooltip title={"Filter"} placement="top"><IconBtn onClick={() => console.log (onSearch)} ><FilterAltIcon /></IconBtn></Tooltip>
        </Box>
        </Box>
    );
};

export default Header;

Header.propTypes = {
    viewType: PropTypes.string,
    onSearch: PropTypes.func,
    setModeView: PropTypes.func
};
