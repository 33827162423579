import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Label from '../Label';
import Help from '../Help';
import Variant from '../Variant';
import Placeholder from '../Placeholder';
import PropTypes from 'prop-types';

const SwitchWidget = ({settings, onChange}) => {
    const onPropertyChange = (name, value) => {
        settings[name]=value;
        onChange (settings);
    }
    return (
        <>
            <Box sx={{m:1, width: '100%'}}>
                <Typography variant='h5' gutterBottom component='div'>
                Switch (On/Off) Widget Settings
                </Typography>
                <Typography variant='h6' gutterBottom component='div'>
                Switch widget properties, which will be applied on the widget
                </Typography>
            </Box>
            <Label value={settings.label || "SET_ME"} onChange={onPropertyChange}/>
            <Help value={settings.help || ""} onChange={onPropertyChange}/>
            <Placeholder value={settings.placeholder || ""} onChange={onPropertyChange}/>
            <Variant value={settings.variant || ""} onChange={onPropertyChange}/>
        </>
    )
}

export default SwitchWidget;
SwitchWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
  };