import React, { useEffect, useState } from 'react';
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import PropTypes from 'prop-types';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';

export function CustomFooterComponent({value}) {
  return (
      <Box sx={{ p: 2, display: 'flex', border: 1, borderColor: 'grey.300', mt:5 }}>
          <Typography align="center">{value || "Loading ..."}</Typography>
      </Box>
  );
}
CustomFooterComponent.propTypes = {
  value: PropTypes.string
};

function ExportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const LazyGrid = ({schema}) => {
    let state = true;
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [value, setValue] = useState ();
    const dispatch = useDispatch();
    const {theme} = useThemeContext();
    const [columnVisible, setColumnVisible] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [style, setStyle] = useState({
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: schema.settings?.header?.backgroundColor || theme.palette.primary.main,
        color: schema.settings?.header?.color || theme.palette.primary.contrastText
      }
    });
    useEffect(() => {
      (schema?.settings?.style) && setStyle (_.assign (style, schema?.settings?.style));
      if (!_.isEqual(schema.settings?.columnVisibilityModel, columnVisible)){
        setColumnVisible (schema.settings?.columnVisibilityModel);
      }
      if (schema?.settings?.columns){
        if (schema.settings.action && _.filter(schema.settings.columns, a => a.field === 'actions').length === 0){
          schema.settings.columns.push({
            field: "actions",
            type: "actions",
            headerName: schema.settings.action.headerName || "Operations",
            width: schema.settings.action.width || 150,
            getActions: (params) => {
              const userActions = [];
              schema.settings.action.actions.forEach ((action) => {
                (!params.row.hideAction && !action.hidden) && userActions.push (
                      <GridActionsCellItem
                        icon={<Tooltip title={action.label} placement="top"><FontAwesomeIcon icon={action.icon?.code} color={action.icon?.color || theme.palette.primary.main}/></Tooltip>}
                        label={action.label}
                        onClick={() => (action.event && schema[action.event] && schema[action.event] (params.row))}
                      />
                  )
              });
              return userActions;
            }
          });
        }
        schema.settings.columns.forEach ((c) => {
          if (c.linkTo){
            c.renderCell = (params) => {
              return (<a href={c.linkTo + (c.linkRef?params.row[c.linkRef]:params.id) }>{params.value}</a>)
            }
          }
          if (c.special?.applyLocalDate){
            c.renderCell = (params) => {
              return (<>{params.value && moment.utc(params.value).local().format(c.special.format || 'llll').split(',')}</>)
            }
          }
        })
        setColumns(schema.settings.columns);
        setTimeout (()=> {
          schema.onInit && schema.onInit ();
          if (schema.onLoad) {
              let prevData = [];
              setValue ("Loading ..."); schema.onLoad (prevData, loadRows);
          }  
        }, 100);
        if (schema.href){
          dispatch (callService (schema.href, null, (success) => {
            if (_.isArray(success.data)) setRows (success.data);
          }, (failure)=> {
              console.log (failure)
          }));
        }
      }
      schema.getParent ().__setSettings__ (schema.__name, schema.settings);
      return () => { state = false }
    },[]);

    const loadRows = (datasource) => {
      if (schema.settings.lazyloading && !_.isArray (datasource)){
        setLoading (!datasource.isFinal);
        if (datasource.info){
            setValue (datasource.info);
            datasource.data = _.uniqBy (datasource.data, "id");
            if (datasource.data && datasource.data.length>0 && state) {
              let prevData = datasource.data;
              setRows (prevData);
              setTimeout (() => {
                !schema.settings.lazyloading.manual && !datasource.isFinal && schema.onLoad (prevData, loadRows);
              })
            } else return;
        }
      }
    }

    useEffect(() => {
      if (_.isArray (schema.datasource) && schema.settings.__hasDataSourceUpdated && !_(rows).xorWith(schema.datasource, _.isEqual).isEmpty()){
          setRows (schema.datasource);
          schema.onDataLoad && schema.onDataLoad ();
          schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const handleRowClick = (params) => {
      if (!schema.settings?.checkboxSelection) schema.setPropertyValue (params);
      schema.onRowClick && schema.onRowClick (params);
    };


    return (
      <>
        {schema && !schema.behaviour?.hidden && 
          <Box sx={{ width: '100%', height: schema.settings?.height || "80vh", mb:5 }}>
            {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
              {schema.settings.label}
            </Typography>}
            {schema.settings.help && <Typography sx={{fontSize: 11, pb:1}} color="text.secondary" gutterBottom>
              {schema.settings.help}
              {schema.settings.helplink && 
                  <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                      <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                  </IconButton>
              }
            </Typography>}
            <DataGridPro 
              checkboxSelection={schema.settings?.checkboxSelection || false}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (schema.settings?.checkboxSelection) schema.setPropertyValue (newRowSelectionModel);
              }}
              sx={style}
              rows={rows}
              columns={columns}
              columnVisibilityModel = {columnVisible}
              onColumnVisibilityModelChange={(newModel) =>setColumnVisible (newModel)}
              disableMultipleRowSelection={schema.settings?.disableMultipleRowSelection || false}
              hideFooterSelectedRowCount={schema.settings?.disableMultipleRowSelection || false}
              initialState={{ pinnedColumns: schema.settings.pinnedColumns || {} }}
              rowHeight={38}
              onRowClick={handleRowClick}
              onFilterModelChange={(filter)=>{schema.onFilter && schema.onFilter (filter)}}
              getRowClassName={(params)=>{
                for (let rule in (schema.settings?.applyStyleIf || [])){
                  if (params.row[schema.settings?.applyStyleIf[rule].field] == schema.settings?.applyStyleIf[rule].value){
                    return schema.settings?.applyStyleIf[rule].className;
                  }
                }
              }}
              slots={schema.settings.lazyloading && {
                // footer: CustomFooterComponent,
                toolbar: ExportToolbar,
                loadingOverlay: LinearProgress
              }}
              loading={loading}
              slotProps={{
                footer: { value },
              }}
            />
          </Box>
        }
      </>
    )
}

export default LazyGrid;

LazyGrid.propTypes = {
  schema: PropTypes.object.isRequired,
};