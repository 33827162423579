import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AppsContent from '@crema/core/AppsContainer/AppsContent';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CardDetail from './CardDetail';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import {grey} from '@mui/material/colors';
import {Fonts} from 'shared/constants/AppEnums';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const onDragEnd = (result, columns, setColumns) => {
    const { source, destination } = result;
    // Moving to new bucket
    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = _.find (columns, (c) => c.id == source.droppableId);
        const destColumn = _.find (columns, (c) => c.id == destination.droppableId); 
        const [removed] = sourceColumn.items.splice(source.index, 1);
        destColumn.items.splice(destination.index, 0, removed);
        setColumns(columns);
    } else {
        const column = _.find (columns, (c) => c.id == source.droppableId);
        column.items = reorder( column.items, source.index, destination.index);
        setColumns(columns);
    }
};
export default function Scrumboard ({schema}) {
    const [columns, setColumns] = useState([]);
    const [option, setOption] = useState({ readonly: true });
    useEffect (()=>{
        setTimeout (() => {schema.onInit && schema.onInit ();});
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(columns).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setColumns (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    useEffect (() => {
        if (schema.settings?.option) setOption (schema.settings.option);
    },[schema.settings]);

    const onCardReorder = (result) => {
        if (!result.destination) return;
        
        onDragEnd(result, columns, setColumns);
        schema.getParent ().__setDataSource__ (schema.__name, columns);
        schema.onReOrder && schema.onReOrder ({
            id: result.draggableId, old_bucket_id: result.source.droppableId, new_bucket_id: result.destination.droppableId, position: result.destination.index
        });
    }

    const onCardClick = (item) => {
        schema.onCardClick && schema.onCardClick (item);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {(!_.has (schema.settings, "showLabel") || schema.settings?.showLabel) && schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                            {option.name || schema.settings.label || ""}
                        </Typography>}
                        {(option.description || schema.settings.help) && 
                            <Typography sx={{fontSize: 12}} color='text.secondary' gutterBottom>
                                {option.description || schema.settings.help} 
                                {schema.settings.helplink && 
                                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                    </IconButton>
                                }
                            </Typography>
                        }

                    </Box>
                    {!option.readonly && schema.settings.menu && <Box>
                        <Tooltip title={schema.settings.menu.help || schema.settings.menu.label} placement="top">
                            <Button  variant='contained' endIcon={<FontAwesomeIcon icon={schema.settings.menu.icon.code} size={schema.settings.menu.icon.size || "xs"}/>} sx={{backgroundColor: schema.settings.menu.icon.color}}
                                onClick={handleClick}>
                                {schema.settings.menu.label}
                            </Button>
                        </Tooltip>
                        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                            {schema.settings.menu.actions?.map ((a, index)=> {
                                return (
                                    <Tooltip title={a.help || "Click Me"} placement="top" key={index}>
                                        <MenuItem onClick={()=>{handleClose();a.event && schema [a.event]  && schema [a.event] ()}} disableRipple >
                                            <IconButton key={"_"+index} size={a.icon.size || "small"} sx={{color: a.icon.color}}><FontAwesomeIcon icon={a.icon.code} /></IconButton>
                                            <Typography variant="inherit" sx={{color: a.icon.color}}>{a.label}</Typography>
                                        </MenuItem>
                                    </Tooltip>
                                )}
                            )}
                        </Menu>
                    </Box>}
                </Box>
                <AppsContent sx={{ flex: 1, 
                        '& .simplebar-wrapper': { my: '0 !important', height: '100%', },
                        '& .simplebar-content': { height: '100%', maxHeight: '100%', py: '0 !important', },
                    }}
                >
                    <Box sx={{ display: "flex", height: "100%",  }}>
                        <DragDropContext onDragEnd={(result) => onCardReorder (result)}>
                            {columns.map((col) => {
                                return (
                                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center" }} key={col.id}>
                                    <Card variant="outlined" sx={{ mb: 2, whiteSpace: 'normal', width: 345, background: col.headerColor || "#FAFAFA", "boxShadow":0}}>
                                        <CardHeader
                                            action={
                                                <Stack direction="row" spacing={0}>
                                                    {!option.readonly && schema.settings.bucket?.actions?.map ((a, index)=> {
                                                        if (a.icon)
                                                            return (
                                                            <IconButton key={"_"+index} size={a.icon.size || "xs"} sx={{color: col.textColor || a.icon.color}}
                                                                onClick={() => a.event && schema [a.event]  && schema [a.event] (col) }
                                                            >
                                                                <Tooltip title={a.help || "Click Me"} placement="top"><FontAwesomeIcon icon={a.icon.code} /></Tooltip>
                                                            </IconButton>)
                                                        }
                                                    )}
                                                </Stack>
                                            }
                                            title={<Typography sx={{color: col.textColor, fontWeight: 'bold'}} variant="h5" component="span">{col.name} ({(col.items || []).length})</Typography>}
                                        />
                                    </Card>
                                    <Box sx={{ m:0.5 }}>
                                        <Droppable droppableId={col.id} key={col.id} isDropDisabled={option.maxCard <= col.items.length}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <Card 
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        sx={{ 
                                                            background: snapshot.isDraggingOver ? (col.dropColor || "#EDE7F6") : (col.bucketColor || "transparent"),
                                                            p: 3, width: 345, mr: 1, display: "block", flexDirection: "column", height: "70vh", overflow: 'auto', boxShadow: 1, borderRadius:0
                                                        }}
                                                    >
                                                        {col.items.map((item, index) => {
                                                            return (
                                                                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={option.readonly || schema.settings.isDragDisabled}>
                                                                    {(provided) => {
                                                                    return (
                                                                        <Card variant="outlined"
                                                                            sx={{ py: 4, px: 6, mb: 2, cursor: 'pointer', whiteSpace: 'normal', borderRadius: 0,
                                                                                backgroundColor: (item.bodyColor || "#FFF"),
                                                                                ...provided.draggableProps.style,
                                                                            }}
                                                                            onClick={(e) => { e.stopPropagation();}}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <CardDetail item={item} onClick={onCardClick}/>
                                                                            {!option.readonly && schema.settings.card?.actions && <>
                                                                                <Divider sx={{ mt: 2, mb:2 }}/>
                                                                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0}>
                                                                                    {schema.settings.card?.actions.map ((a, index)=> {
                                                                                    if (a.icon) return (
                                                                                        <IconButton key={"_"+index} size={a.icon.size || "xs"} sx={{color: a.icon.color}}
                                                                                            onClick={() => a.event && schema [a.event]  && schema [a.event] (item, col) }>
                                                                                            <Tooltip title={a.help || "Click Me"} placement="top"><FontAwesomeIcon icon={a.icon.code} /></Tooltip>
                                                                                        </IconButton>)
                                                                                    }
                                                                                    )}
                                                                                </Stack>
                                                                            </>
                                                                            }
                                                                        </Card>
                                                                    );
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </Card>
                                                );
                                            }}
                                        </Droppable>
                                    </Box>
                                </Box>
                                );
                            })}
                            {!option.readonly && option.maxBucket > columns.length && schema.settings.bucket?.action && 
                            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Card variant="outlined" sx={{ ml:0.5, width: 345, background: (schema.settings.bucket.action.background || "#FAFAFA"), "boxShadow":0}}>
                                    <CardHeader
                                        action={
                                            <Stack direction="row" spacing={0}>
                                                <Tooltip title={schema.settings.bucket.action.help || "Add"} placement="top">
                                                    <Avatar sx={{ cursor: 'pointer', backgroundColor: 'grey.100', border: `1px dashed ${grey[400]}`, }} 
                                                        onClick={() => schema.settings.bucket.action.event && schema [schema.settings.bucket.action.event]  && schema [schema.settings.bucket.action.event] () }>
                                                        <AddIcon sx={{ color: 'grey.500', fontWeight: Fonts.LIGHT, }} />
                                                    </Avatar>
                                                </Tooltip>
                                            </Stack>
                                        }
                                        title={<Typography sx={{fontWeight: 'bold'}} variant="h4" component="span">{schema.settings.bucket.action.label || "Add Bucket"}</Typography>}
                                    />
                                </Card>
                            </Box>}
                        </DragDropContext>
                    </Box>
                    
                </AppsContent>
            </>
            }
        </>
    );
}

Scrumboard.propTypes = {
  schema: PropTypes.object.isRequired,
};