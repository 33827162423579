import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabsWrapper from './TabsWrapper';
import AppAnimate from '@crema/core/AppAnimate';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { Registry } from 'react-registry';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabComponent = ({schema}) => {
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);

  const [value, setValue] = useState(0);
  const onTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        component='h2'
        variant='h2'
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4,
          },
        }}
      >
        {schema.settings.label}
        <Typography variant='h6' gutterBottom component='div'>
            {schema.settings.help}
        </Typography>
      </Box>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <TabsWrapper>
          <Tabs
            className='account-tabs'
            value={value}
            onChange={onTabsChange}
            aria-label='basic tabs example'
            orientation='vertical'
          >
            {(schema.settings.tabs || []).map((tab, index) => (
              <Tab
                className='account-tab'
                label={tab.label}
                icon={<Tooltip title={tab.tooltip} placement="top"><FontAwesomeIcon icon={tab.icon?.code} color={tab.icon?.color} size={tab.icon?.size || "xs"}/></Tooltip>}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          <Box className='account-tabs-content'>
                {schema.fieldsets?.map((fieldset, index) => {
                    return (<div key={schema.__id + "-" + index}> 
                    {fieldset.fields.map ((field, idx) => {
                        if (schema.properties [field] && idx==value)
                        return (
                            <div key={schema.properties [field].__id}> 
                            {Registry.createElement(schema.properties [field].widget, {schema: schema.properties [field], model: schema.properties [field].value})}
                            </div>
                        )
                    })}
                    </div>)
            })}
          </Box>
        </TabsWrapper>
      </AppAnimate>
    </>
  );
};

export default TabComponent;

TabComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};
