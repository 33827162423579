import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const SearchBar = ({schema}) => {
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <TextField
                sx={{
                    width: '100%',
                    position: 'relative',
                    transition: 'all 0.5s ease'
                }}
                required={schema.behaviour?.mandatory}
                disabled={schema.behaviour?.readonly}
                id={schema.__id + "1"}
                label={schema.settings.label}
                helperText={schema.settings.help + (schema.error?" (" +schema.error+ ")":"") }
                value={schema.getPropertyValue()}
                placeholder={schema.settings.placeholder}
                variant={schema.settings.variant || 'outlined'}
                error={schema.error?true:false}
                onChange={(e) => {
                    schema.setPropertyValue (e.target.value);
                    if (schema.onChange) schema.onChange();
                }}
                onBlur={(e) => {
                    schema.setPropertyValue (e.target.value);
                    if (schema.onBlur) schema.onBlur();
                }}
                onKeyDown={(ev) => {
                    if (schema.onSearch && ev.key === 'Enter') schema.onSearch ();
                }}
                InputProps={{
                    maxLength: schema.settings.maxLength, minLength: schema.settings.minLength,
                    endAdornment: <InputAdornment position="end">
                         <IconButton
                            size='small'
                            onClick={() => {
                                if (schema.onSearch) schema.onSearch ();
                            }}
                            edge='end'
                        >
                            <Tooltip title="Search" placement="top"><FontAwesomeIcon icon="fas fa-search"/></Tooltip>
                        </IconButton>
                    </InputAdornment>,
                }}
            />}
        </>
    )
}

export default SearchBar;

SearchBar.propTypes = {
    schema: PropTypes.object.isRequired,
};