import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import * as _ from "lodash";
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const filter = createFilterOptions();
const AddToListComponent = ({schema}) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const dispatch = useDispatch();
    useEffect (()=> {
        if (schema.getPropertyValue()) setValue (schema.getPropertyValue());
        else schema.setPropertyValue (value);
        setTimeout (()=> { schema.onInit && schema.onInit (); }, 100);
    }, []);
    
    useEffect (()=>{
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty()){
            setOptions (_.uniqBy(schema.datasource, 'id'));
            setValue (_.cloneDeep (schema.getPropertyValue()));
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }, [schema.datasource]);

    const handleLookup = (event) => {
        if (event.target.value.length>=3 && schema.href ){
            dispatch (callService (schema.href, {
                model: { name: schema.__name, path: schema.__key, value: event.target.value }
            }, (success) => {
                if (_.isArray(success.data)){
                    setOptions (success.data);
                }
            }, (failure)=> {
                console.log (failure)
            }));
        } else {
            event.target.value.length>=3 && schema.onSearch && schema.onSearch ({
                name: schema.__name,
                path: schema.__key,
                value: event.target.value?.replace(/[^\w ]/, '')
            });
        }
    }

    const handleDelete = (id) => {
        _.remove (value, (v)=> v.id === id)
        let val = _.clone (value);
        setValue (val);
        schema.setPropertyValue (val);
        if (schema.onChange) schema.onChange ({
            name: schema.__name,
            nodes: val
        });
        schema.getParent ().__isValid__ ();
    }

    const handleAdd = (node) => {
        if (node && _.findIndex (value, (v) => v.id == node.id) == -1){    
            let val = schema.getPropertyValue();
            val.push (node);
            schema.setPropertyValue (val);
            setValue (_.clone (val));
            if (schema.onChange) schema.onChange ({
                name: schema.__name,
                nodes: val
            });
            schema.getParent ().__isValid__ ();
        }
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                {(!schema.settings?.max || value.length<schema.settings.max) && <Autocomplete
                    id={schema.__id}
                    options={options}
                    filterOptions={(options, params)=>{
                        return schema.onSearch?options:filter(options, params);
                    }}
                    getOptionLabel={(option) => {
                        if (option.name && option.value){
                            let val = (option.value + " - " + option.name);
                            return val.length>140?val.slice(0,140) + " ...":val;
                        }
                        return option?.value || option?.name || option?.description || option?.id || option
                    }}
                    disabled={schema.behaviour?.readonly}
                    onChange={(e, newValue) => { handleAdd (newValue); }}
                    renderInput={(params) => (
                        <TextField sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                            {...params} 
                            label={schema.settings.label}
                            variant={schema.settings.variant || 'outlined'}
                            placeholder={schema.settings.placeholder}
                            helperText={(schema.settings.help || "") + (schema.error || "")}
                            required={schema.behaviour?.mandatory}
                            error={schema.error?true:false}
                            onChange={handleLookup}
                        />
                    )}
                />}
                {schema.settings.help && value.length>0 && <Typography variant='h6' gutterBottom color='text.secondary'>
                {schema.settings.listHelp}
                </Typography>}
                <List sx={{ maxHeight: schema.settings?.maxHeight || "50vh", mt:2, flexGrow: 1, overflowY: 'auto', width: '100%', bgcolor: 'transparent' }}>
                    {value.map((v, index) => {
                    return (
                        <div key={v.id+index}>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="comments" onClick = {()=>handleDelete(v.id)} sx={{color:'#C63C3C'}}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText id={v.id} primary={v.name} />
                            </ListItem>
                            <Divider component="li"/>
                        </div>
                    );
                })}
                </List>
            </>
            }
                
        </>
    )
}

export default AddToListComponent;

AddToListComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};