import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import './styles.css';

const ReactImageGallery = ({schema}) => {
    const [images, setImages] = useState([]);

    useEffect (()=>{
        update (schema.getPropertyValue() || []);
        setTimeout (()=> {  schema.onInit && schema.onInit ((style)=>{
            update (schema.getPropertyValue() || [], style);
        });  }, 100);
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(images).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            update (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const myRenderItem = (i) => {
        return <div>
            <img className="image-gallery-image" style={i.style.image} src={i.url} title={i.name} alt={i.alt}/>
            <a className="image-gallery-description" style={i.style.title} href={i.linkTo||null}>{i.name}</a>
        </div>;
    }

    const update = (imglist, style) => {
        imglist.map ((i) => {
            i.original = i.thumbnail = i.url;
            i.description = i.name;
            i.originalAlt = i.thumbnailAlt = i.alt;
            i.originalTitle = i.name;
            i.style = style || {};
            i.renderItem = myRenderItem;
        });
        setImages (imglist);
    }
    return (
        <>
            {schema && !schema.behaviour?.hidden && <ImageGallery 
                items={images} 
                showIndex = {images.length>1 && (schema.settings.showIndex || true)}
                showBullets = {images.length>1 && (schema.settings.showBullets || true)}
                showPlayButton = {images.length>1 && (schema.settings.showPlayButton || true)}
                infinite = {schema.settings.infinite || true}
                autoPlay = {schema.settings.autoPlay || true}
                slideDuration = {schema.settings.slideDuration || 1000 }
                slideInterval = {schema.settings.slideInterval || 5000 }
                loading = {schema.settings.loading || "lazy" }
                showThumbnails = {images.length>1}
                showFullscreenButton = {schema.settings.showFullscreenButton || false }
            />}
        </>
    );
};

export default ReactImageGallery;
ReactImageGallery.propTypes = {
    schema: PropTypes.object.isRequired,
};