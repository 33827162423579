import {
  useJWTAuth,
  useJWTAuthActions,
} from "../services/auth/jwt-auth/JWTAuthProvider";

export const useAuthUser = () => {
  const { user, menu, ui, isAuthenticated, isLoading, settings } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user,
    menu,
    ui,
    settings
  };
};

export const useAuthMethod = () => {
  const { updateSettings, updateMenu, updateUser } = useJWTAuthActions();
  return {
    updateSettings,
    updateMenu,
    updateUser,
    // updateToken
  };
};
