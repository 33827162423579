import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const PageNavigation = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    const handleChange = (event, value) => {
        schema.setPropertyValue ({
            current: value,
            total: schema.getPropertyValue ()?.total
        })
        schema.onChange && schema.onChange ();
    };
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Stack spacing={2} alignItems="center">
                    {(schema.settings.label) && <Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom >
                        {schema.settings.label}
                    </Typography>}
                    <Pagination 
                        count={schema.getPropertyValue ()?.total || 0} 
                        color="primary"
                        showFirstButton 
                        showLastButton 
                        page={schema.getPropertyValue ()?.current || 0} onChange={handleChange}
                    />
                </Stack>
            }
        </>
    )
}

export default PageNavigation;

PageNavigation.propTypes = {
    schema: PropTypes.object.isRequired,
};