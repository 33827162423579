import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Settings from './settings';
import Behaviour from './settings/Behaviour';
import Href from './datasource/Href';
import Enumeration from './datasource/Enumeration';
import _ from "lodash";
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function WidgetType({schema}) {
  useEffect (()=>{
    if (!schema.validator)schema.validator = () => {
      schema.getParent ().__setError__ (schema.__name, null);
      let value = schema.getPropertyValue();
      // Validating dropdown properties
      if (value.widget == "dropdown"){
        // Rule 1: Check the dropdown datasource
        let result = _.uniq(_.map((value.datasource || []), 'name'));
        if (result.length != (value.datasource || []).length){
          schema.error = "Duplicate in name in Datasource is not allowed";
          return false;
        }
      }
      if (value.widget == "custom"){
        if ((value.__errors || []).length > 0){
          schema.getParent ().__setError__ (schema.__name, "Error in the custom widget settings, please fix");
          return false;
        }
      }
      return true;
    }, [schema]});
  const handleChange = (event) => {
    schema.setPropertyValue({
      widget: event.target.value,
      settings: {},
      behaviour: { mandatory: false, readonly: false, hidden: false }
    })
  };
  const onChangeSettings = (settings) => {
    onChange ({ settings: settings });
  }

  const onChange = (data) => {
    schema.setPropertyValue(Object.assign (schema.getPropertyValue(), data));
  }
  

  return (
    <>
      <FormControl required 
        sx={{
            width: '100%',
            mt:3,
            position: 'relative',
            transition: 'all 0.5s ease'
        }}>
        <InputLabel>
            Select Widget Type
        </InputLabel>
        <Select
          labelId='type-select-helper-label'
          id="type-select-helper"
          value={schema.getPropertyValue()?.widget}
          label="Select Widget Type"
          inputProps={{
            name: 'widget',
            id: 'widgetSelection',
          }}
          onChange={handleChange}
        >
          <MenuItem value={"string"}>String</MenuItem>
          <MenuItem value={"dropdown"}>Dropdown</MenuItem>
          <MenuItem value={"number"}>Number</MenuItem>
          <MenuItem value={"switch"}>Switch (On/Off or Yes/No)</MenuItem>
          <MenuItem value={"custom"}>Custom (JSON Driven)</MenuItem>
        </Select>
        <FormHelperText>Widget Type is required to define the attribute</FormHelperText>
      </FormControl>
      <Behaviour behaviour={schema.getPropertyValue ().behaviour} onChange={onChange}/>
      <Settings schema={schema.getPropertyValue ()} onChange={onChangeSettings}/>
      {_.includes(["dropdown"], schema.getPropertyValue()?.widget) && 
      <>
        <Href value={schema.getPropertyValue ().href} onChange={onChange}/>
        <Enumeration value={schema.getPropertyValue ().datasource} onChange={onChange}/>
      </>
      }
      {schema.error && [schema.error].map ((error, index) => {
          return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
        })}
      
    </>
  );
}

WidgetType.propTypes = {
  schema: PropTypes.object.isRequired,
};