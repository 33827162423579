import React, { useEffect, useState } from 'react';
import Editor from "@monaco-editor/react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const ActivityServiceBuilder = ({schema}) => {
    const [value, setValue] = useState({});
    useEffect (() => {
        let defaultValue = {script: "import traceback\n\ndef __evaluate__ (this):\n\tpass"}
        if (!schema.getPropertyValue()) schema.setPropertyValue(defaultValue);
        setValue (schema.getPropertyValue() || defaultValue);
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
        return () => {
            provider?.dispose ();
        }
    }, []);
    const onChange = (newValue) => {
        value.script = newValue;
        setValue (value);
        schema.setPropertyValue (value);
    }
    const handleEditorValidation = (markers) => {
        value.errors = [];
        markers.forEach(marker => {
            value.errors.push (marker.message);
        });
        setValue (value);
        console.log (value.errors)
        schema.setPropertyValue (value);
    }

    const generateSuggestion = (monaco) => {
        let suggestions=[];
        (schema.datasource || []).forEach ((data)=>{
            suggestions.push ({
                label: data.name + " (" + data.description+ ")",
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: "\"" + data.id + "\" #" + data.name
            });
        })
        return { suggestions: suggestions };
    }
    let provider;
    const handleEditorDidMount = (editor, monaco) => {
        provider?.dispose ();
        provider = monaco.languages.registerCompletionItemProvider('python', {
            provideCompletionItems: () => generateSuggestion(monaco)
        });
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && <>
                <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                    {schema.settings.label}
                </Typography>
                
                <Typography sx={{fontSize: 12}} color={(schema.error || (value?.errors||[]).length>0)?"red":"text.secondary"} gutterBottom >
                    {schema.settings.help + ((schema.error || (value?.errors||[]).length>0)? ".. Compilation Error occured, please fix":"") }
                </Typography>
                {schema && 
                <Box m={1} >
                    <Editor
                        theme={schema.settings.theme || "vs-dark"}
                        language="python"
                        value={value.script}
                        height="80vh"
                        options = {{ minimap: { enabled: false }, readOnly: schema.behaviour?.readonly }}
                        onValidate={handleEditorValidation}
                        onChange={onChange}
                        onMount={handleEditorDidMount}
                    />
                    {schema.error && <Typography variant='h6' gutterBottom  component='div' color="red"><pre>{schema.error}</pre></Typography>}
                    {(value.errors || []).map ((error, index) => {
                        return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
                    })}
                </Box>}
            </>}
        </>
    )
}

export default ActivityServiceBuilder;
ActivityServiceBuilder.propTypes = {
    schema: PropTypes.object.isRequired
  };
