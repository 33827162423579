import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AppCard from '@crema/core/AppCard';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as _ from "lodash";

const CardType1 = ({schema}) => {
  const [data, setData] = useState({});
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
  }, []);
  useEffect (() => {
    if (schema.settings.__hasDataSourceUpdated && (schema.datasource || []).length >0){
      setData (schema.datasource[0]);
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  },[schema.datasource]);
  
  return (
    <>
      <Box sx={{width: '100%'}}>
        {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
          {schema.settings.label}
        </Typography>}
        {schema.settings.help && <Typography variant='h6' gutterBottom component='div'>
          {schema.settings.help}
        </Typography>}
      </Box>
      <AppCard>
        <Box  sx={{ display: 'flex', flexDirection: 'column', width: 1, }}>
          {schema.settings.layout?.map ((r, i) => {
            return (
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', borderBottom: (theme) => (schema.settings.layout.length-1==i)?null:`solid 1px ${theme.palette.grey[200]}`}} 
              key={i}>
              {r.map ((c, j) => { 
                let value = _.get (data, c.key);
                return (
                <Box  sx={{ py: 5, px: 2, width: 1 / 2, borderRight: (theme) => { return (r.length-1 == j)?null:`solid 1px ${theme.palette.grey[200]}`}, }} key={j}>
                  <Box  component='h6' sx={{ color:(theme) => `${theme.palette.grey[800]}`}}>{c.label}</Box>
                  <Box component='h1'>{_.isObject (value) ? (value.name || value.value) : (value || "-")}</Box>
                  <Box  component='h6' sx={{ color:(theme) => `${theme.palette.grey[500]}`}}>{c.help}</Box>
                </Box>)
              })}
            </Box>)
          })}
        </Box>
      </AppCard>
    </>
  )
}

export default CardType1;

CardType1.propTypes = {
    schema: PropTypes.object.isRequired
};