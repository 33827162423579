import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { HexColorPicker } from "react-colorful";
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';

const ColorPicker = ({schema}) => {
    const ref = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                <Box sx={{display:"flex" }}>
                    <TextField
                        ref={ref}
                        sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                        label={schema.settings.label}
                        value={schema.getPropertyValue()}
                        disabled={schema.behaviour?.readonly}
                        onChange={(e) => {
                            let color = e.target.value;
                            if (color && !color.startsWith("#")) color = "#" + color;
                            schema.setPropertyValue (color);
                            schema.getParent().__isValid__ ();
                            if (schema.onChange) schema.onChange();
                        }}
                        onBlur={(e) => {
                            let color = e.target.value;
                            if (color && !color.startsWith("#")) color = "#" + color;
                            schema.setPropertyValue (color);
                            schema.getParent().__isValid__ ();
                            if (schema.onChange) schema.onChange();
                        }}
                        helperText={schema.settings.help + (schema.error?" (" +schema.error+ ")":"") }
                        variant={schema.settings.variant || 'outlined'}
                        InputProps={!schema.behaviour?.readonly?{
                            endAdornment: <InputAdornment position="end">
                                 <IconButton
                                    size='small'
                                    onClick={handleClick}
                                    edge='end'
                                    sx={{color: schema.getPropertyValue() }}
                                >
                                    <Tooltip title="Search" placement="top"><FontAwesomeIcon icon="fas fa-droplet"/></Tooltip>
                                </IconButton>
                            </InputAdornment>,
                        }:{}}
                    />
                    {schema.settings.helplink && 
                        <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                            <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                        </IconButton>
                    }
                </Box>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                >
                    <Box sx={{ overflow: "hidden", background: "transparent" }}>
                        <HexColorPicker
                            color={schema.getPropertyValue()}
                            onChange={(color) => {
                                schema.setPropertyValue (color);
                                schema.getParent().__isValid__ ();
                                if (schema.onChange) schema.onChange();
                            }}
                        />
                    </Box>
                </Popover>
            </>}
        </>
    )
}

export default ColorPicker;

ColorPicker.propTypes = {
    schema: PropTypes.object.isRequired,
};