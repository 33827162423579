import React, { useState, useEffect } from 'react';
import AppCard from '@crema/core/AppCard';
import Slider from 'react-slick';
import ContentItem from './ContentItem';
import PropTypes from 'prop-types';
import ContentSlider from './ContentSlider';
import * as _ from "lodash";

const CardSlider = ({schema}) => {
  const [contents, setContents] = useState([]);
  const [settings, setSettings] = useState({ dots: false,  infinite: true, speed: 500, slidesToShow: 2, slidesToScroll: 1, autoplay: true,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 2, slidesToScroll: 1, }, },
      { breakpoint: 960, settings: { slidesToShow: 2, slidesToScroll: 1, }, },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, }, },
      { breakpoint: 400, settings: { slidesToShow: 1, slidesToScroll: 1, }, },
    ],
  });

  useEffect (()=>{
    setContents (schema.getPropertyValue() || []);
    setTimeout (()=> {  schema.onInit && schema.onInit (()=>{});  }, 100);
  }, []);

  useEffect(() => {
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(contents).xorWith(schema.datasource, _.isEqual).isEmpty()){
      schema.datasource = _.uniqBy (schema.datasource, "id");
      setContents (schema.datasource);
      if (schema.datasource.length <= 1){
        setSettings (_.assign (settings, {infinite:false}))
      }
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  },[schema.datasource]);

  useEffect (() => {
    setSettings (_.assign (settings, schema.settings));
  },[schema.settings]);

  return (
    <>
    {settings && <AppCard sx={{height: 1, m:1}} title={settings.title}>
      <ContentSlider>
        <Slider className='slideRoot' {...settings}>
          {contents.map((data, index) => (
            <ContentItem key={index} data={data} />
          ))}
        </Slider>
      </ContentSlider>
    </AppCard>}
    </>
  );
};

export default CardSlider;

CardSlider.propTypes = {
  schema: PropTypes.object.isRequired
};
