import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const Members = (props) => {
  const {members} = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
      {members.map((member) => {
        return (
          <Tooltip title={member.name} key={member.id}>
            {member.avatar ? 
              (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white" }} src={member.avatar} alt={member.name} />) : 
              (<Avatar sx={{ width: 24, height: 24, mr: 2, border: 1.5, borderColor: "white"}}>{member.name[0].toUpperCase()}</Avatar>)}
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default Members;

Members.propTypes = {
  members: PropTypes.array.isRequired,
};
