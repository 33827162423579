import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import AppGridContainer from '@crema/core/AppGridContainer';
import {Grid, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import * as _ from "lodash";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const EventConfig = ({ open, setOpen, config, setConfig, settings, updateEvent, schema }) => {
    const [start, setStart] = useState ("");
    useEffect (()=>{
        setStart (dayjs (config.start));
        let _start = dayjs (config.start).toDate ();
        let _end = dayjs (config.end).toDate ();
        if (settings.duration){
            _end = dayjs (config.start).add (settings.duration, "hour").toDate ();
            setConfig (config => ({...config, start: _start, end: _end, duration: settings.duration }));
        } else {
            if (!config.duration) {
                let hours = Math.ceil (dayjs (config.end).diff (dayjs (config.start), "hours"));
                _end = dayjs (config.start).add (hours, "hour").toDate ();
                setConfig (config => ({...config, start: _start, end: _end, duration: hours }));
            }
        }
    }, [open]);

    const handleCancel = () => {
        setOpen (false);
        schema.getParent().__confirmation__ ({
            title: "Warning",  
            message: "Are you sure, you want to cancel this event, this action cannot be undone?",
            events: [{
                label: "Cancel",
                onClick: () => {
                    setOpen (true);
                }
            },  {
                label: "Yes, Please",
                onClick: () => {
                    updateEvent (config.id);
                }
            }]
        });
    };
    const handleConfirm = () => {
        if (!config.title || !config.resources || (_.isArray (config.resources) && config.resources.length == 0)){
            schema.getParent ().__snackbar__ ({open: true, message: "Invalid Event Configuration", severity: "error"})
            return;
        }
        if (!config.duration){
            schema.getParent ().__snackbar__ ({open: true, message: "Please provide an approximate duration", severity: "error"})
            return;
        }
        if (!start){
            schema.getParent ().__snackbar__ ({open: true, message: "Please provide a start-time", severity: "error"})
            return;
        }
        
        updateEvent ();
        setOpen (false);
        
    }
    
    return (
        <Dialog
            open={open} 
            TransitionComponent={Transition}
            aria-describedby='alert-dialog-slide-description'
            onClose={()=>setOpen (false)}
        >
            <DialogTitle>{"Event Configuration"}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>setOpen (false)}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
                </IconButton>
            <DialogContent>
                <AppGridContainer sx={{ pt : 5}}>
                    <Grid item xs={12} lg={6} key={"name"}>
                        <TextField
                            sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                            required
                            label="Event Name"
                            type="string"
                            disabled={ !config.updatable || false}
                            helperText="Give a Name of the slot which will appear on the calender"
                            value={config.title || ""}
                            variant={'outlined'}
                            onChange={(e)=>{ setConfig (config => ({...config, title: e.target.value}))}}
                            InputProps={{ inputProps: { maxLength: 20 } }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} key={"duration"}>
                        <TextField
                            sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                            type="number"
                            required
                            disabled={settings.duration || !config.updatable || false }
                            label="Event Duration"
                            helperText="Give an estimated duration of the event in Hrs"
                            value={config.duration || ""}
                            variant={'outlined'}
                            onChange={(e)=>{ setConfig (config => ({...config, duration: e.target.value}))}}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} key={"starttime"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker label="Event StartTime"
                                value={start}
                                disabled={ !config.updatable || false}
                                onChange={(newValue) => { 
                                    setStart (newValue);
                                    let _start = newValue.toDate ();
                                    let _end = dayjs (newValue).add (config.duration, "hour").toDate ();
                                    setConfig (config => ({...config, start: _start, end: _end }));
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params}
                                        sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                                        variant={'outlined'}
                                        label={"Event Start Time"}
                                        helperText={"Update the start time from there end time will be calculated based on duration"}
                                        required
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={12} key={"start"}>
                        <Autocomplete
                            sx={{width: 1}}
                            options={settings.resources || []}
                            getOptionLabel={(option) => option?.name || "" }
                            isOptionEqualToValue={(option, value) => { return option.id == value.id }}
                            value={config.resources || (settings.allowMultipleResource?[]:"")}
                            onChange={(e, newValue) => { setConfig (config => ({...config, resources: newValue })) }}
                            // getOptionDisabled={(option) => option.id == "3"}
                            multiple={settings.allowMultipleResource}
                            disabled={ !config.updatable || false}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                                    {...params}
                                    variant='outlined'
                                    label="Resources"
                                    helperText="Assign Resources for the Activity Event"
                                    required
                                />
                            )}
                        />
                    </Grid>
                </AppGridContainer>
            </DialogContent>
            <DialogActions>
                {config.updatable ? <>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>Ok</Button></>:
                <Button onClick={()=>setOpen(false)} autoFocus>Close</Button>}
            </DialogActions>
        </Dialog>
    );
};

export default EventConfig;

EventConfig.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    setConfig: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    updateEvent: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired
};
