import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import {ListItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const NotificationItem = ({item}) => {
  return (
    <>
      <ListItem alignItems='flex-start'>
        {item.data.pname && <ListItemAvatar>
            <Avatar alt={item.data.pname} src={item.data.pavatar} />
        </ListItemAvatar>}
        <ListItemText
            sx={{ '.newline': {
                    "whiteSpace": "pre-wrap",
                    "wordWrap": "break-word"
                },
            }}
            primary={item.data.linkTo ? <a href={item.data.linkTo }>{item.data.title}</a> : item.data.title}
            secondary={
              <>
                <span className='newline'>{(item.data.body.length>140?item.data.body.slice(0,140) + " ...":item.data.body) + "\n"}</span>
                <span className='newline'>{new Date(item.data.date.seconds * 1000).toUTCString().replace ("GMT", "")}</span>
              </>
            }
        />
      </ListItem>
      <Divider variant={item.data.pname?'inset':null} />
    </>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
