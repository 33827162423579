import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';
import { FormLabel } from '@mui/material';
import Selection from './Selection';
import * as _ from "lodash";
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';

export default function Subform({schema}) {
    const [activeStep, setActiveStep] = useState(0);
    const [auto, setAuto] = useState(false);
    const [manual, setManual] = useState(false);
    const dispatch = useDispatch();

    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
        schema.validator = () => {
            let toBePreLoaded = validateCardinality (schema.settings.items, schema.properties);
            if (toBePreLoaded.length>0) {
                schema.getParent ().__setError__ (schema.__name, " (Minimum Cardinality check failed) Please add following item(s) or click \"Auto Organize\" button\n" + _.map (toBePreLoaded, (o)=>" " + o.name + " - " + o.missing + " (min: "+o.cardinality.min+", max: "+o.cardinality.max+")").join ("\n"));
            }
        }
        schema.__setCardinality = (itemName, cardinality) => {
            if (_.isArray (schema.settings.items) && schema.settings.items.length>0){
                let hasUpdated = false;
                schema.settings.items.forEach ((i) => {
                    if (i.identifier === itemName){
                        i.cardinality = cardinality;
                        hasUpdated=true;
                    }
                });
                if (hasUpdated){
                    validateCardinality (schema.settings.items, schema.properties);
                }
            }
        }
        organizeSubforms ();

        if (schema.settings?.activeStep && schema.settings?.activeStep >= schema.properties.length) setActiveStep (0);
        else setActiveStep (schema.settings?.activeStep || 0); 
    }, []);

    useEffect (()=>{
        if (activeStep>=schema.properties.length && schema.properties.length>0) setActiveStep (schema.properties.length-1);
        validateCardinality (schema.settings.items, schema.properties);
        schema.getParent().__setSettings__ (schema.__name, schema.settings);
    }, [schema.properties.length]);

    const organizeSubforms = () => {
        // Preparing the forms based on the cardinality
        if (_.isArray (schema.settings.items) && schema.settings.items.length>0){
            schema.getParent().__backdrop__ ({ isOpen: true, message: (schema.settings.loadingText || "Organizing the subform ... please wait")});
            if (schema.settings.items[0].id) organize (_.cloneDeep (schema.settings.items));
            else {
                dispatch (callService (schema.settings.href.getName, {
                    model: _.map(schema.settings.items, "name")
                }, (success) => {
                    if (_.isArray(success.data)){
                        let its = _.values (_.merge(_.keyBy(schema.settings.items, 'name'), _.keyBy(success.data, 'identifier')));
                        organize (its);
                    }
                }, (failure)=> {
                    console.log (failure)
                }));
            }
        }
    }

    const organize = (its) => {
        let missing = validateCardinality (its, schema.properties);
        let toBePreLoaded = _.map (missing, (o)=> o.id);
        _.remove (schema.settings.items);
        its.forEach ((i) => schema.settings.items.push (i));
        if (toBePreLoaded.length>0) handleSelection (toBePreLoaded, its);
        else schema.doRefresh ();
        schema.getParent().__backdrop__ ({ isOpen: false});
    }

  const validateCardinality = (its, props) => {
    let missing = [];
    its.map ((i) => {
        let pc = _.filter (props || [], (c) => c.identifier == i.identifier).length;
        let missingCount = i.cardinality.min - pc;
        // Missing count is based on cardanality
        if ((i.cardinality.min || 0) > pc) missing.push ({
            id: i.id,
            name: i.name,
            missing: missingCount,
            cardinality: i.cardinality 
        });
        // Check Maximum
        i.disabled = (i.cardinality.max <= pc || i.cardinality?.max === 0);
    });
    setAuto (missing.length>0);
    setManual (_.filter (its, (i)=>!i.disabled).length>0);
    return missing;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
        schema.getParent().__setSettings__ (schema.__name, {activeStep: prevActiveStep + 1});
        return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
        schema.getParent().__setSettings__ (schema.__name, {activeStep: prevActiveStep - 1});
        return prevActiveStep - 1
    });
  };

  const handleSelection = (id, its=(schema.settings.items||[])) => {
    schema.getParent().__backdrop__ ({ isOpen: true, message: schema.settings.loadingText || "Organizing the subform ... please wait"});
    dispatch (callService (schema.settings.href.getSchema, {
        model: _.isArray(id)?id:[id]
    }, (response) => {
        let sch = _.cloneDeep (schema);
        (_.isArray(id)?id:[id]).map ((id) => {
            // This is a patch to keep the order as database in query was changing the order
            let s = _.find (response.data, ["id", id]);
            if (!s) return;
            if (!sch.properties) sch.properties = [];
            let i = _.find (its, { identifier: s.identifier});
            let p = _.filter (sch.properties, (c) => c.identifier == s.identifier).length;
            // Check min number reached
            if (i.cardinality?.min > 0 && p < i.cardinality?.min)
                _.times (i.cardinality?.min - p, () => sch.properties.push (s));
            else sch.properties.push (s);

            p = _.filter (sch.properties, (c) => c.identifier == s.identifier).length;
            // No check missing based on configured value
            if (_.isArray (schema.getPropertyValue()) && schema.getPropertyValue().length > 0){
                let noOfValues = _.filter (schema.getPropertyValue(), (c) => _.has (c, s.identifier)).length;
                if (noOfValues > p) _.times (noOfValues - p, () => sch.properties.push (s));
            }
        });
        schema.__addItem__ (sch.properties);
        setTimeout (()=>{schema.onChange && schema.onChange ()}, 100);
        schema.getParent().__backdrop__ ({ isOpen: false});
    }))
  }

  const handleDelete = (index) => {
    schema.getParent().__confirmation__ ({
        title: "Warning",  
        message: "Are you sure, you want to delete this item, this action cannot be undone?",
        events: [{
            label: "Cancel"
        },  {
            label: "Yes, Please",
            onClick: () => {
                schema.getParent().__backdrop__ ({ isOpen: true, message: schema.settings.loadingText || "Organizing the subform ... please wait"});
                schema.__removeItem__ (index);
                schema.onChange && schema.onChange ();
                schema.getParent().__backdrop__ ({ isOpen: false});
            }
        }]
    });
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
        {schema && !schema.behaviour?.hidden && <>
        {schema.settings.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings.label}</FormLabel>}
        <Box sx={ schema.settings.style || {width: '100%', border: 1, borderRadius: 2, borderColor: 'grey.400', mt:5, p:3, pl:5, pr:5}}>
            {(schema.settings.help || schema.error) &&
                <pre><Typography sx={{fontSize: 12}} gutterBottom color={(schema.error)?"#ef5350":"text.secondary"}>
                    {(schema.settings.help || "") || (schema.error || "")}
                </Typography></pre>
            }
            {auto && 
            <Button startIcon={<SyncIcon />} sx={{float: 'left'}} 
                aria-controls='subform-menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={organizeSubforms}
            >Auto Organize</Button>}
            {manual && <Selection items={schema.settings.items} handleSelection={handleSelection}/>}
            <Stepper activeStep={activeStep} orientation='vertical' sx={{width:"100%"}}>
                {schema.properties?.map((prop, index) => {
                    prop.settings.showLabel = false;
                    return (
                    <Step key={prop.__id}>
                        <StepLabel sx={{width:"100%", '&:hover': { cursor: 'pointer'}}} onClick={handleStep(index)}>
                            <Typography sx={{fontSize: 14, fontWeight: 600}} color={(prop.error)?"#ef5350":"text.secondary"}>
                                {(prop.settings.label || prop.settings.step.label)  + (prop.error || "")}
                            </Typography>
                        </StepLabel>
                        <StepContent TransitionProps={{ unmountOnExit: schema.settings.unmountOnExit }} sx={{pt:5}}>
                            {!schema.settings.hideDelete && <Button endIcon={<DeleteIcon />} color='secondary' sx={{float: 'right', color:'#F04F47'}} onClick = {()=>handleDelete(index)}>Delete</Button>}
                            {Registry.createElement(prop.widget, {schema: prop, model: prop.value})}
                            {!schema.settings.hideNavigation && <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                {index>0 && <Button
                                    onClick={()=>schema.onBack?schema.onBack (prop.name, handleBack):handleBack()}
                                    sx={{mt: 3, mr: 1}}
                                >
                                    Previous
                                </Button>}
                                {index < schema.properties.length - 1 && 
                                <><Box sx={{flex: '1 1 auto'}} /><Button
                                    onClick={()=>schema.onNext?schema.onNext (prop.name, handleNext):handleNext()}
                                    sx={{mt: 3, mr: 1}}
                                >Next</Button></>}
                            </Box>}
                        </StepContent>
                    </Step>)
                })}
            </Stepper>
        </Box>
        </>}
    </>
  );
}

Subform.propTypes = {
  schema: PropTypes.object.isRequired,
};