import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const TabPanel = ({children, value, index}) => {
  return (
    <Box role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </Box>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
