import React, { useEffect, useState } from 'react';
import {Card} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ContentItem from './ContentItem';

const HorizontalCard = ({schema}) => {
  const [content, setContent] = useState();
  useEffect (()=>{
      setTimeout (() => { schema.onInit && schema.onInit ((data)=>{ setContent (data);  });});
  }, []);
  return (
    <Card sx={{m:1}}>
      {content && 
      <Box sx={{ display: 'flex', flexDirection: 'column', '@media (min-width: 768px)': { flexDirection: 'row', }, }} >
        {content.cover_image && 
        <Box sx={{ width: '100%', '@media (min-width: 768px)': { width: '40%', order: 2, },
            '& > img': { objectFit: 'cover', width: '100%', height: '100%', }, }} >
          <img src={content.cover_image} alt={content.title} title={content.heading}/>
        </Box>}
        <Box sx={{ width: '100%', p: 5, mt:2, alignItems: 'center', '@media (min-width: 768px)': { width: '60%', order: 1, }, }} >
          <Box sx={{ mb: 4 }}>
            <Typography variant='h3' gutterBottom component='div' color="primary">
              {content.heading}
            </Typography>
          </Box>
          {content.linkTo ? 
            <Link sx={{ mb: 5, }} href={content.linkTo} underline="hover">
              <Typography variant='h1' gutterBottom component='div' color="text.secondary">
                {content.title}
              </Typography>
            </Link> :
            <Box sx={{ mb: 5, }} component='div'>
              <Typography variant='h1' gutterBottom component='div' color="text.secondary">
                {content.title}
              </Typography>
            </Box>
          }
          {(content.content || []).map((d, index) => {
            if (d) return (<ContentItem key={index} data={d} />)
          })}
        </Box>
      </Box>}
    </Card>
  );
};

export default HorizontalCard;

HorizontalCard.propTypes = {
  schema: PropTypes.object.isRequired
};
