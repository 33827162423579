import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import * as _ from "lodash";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const filter = createFilterOptions();
const BasicDropdownComponent = ({schema}) => {
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    useEffect (()=> {
        setTimeout (()=> {  
            schema.onInit && schema.onInit ((data) => _.isArray(data) && setOptions (data));
            if (schema.href && !schema.settings?.lookup ){
                let url=schema.href; 
                let model = null;
                if (_.isObject (schema.href)){
                    url = schema.href.id || null;
                    model = schema.href.model || null
                }
                // let url = _.isObject (schema.href)?"/api/"+schema.href.id:schema.href;
                dispatch (callService (url, model, (success) => {
                    if (_.isArray(success.data)){
                        setOptions (success.data);
                        validate (success.data);
                    }
                }, (failure)=> {
                    console.log (failure)
                }));
            }
        }, 100);
        if (_.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty()){
            setOptions (schema.datasource);
            validate (schema.datasource);
        }
    }, []);
    useEffect (()=>{
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setOptions (schema.datasource);
            validate (schema.datasource);
            // This is to hold the change in datasource every time
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }, [schema.datasource]);
    
    const handleLookup = (event) => {
        if (event.target.value.length>=3 && schema.href && schema.settings?.lookup ){
            let url = _.isObject (schema.href)?"/api/"+schema.href.id:schema.href;
            dispatch (callService (url, {
                model: {
                    name: schema.__name,
                    path: schema.__key,
                    value: event.target.value
                }
            }, (success) => {
                if (_.isArray(success.data)){
                    success.data = _.uniqBy (success.data, "id");
                    setOptions (success.data);
                    validate (success.data);
                }
            }, (failure)=> {
                console.log (failure)
            }));
        } else {
            event.target.value.length>=3 && schema.onSearch && schema.onSearch ({
                name: schema.__name,
                path: schema.__key,
                value: event.target.value
            });
        }
    }
    const validate = (data) => {
        let value = schema.getPropertyValue ();
        if (_.isArray (value)){
            let finalValue = []
            value.forEach (val => {
                let item = _.find (data, (v)=> v.id == val);
                if (_.isObject (item)){
                    finalValue.push (item);
                }
            });
            if (finalValue.length > 0){
                schema.setPropertyValue (finalValue);
            } else {
                (schema.settings.deleteOnDestroy) && schema.setPropertyValue ("");
            }
        }
        else {
            if (_.isObject (value)) value = value.id;
            if (_.isInteger (value) || (_.isString (value) && value.length>0)){
                let item = _.find (data, (v)=> v.id == value);
                if (_.isObject (item)){
                    schema.setPropertyValue (item);
                } else {
                    (schema.settings.deleteOnDestroy || item?.length > 0) && schema.setPropertyValue ("");
                }
                if (schema.onChange) schema.onChange();
            }
        }
    }
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <Box sx={{display:"flex" }}>
                <Autocomplete
                    id={schema.__id}
                    sx={{width: 1}}
                    options={options}
                    getOptionLabel={(option) => {
                        return "" + (option?.value || option?.name || option?.description || option?.id || option)
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return value === undefined || value === "" || option.id == value.id
                    }}
                    value={schema.getPropertyValue()|| (schema.settings.multiple?[]:"")}
                    disabled={schema.behaviour?.readonly}
                    onChange={(e, newValue) => {
                        schema.setPropertyValue (newValue);
                        schema.getParent().__isValid__ ();
                        if (schema.onChange) schema.onChange(true);
                    }}
                    filterOptions={(options, params)=>{
                        return schema.onSearch?options:filter(options, params);
                    }}
                    renderOption={(props, option) => {
                        return ( <li {...props} key={option.id}>{"" + (option?.value || option?.name || option?.description || option?.id || option)}</li>);
                    }}
                    multiple={schema.settings.multiple}
                    renderInput={(params) => (
                        <TextField
                            sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                            {...params}
                            variant={schema.settings.variant || 'outlined'}
                            label={schema.settings.label}
                            placeholder={schema.settings.placeholder}
                            helperText={(schema.settings.help || "") + (schema.error || "")}
                            required={schema.behaviour?.mandatory}
                            error={schema.error?true:false}
                            onChange={handleLookup}
                        />
                    )}
                />
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Box>
            }
                
        </>
    )
}

export default BasicDropdownComponent;

BasicDropdownComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};