import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Editor from "@monaco-editor/react";
import RuleComponent from 'pages/dynamic/editor/EditorComponent/RuleComponent';
import uuid from 'react-uuid';
import { CONFIRMATION } from 'shared/constants/ActionTypes';

const CustomWidget = ({settings, onChange, readonly}) => {
    const [errors, setErrors] = useState([]);
    const [jsonProvider, setJsonProvider] = useState (null);
    const dispatch = useDispatch();
    const handleEditorValidation = (markers) => {
        let errors = [];
        markers.forEach(marker => {
            errors.push (marker.message);
        });
        setErrors (errors)
        settings.errors = errors;
        onChange (settings);
    }

    const onScriptChange = (newValue) => {
        settings.script = newValue;
        onChange (settings);
    }

    const onAddRule = () => {
        if (!settings?.rules) settings.rules = [];
        settings.rules.push ({
            id: uuid (),
            name: "Set Name",
            description: "Set Description",
            script: "// Write your rules here\n\n\n",
            compiled: "",
            isActive: true
        });
        onChange (settings);
    }
    const onUpdateRule = () => {
        onChange (settings);
    }

    const generateJSONSuggestion = (monaco) => {
        let suggs=[];
        (settings.rules || []).forEach ((rule)=>{
            if (!rule.error)
            suggs.push ({
                label: rule.name + " (Rule)",
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: '{"id": "'+rule.id+'", "name":"'+ rule.name +'"}'
            });
        })
        return { suggestions: suggs };
    }

    useEffect(() => {
        return () => {
            jsonProvider?.dispose ();
        }
    }, []);

    const handleEditorDidMount = (editor, monaco) => {
        jsonProvider?.dispose ();
        setJsonProvider (monaco.languages.registerCompletionItemProvider('json', {
            provideCompletionItems: () => generateJSONSuggestion(monaco)
        }));
    }

    const onReOrderRule = (rules) => {
        settings.rules = rules;
    }

    const onDeleteRule = (id) => {
        dispatch({type: CONFIRMATION, payload: {
            isOpen: true,
            title: "Warning",  
            message: "Are you sure, you want to delete this rule?",
            events: [
                { label: "Cancel" },  
                {   label: "Yes, Please", 
                    onClick: () => { 
                        settings.rules = settings.rules.filter ((rule) => rule.id !== id); 
                        onChange (settings);
                    } 
                }
            ]
        }});
    }

    return (
        <>
            {settings.widget === "composite" && <>
                <pre>
                    <Typography sx={{fontSize: 12 }} color={"text.secondary"} gutterBottom component='div'>
                        Composite Attribute is a combination of multiple single attributes; which are stictched together to create a single attribute. You can write rules to validate the attribute values and/or change the attribute behaviours.
                    </Typography>
                    <Typography sx={{fontSize: 11 }} color="primary" gutterBottom component='div'>
                        Please avoid making full schema in one attribute
                    </Typography>
                </pre>
                <RuleComponent onAddRule={onAddRule} rules={settings.rules} onDeleteRule={onDeleteRule} onUpdateRule={onUpdateRule} onReOrderRule={onReOrderRule} readonly={readonly}/>
            </>}
            <Editor
                theme="vs-dark"
                language="json"
                value={settings.script || "{\n}"}
                height="60vh"
                options = {{ minimap: { enabled: false }, readOnly: readonly }}
                onValidate={handleEditorValidation}
                onChange={onScriptChange}
                onMount={handleEditorDidMount}
            />
            {errors.map ((error, index) => {
                return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
            })}
        </>
    )
}

export default CustomWidget;
CustomWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired
  };