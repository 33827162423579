import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import Grid from '@mui/material/Unstable_Grid2';
import LayoutItem from './LayoutItem';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        background: isDragging ? "#0277BD" : null,
        // styles we need to apply on draggables
        ...draggableStyle
    }
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightgrey" : null
});

const LayoutOrganizer = ({schema}) => {
    const [items, setItems] = useState([]);
    useEffect (()=>{
        _.isArray (schema.datasource) && setItems (schema.datasource);
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(items).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setItems (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        let newItems = reorder( items, result.source.index, result.destination.index);
        setItems(newItems);
        schema.getParent ().__setDataSource__ (schema.__name, newItems);
        schema.onChange && schema.onChange ();
    }
    
    return (
        <>
        {schema && !schema.behaviour?.hidden && 
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <Grid container spacing={2} 
                        ref={provided.innerRef} 
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                    return (<Grid xs={item.width}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                        <LayoutItem item={item} schema={schema}/>
                                    </Grid>)
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
                </Droppable>
            </DragDropContext>
        }
        </>
    );
};

export default LayoutOrganizer;
LayoutOrganizer.propTypes = {
    schema: PropTypes.object.isRequired,
};