import React, { useEffect, useState } from "react";
import "./Block.css";
import "./customBlocks/custom_Blocks";
import {javascriptGenerator} from 'blockly/javascript';
import { BlocklyWorkspace } from "react-blockly";
import Blockly from "blockly";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RuleComponent from "../../EditorComponent/RuleComponent";
import uuid from 'react-uuid';
import * as _ from "lodash";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import JSON5 from 'json5';

var jsProvider;
export default function ActivityFormBuilder ({schema}) {
  const [value, setValue] = useState({});
  const [loaded, setLoaded] = useState (false);
  
  useEffect (() => {
    let v = schema.getPropertyValue();
    if (!_.isObject (v)){
      v = {
        xml: '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>',
        errors: ["Need minimum 1 attribute to configure the form"]
      }
    }
    setValue (v);
    setTimeout (()=> { !schema.behaviour?.readonly && schema.onInit && schema.onInit ();  }, 100);
    return () => {
      jsProvider?.dispose ();
    }
  }, []);
  useEffect(() => {
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource)){
      setLoaded (true);
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  },[schema.datasource]);

  Blockly.Blocks["attribute"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Attribute Ref*")
        .appendField(new Blockly.FieldDropdown(this.generateOptions), 'identifier')
        .appendField(new Blockly.FieldTextInput(''), "attributeRef");
      this.getField ("attributeRef").setVisible (schema.behaviour?.readonly);
      this.getField ("identifier").setVisible (!schema.behaviour?.readonly);
      this.appendDummyInput()
        .appendField("Name*")
        .appendField(new Blockly.FieldTextInput('', (value)=>{ return _.camelCase (value)}), 'name');
      this.appendDummyInput()
        .appendField("Override Settings")
        .appendField(new Blockly.FieldMultilineInput(''), 'settings');
      this.appendDummyInput()
        .appendField("Override Behaviour")
        .appendField(new Blockly.FieldMultilineInput(''), 'behaviour');
      this.setPreviousStatement (true, ["attribute", "object"]);
      this.setNextStatement (true, ["attribute", "object"]);
      this.setColour(100);
      this.setHelpUrl('/page/getContent/what_is_activity_attribute_standalone');
    },
    generateOptions: function() {
      return (schema.datasource && schema.datasource[0].length>0)?schema.datasource[0]:[["No Attributes Configured", ""]];
    }
  }

  Blockly.Blocks["composite"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Attribute (Composite) Ref*")
        .appendField(new Blockly.FieldDropdown(this.generateOptions), 'identifier')
        .appendField(new Blockly.FieldTextInput(''), "attributeRef");
      this.getField ("attributeRef").setVisible (schema.behaviour?.readonly);
      this.getField ("identifier").setVisible (!schema.behaviour?.readonly);
      this.appendDummyInput()
        .appendField("Name*")
        .appendField(new Blockly.FieldTextInput('', (value)=>{ return _.camelCase (value)}), 'name');
      this.appendDummyInput()
        .appendField("Override Settings")
        .appendField(new Blockly.FieldMultilineInput(''), 'settings');
      this.appendDummyInput()
        .appendField("Override Behaviour")
        .appendField(new Blockly.FieldMultilineInput(''), 'behaviour');
      this.appendDummyInput()
        .appendField("Cardinality*")
        .appendField("Min")
        .appendField(new Blockly.FieldNumber(0,0,99), 'min')
        .appendField("Max")
        .appendField(new Blockly.FieldNumber(99,0,99), 'max');      
      this.setPreviousStatement (true, ["composite"]);
      this.setNextStatement (true, ["composite"]);
      this.setColour(150);
      this.setHelpUrl('/page/getContent/what_is_activity_attribute_composite');
    },
    generateOptions: function() {
      return (schema.datasource && schema.datasource[1].length>0)?schema.datasource[1]:[["No Composite Attributes Configured", ""]];
    }
  }

  Blockly.Blocks["rule"] = {
    init: function () {
      this.appendDummyInput()
          .appendField("Execute this ")
          .appendField(new Blockly.FieldDropdown(this.generateOptions), 'name')
          .appendField("Rule");
      this.appendDummyInput()
          .appendField("if")
          .appendField(new Blockly.FieldTextInput('onChange', (value)=>{ return _.camelCase (value)}), 'event');
      this.appendDummyInput()
          .appendField("of")
          .appendField(new Blockly.FieldTextInput(), 'triggers');
      this.setColour(100);
      this.setPreviousStatement (true, "rule");
      this.setNextStatement (true, "rule");
      this.setHelpUrl('/page/getContent/what_is_activity_rule');
    },
    generateOptions: function() {
      let rules = value?.rules?.length>0?[]:[["No Rules Configured", ""]];
      (value?.rules || []).forEach ((r) => {
        rules.push ([r.name, r.id])
      })
      return rules;
    }
  }

  const onJavascriptChange = (workspace) => {
    if (schema.behaviour?.readonly) return;
    value.errors = [];
    let code = javascriptGenerator.workspaceToCode(workspace);
    if (!code){ 
      value.errors = ["Not a valid form, please fix the errors"];
      setValue (value);
      schema.setPropertyValue (value);
      return;
    }
    try {
      if (code.trim().endsWith (",")) code = code.trim().slice (0,-1);
      let schema = JSON5.parse (code);
      if (schema.type !== "schema") value.errors = ["Not a valid form, please enclose everything in a Schema Specification"];
      else value.script = code
      // console.log ("Code",code);
    } catch (e){
      // console.log ("Error", code, e);
      value.errors = ["Not a valid form, please fix the errors"];
    }
    setValue (value);
  }

  const onXMLChange = (xml) => {
    if (schema.behaviour?.readonly) return;
    value.xml = xml;
    schema.setPropertyValue (value);
  }

  const onAddRule = () => {
    if (!value?.rules) value.rules = [];
    value.rules.push ({
        id: uuid (),
        name: "Set Name",
        description: "Set Description",
        script: "// Please add your business rules below inside the evaluate function\n__evaluate__ = () => /*This is a root function; which is called, please do not add var / let infront of this function*/\n{\n}",
        compiled: "",
        isActive: true
    });
    schema.setPropertyValue (value);
  }

  const onDeleteRule = (id) => {
    schema.getParent().__confirmation__ ({
        title: "Warning",  
        message: "Are you sure, you want to delete this rule?",
        events: [{
            label: "Cancel"
        },  {
            label: "Yes, Please",
            onClick: () => {
                value.rules = value.rules.filter ((rule) => rule.id !== id);
                schema.setPropertyValue (value);
            }
        }]
    });
  }

  const onUpdateRule = () => schema.setPropertyValue (value);

  const onReOrderRule = (rules) => {
    value.rules = rules;
    schema.setPropertyValue (value);
  }

  return (
    <>
      {schema && !schema.behaviour?.hidden && <>
        {schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>{schema.settings.label}</Typography>}
        {(schema.settings?.help || schema.error) && <pre>
            <Typography sx={{fontSize: 12, mb:5}} color={(schema.error)?"#ef5350":"text.secondary"} gutterBottom component='div'>
                {(schema.settings.help || "") + "\n" + (schema.error||"")}
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Typography>
        </pre>}
        {schema && value &&
        <>
          {(schema.behaviour?.readonly || loaded) &&
            <>
              <RuleComponent onAddRule={onAddRule} rules={value.rules} onUpdateRule={onUpdateRule} onDeleteRule={onDeleteRule} onReOrderRule={onReOrderRule} readonly={schema.behaviour?.readonly}/>
              <Box sx={{width: "100%", m:1, p:1, pr:3}}>
                <BlocklyWorkspace
                  toolboxConfiguration={schema.settings?.toolbox || {}}
                  initialXml={value.xml}
                  className="fill-height"
                  workspaceConfiguration={{ 
                    grid: { spacing: 20, length: 3, colour: "#ccc", snap: true, },
                    comments: true,
                    trashcan: true,
                    zoom: { controls: true, wheel: true, startScale: 0.75 },
                    readOnly: schema.behaviour?.readonly,
                    collapse: true,
                    move:{ scrollbars: { horizontal: true, vertical: true }, drag: true, wheel: false }
                  }}
                  onWorkspaceChange={onJavascriptChange}
                  onXmlChange={onXMLChange}
                />
              </Box>
            </>
          }
          {(value?.errors || []).map ((error, index) => {
              return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
          })}
        </>}
      </>}
    </>
  );
}

ActivityFormBuilder.propTypes = {
  schema: PropTypes.object.isRequired
};
