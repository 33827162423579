import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Editor from "@monaco-editor/react";
import RuleComponent from 'pages/dynamic/editor/EditorComponent/RuleComponent';
import uuid from 'react-uuid';

const CustomWidget = ({settings, onChange}) => {
    const [errors, setErrors] = useState([]);
    const handleEditorValidation = (markers) => {
        let errors = [];
        markers.forEach(marker => {
            errors.push (marker.message);
        });
        setErrors (errors)
        settings.__errors = errors;
        onChange (settings);
    }

    const onScriptChange = (newValue) => {
        settings.script = newValue;
        onChange (settings);
    }

    const onAddRule = () => {
        if (!settings?.rules) settings.rules = [];
        settings.rules.push ({
            id: uuid (),
            name: "Set Name",
            description: "Set Description",
            script: "// Write your rules here\n\n\n",
            compiled: "",
            isActive: true
        });
        onChange (settings);
    }
    const onUpdateRule = () => {
        onChange (settings);
    }

    const generateJSONSuggestion = (monaco) => {
        let suggs=[];
        (settings.rules || []).forEach ((rule)=>{
            if (!rule.error)
            suggs.push ({
                label: rule.name + " (Rule)",
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: '{"id": "'+rule.id+'", "name":"'+ rule.name +'"}'
            });
        })
        return { suggestions: suggs };
    }
    let jsonProvider;

    useEffect( () => () => {
        jsonProvider?.dispose ();
    }, [] );

    const handleEditorDidMount = (editor, monaco) => {
        jsonProvider?.dispose ();
        jsonProvider = monaco.languages.registerCompletionItemProvider('json', {
            provideCompletionItems: () => generateJSONSuggestion(monaco)
        });
    }

    return (
        <>
            <Box sx={{m:1, width: '100%'}}>
                <Typography variant='h5' gutterBottom component='div'>
                Custom Widget Settings
                </Typography>
                <Typography variant='h6' gutterBottom component='div'>
                Custom widget properties, which will be applied on the widget
                </Typography>
            </Box>
            <RuleComponent onAddRule={onAddRule} rules={settings.rules} onUpdateRule={onUpdateRule}/>
            <Editor
                theme="vs-dark"
                language="json"
                value={settings.script || "{}"}
                height="80vh"
                options = {{
                    minimap: { enabled: false }
                }}
                onValidate={handleEditorValidation}
                onChange={onScriptChange}
                onMount={handleEditorDidMount}
            />
            {errors.map ((error, index) => {
                return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
            })}
        </>
    )
}

export default CustomWidget;
CustomWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
  };