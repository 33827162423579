import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import AppNotificationContent from './AppNotificationContent';
import MailIcon from '@mui/icons-material/Mail';
import AppTooltip from '../AppTooltip';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, orderBy,query, limit } from 'firebase/firestore';
import { useAuthUser } from '@crema/utility/AuthHooks';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

const AppNotifications = ({
  drawerPosition,
  tooltipPosition,
  sxNotificationContentStyle,
}) => {
  const {ui} = useAuthUser();
  const [data, setData] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    if (ui.firebaseConfig){
      const firebaseConfig = {
        apiKey: "AIzaSyBMTRDi3oZGNR0mjD8h0_eNSobS1JIFawM",
        authDomain: "urbbit-core.firebaseapp.com",
        projectId: "urbbit-core",
        storageBucket: "urbbit-core.appspot.com",
        messagingSenderId: "448578710499",
        appId: "1:448578710499:web:4d56ce7d579d17a3364ed6"
      };
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const colRef = collection(db, ui.partyId);
      const q = query (colRef, orderBy("date", "desc"), limit (11));
      const unsubListener = onSnapshot(q, snapshot => {
        setData (snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      return unsubListener; // <- don't forget to return the unsubscribe function!
    }
  },[]);
  return (
    <>
      {data.length>0 && <IconButton aria-label='notification' size="large">
        <AppTooltip title='Notification' placement={tooltipPosition}>
          <Badge badgeContent={data.length} max={10} color='primary' onClick={() => setShowNotification(true)}>
            <MailIcon color='action' />
          </Badge>
        </AppTooltip>
      </IconButton>}
      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
          data = {data}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;

AppNotifications.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
