/*global google*/
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, DrawingManager, StandaloneSearchBox, Polygon, Circle, Rectangle, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { HexColorPicker } from "react-colorful";
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import uuid from 'react-uuid';
import * as _ from "lodash";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const styles = require('shared/vendors/mapStyles.json')
const STYLES = {
    infoView: {
      background: '#fff',
      border: '0px solid #ccc',
      padding: 0,
    },
    inputStyle: {
        boxSizing: `border-box`,
        border: `1px solid transparent`,
        height: "48px",
        padding: `0 12px`,
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        fontSize: `14px`,
        outline: `none`,
        textOverflow: `ellipses`,
    }
};

const DrawingMap = ({schema}) => {
    const googleMapApiKey = useSelector(({content}) => content.googleMapApiKey);
    const [center, setCenter] = useState (schema.settings?.map?.center || { lat: -37.7006775, lng: 144.7995576});
    const [searchBox, setSearchBox] = useState (null);
    const [isEmpty, setEmpty] = useState (true);
    const [map, setMap] = useState (null);
    const [info, setInfo] = useState (null);
    const [mapStyle, setMapStyle] = useState ([]);
    const [selection, setSelection] = useState(null);
    const [options, setOptions] = useState ({
        editable:true,
        fillColor: "#C8E6C9",
        fillOpacity: 0.1,
        strokeColor: "#C8E6C9",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    })
    const [previous, setPrevious] = useState ();
    const [reference, setReference] = useState ();
    useEffect (()=>{
        setMapStyle (_.assign (styles, schema.settings?.styles || []));
        if (!map) return;
        if (schema.getPropertyValue () && _.isArray (schema.getPropertyValue ()) && schema.getPropertyValue ().length>0){
            setPrevious (schema.getPropertyValue ());
            setEmpty (schema.getPropertyValue ().length==0);
            let marker = _.find (schema.getPropertyValue (), ["type", "marker"])
            let position, zoom = 18;
            if (marker){
                position = marker.center;
            } else {
                let first = schema.getPropertyValue ()[0];
                zoom = 15
                position = first.center;
            }
            map.setCenter (position);
            map.setZoom (zoom);
        } else schema.setPropertyValue ([]);
    }, [map])

    useEffect (()=>{
        if (!map) return;
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource)){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            _.map (schema.datasource, (v) => { v.readonly = true, v.zIndex = 1 });
            setReference (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }, [schema.datasource]);

    const getElement = (v) => {
        switch (v.type){
            case "polygon":
                return <Polygon key={v.id} options={_.assign (_.cloneDeep (options), { strokeColor: v.strokeColor, fillColor: v.fillColor, editable: (!schema.behaviour.readonly && !v.readonly) })} 
                    paths={v.paths} onLoad={(shape) => { handleUpdate (v.type, shape, v) }}/>
            case "polyline":
                return <Polyline key={v.id} options={_.assign (_.cloneDeep (options), { strokeColor: v.strokeColor, fillColor: v.fillColor, editable: (!schema.behaviour.readonly && !v.readonly) })} 
                    path={v.paths} onLoad={(shape) => { handleUpdate (v.type, shape, v) }}/>
            case "circle":
                return <Circle key={v.id} options={_.assign (_.cloneDeep (options), { strokeColor: v.strokeColor, fillColor: v.fillColor, editable: (!schema.behaviour.readonly && !v.readonly) })} 
                    center={v.center} radius={v.radius} onLoad={(shape) => { handleUpdate (v.type, shape, v) }}/>
            case "rectangle":
                return <Rectangle key={v.id} options={_.assign (_.cloneDeep (options), { strokeColor: v.strokeColor, fillColor: v.fillColor, editable: (!schema.behaviour.readonly && !v.readonly) })} 
                    bounds={{north: v.ne.lat, south: v.sw.lat, east: v.ne.lng, west: v.sw.lng } }
                    onLoad={(shape) => { handleUpdate (v.type, shape, v) }}/>
            case "marker":
                return <Marker key={v.id} options={_.assign (_.cloneDeep (options), { editable: (!schema.behaviour.readonly && !v.readonly), draggable: (!schema.behaviour.readonly && !v.readonly) })} 
                position={v.center} onLoad={(shape)=>{ handleUpdate (v.type, shape, v) }}/>
        }
    }

    const child = useMemo (() => {
        if (previous){ return (<>{_.map (previous, (v) => getElement (v))}</>)}
        return <></>
    }, [previous]);

    const staticChild = useMemo (() => {
        if (reference){ return (<>{_.map (reference, (v) => getElement (v))}</>)}
        return <></>
    }, [reference]);

    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget);};
    const handleClose = () => { setAnchorEl(null); };

    const [anchorOverlayEl, setAnchorOverlayEl] = useState(null);
    const openOverlay = Boolean(anchorOverlayEl);
    const handleOverlayClick = (event) => { setAnchorOverlayEl(event.currentTarget);};
    const handleOverlayClose = (value) => { 
        setAnchorOverlayEl(null); 
        if (!value.center) return;
        map.setCenter (value.center);
        setCenter (value.center);
    };

    const [anchorMapTypeEl, setAnchorMapTypeEl] = useState(null);
    const openMapType = Boolean(anchorMapTypeEl);
    const handleMapTypeClose = (e) => {
        setAnchorMapTypeEl (null);
        const {value} = e.currentTarget.dataset;
        if (!value) return;
        if (value === "roadmap")setMapStyle (_.assign (styles, schema.settings?.styles || []));
        else setMapStyle ([]);
        if (value === "general"){
            map.setMapTypeId ("roadmap");
            return;
        } 
        map.setMapTypeId (value);
    }
    const handleMapType = (event) => { setAnchorMapTypeEl (event.currentTarget); };
    
    
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapApiKey,
        libraries: ["places", "drawing"]
    });
    const onPlacesChanged = () => {
        const places = searchBox.getPlaces ();
        if (places.length>0){
            setCenter ({ lat: places[0].geometry.location.lat(), lng: places [0].geometry.location.lng() });
            map.setCenter ({ lat: places[0].geometry.location.lat(), lng: places [0].geometry.location.lng() })
            map.setZoom (18);
        }
    }

    const handleDelete = () => {
        if (schema.behaviour?.readonly) return;
        if (selection){
            const value = schema.getPropertyValue ();
            _.remove (value, (v) => v.id === selection.id )
            
            selection.setMap && selection.setMap (null);
            setSelection (null);
            schema.setPropertyValue (value);
            setEmpty (value.length == 0);
            setInfo (null);
        }
    }

    const handleUpdate = (type, overlay, prev) => {
        const isNewShape = overlay.id?false:true;
        let data = { id: overlay.id || prev?.id || uuid (), type: type, strokeColor: overlay.strokeColor, fillColor: overlay.fillColor }
        if (prev?.readonly) data = prev;
        overlay.set ("data", data);
        if (isNewShape){
            overlay.set ("id", data.id);
            overlay.addListener ("click", (e) => {
                let position = {lat: e.latLng.lat (), lng: e.latLng.lng ()}
                setInfo (_.assign (overlay.data, { position: position }));
                if (!overlay.data.readonly) setSelection (overlay); else setSelection (null);
                map.setCenter (position);
                setCenter (data.center);
            })
        }
        let bounds;
        switch (type){
            case "rectangle":
                isNewShape && !prev?.readonly && overlay.addListener ("bounds_changed", () => { handleUpdate (type, overlay) });
                data.center = { lat: overlay.getBounds ().getCenter ().lat (), lng: overlay.getBounds ().getCenter ().lng ()}
                data.ne = { lat: overlay.getBounds ().getNorthEast ().lat (), lng: overlay.getBounds ().getNorthEast ().lng () }
                data.sw = { lat: overlay.getBounds ().getSouthWest ().lat (), lng: overlay.getBounds ().getSouthWest ().lng () }
                setCenter (data.center);
                map.fitBounds (overlay.getBounds ());
                break;
            case "circle":
                isNewShape && !prev?.readonly && overlay.addListener ("radius_changed", () => { handleUpdate (type, overlay) })
                isNewShape && !prev?.readonly && overlay.addListener ("center_changed", () => { handleUpdate (type, overlay) })
                data.center = { lat: overlay.center.lat (), lng: overlay.center.lng ()}
                data.radius = overlay.radius;
                setCenter (data.center);
                map.fitBounds (overlay.getBounds ());
                break;
            case "polygon":
            case "polyline":
                isNewShape && !prev?.readonly && overlay.getPath().addListener ("insert_at", () => { handleUpdate (type, overlay) })
                isNewShape && !prev?.readonly && overlay.getPath().addListener ("remove_at", () => { handleUpdate (type, overlay) })
                isNewShape && !prev?.readonly && overlay.getPath().addListener ("set_at", () => { handleUpdate (type, overlay) })
                !prev?.readonly && overlay.addListener ("contextmenu", (e) => { 
                    if (e.vertex && overlay.getPath && !prev?.readonly){ 
                        try{
                            overlay.getPath ().removeAt (e.vertex); 
                        } catch (e){console.log (e);}
                    }
                })
                data.paths = []
                overlay.getPath ().getArray ().map ((d) => { data.paths.push ({ lat: d.lat (), lng: d.lng ()}) })
                if (google){
                    bounds = new google.maps.LatLngBounds();
                    overlay.getPath ().getArray ().map((element) => {bounds.extend(element)}); 
                    data.center = { lat: bounds.getCenter ().lat (), lng: bounds.getCenter ().lng ()}
                    map.fitBounds (bounds);
                    if (google.maps.geometry) data.distance = google.maps.geometry.spherical.computeLength(overlay.getPath ());
                    else data.distance = prev?.distance;
                } else {
                    data.center = prev?.center || data.paths[0];
                    data.distance = prev?.distance;
                }
                setCenter (data.center);
                break;
            case "marker":
                (isNewShape && !prev?.readonly) && overlay.addListener ("dragend", () => { handleUpdate (type, overlay) })
                data.center = { lat: overlay.position.lat (), lng: overlay.position.lng ()}
                break;
        }
        if (prev?.readonly) return; // Info only so just need to register the click event
        // Resolving Address of center
        if (google){
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: data.center })
                .then ((resp) => {
                    data.address = prev?.address || {};
                    let place = data.address;
                    if (resp?.results?.length > 0 ){
                        place.name = resp.results [0].formatted_address;
                        place.address_components = resp.results [0].address_components;
                        place.types = resp.results [0].types;
                        data.address = place;
                        updateSchemaProperty (data);
                    }
                    
                })
                .catch((e) => {
                    data.address = prev?.address || {};
                    console.log("Geocoder failed due to: " + e);
                })
        } else {
            data.address = prev?.address;
            updateSchemaProperty (data);
        }
    }

    const updateSchemaProperty = (data) => {
        if (schema.behaviour?.readonly) return;
        const values = _.cloneDeep (schema.getPropertyValue ());
        var index = _.findIndex(values, {id: data.id});
        if (index == -1) values.push (data); 
        else values.splice (index, 1, data);
        schema.setPropertyValue (values);
        setEmpty (values.length == 0);
        schema.onChange && schema.onChange ();
    }
    
    return (
    <>
        {schema && !schema.behaviour?.hidden && <>
        <Typography sx={{fontSize: 14, mb:schema.settings?.help?1:5}} color='text.secondary' gutterBottom component='div'>
            {schema.settings.label || ""}
        </Typography>
        <pre>
            <Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                {schema.settings.help || ""}
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Typography>
        </pre>
        {isLoaded && 
            <GoogleMap
                onLoad={(ref) => setMap (ref)}
                onClick={()=>{setSelection(null)}}
                onDrag={() => setSelection (null)}
                mapContainerStyle={schema.settings?.map?.style || { width: '100%', height: '80vh'}}
                center={center}
                zoom={schema.settings?.map?.zoom || 10}
                tilt={0}
                options={{
                    disableDefaultUI: true, // disable default map UI
                    draggable: true, // make map draggable
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    styles: mapStyle
                }}
            >   
                <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"} sx={{position: "absolute", right: "10px", top: "5px", zIndex: 9999}} >
                    {!isEmpty && <Tooltip title={"List of all Shapes"} placement="top" key={"list"} arrow>
                        <IconButton variant='contained' sx={{ backgroundColor: "#303F9F"}} onClick={handleOverlayClick}>
                            <FontAwesomeIcon icon={"fas fa-list"} size={"xs"} color="white"/>
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={"Map Type"} placement="top" key={"maptype"} arrow>
                        <IconButton variant='contained' sx={{ backgroundColor: "#303F9F"}} onClick={handleMapType}>
                            <FontAwesomeIcon icon={"fas fa-map"} size={"xs"} color="white"/>
                        </IconButton>
                    </Tooltip>
                    {selection && !schema.behaviour?.readonly && <Tooltip title={"Delete Shape"} placement="top" key={"delete"} arrow>
                        <IconButton variant='contained' sx={{ backgroundColor: "#F04F47"}} onClick={handleDelete}>
                            <FontAwesomeIcon icon={"fas fa-trash"} size={"xs"} color="white"/>
                        </IconButton>
                    </Tooltip>}
                    {!schema.behaviour?.readonly && <Tooltip title={"Select Color"} placement="top" key={"color"} arrow>
                        <IconButton variant='contained' onClick={handleClick} sx={{ backgroundColor: "#303F9F"}}>
                            <FontAwesomeIcon icon={"fas fa-droplet"} size={"xs"} color={options.strokeColor}/>
                        </IconButton>
                    </Tooltip>}
                    {!schema.settings.disableSearch && <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={(ref) => setSearchBox (ref)}>
                        <input type='text' placeholder='Search Address (Nearby)' style={STYLES.inputStyle}/>
                    </StandaloneSearchBox>}
                </Stack>
                <Marker position={center} icon={"http://maps.gstatic.com/mapfiles/ms2/micons/grn-pushpin.png"}/>
                {staticChild}
                {child}
                {info && <InfoWindow position={info.position} options={{closeBoxURL: ``, enableEventPropagation: true}} onCloseClick={() => setInfo (null)}>
                    <div style={STYLES.infoView}>
                        {info.address.name && <div><b>Address: </b>{info.address.name}</div>}
                        {info.center && <><div><b>Latitude:</b> {info.center.lat}</div><div><b>Longitude:</b> {info.center.lng}</div></>}
                        {info.distance && <div><b>Preimeter:</b> {info.distance.toFixed(2)}m</div>}
                        {info.radius && <div><b>Radius:</b> {info.radius.toFixed(2)}m</div>}
                        {!schema.behaviour?.readonly && !info?.readonly && <Stack spacing={2} direction="row" justifyItems={"flex-end"} sx={{float: "right"}}>
                            <Tooltip title={"Set this location as a default location"} placement="top" key={"default"} arrow>
                                <Button variant="text" size='small' onClick={()=>{
                                    const values = _.cloneDeep (schema.getPropertyValue ());
                                    const val = _.remove (values, (v) => { return v.id === info.id});
                                    values.splice (0, 0, val[0]);
                                    schema.setPropertyValue (values);
                                    schema.onChange && schema.onChange ();
                                    setInfo (null);
                                }}>Set as default</Button>
                            </Tooltip>
                        </Stack>}
                    </div>
                </InfoWindow>}
                <DrawingManager 
                    options={{
                        polygonOptions: options,
                        circleOptions: options,
                        rectangleOptions: options,
                        polylineOptions: options,
                        markerOptions: { editable: !schema.behaviour?.readonly, draggable: !schema.behaviour?.readonly },
                        drawingControl: !schema.behaviour?.readonly,
                        drawingControlOptions: { drawingModes: schema.settings.drawingModes || ["marker", "circle", "rectangle", "polygon", "polyline"]}
                    }} 
                    
                    onOverlayComplete={(shape) => {       
                        handleUpdate (shape.type, shape.overlay);
                    }}
                />
            </GoogleMap>}
            <Menu id='maptype-menu'
                anchorEl={anchorMapTypeEl}
                open={openMapType}
                onClose={handleMapTypeClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button'}}
            >
                <MenuItem key={"roadmap"} data-value={"roadmap"} onClick={handleMapTypeClose}>Roadmap (Default)</MenuItem>
                <MenuItem key={"general"} data-value={"general"} onClick={handleMapTypeClose}>Roadmap (General)</MenuItem>
                <MenuItem key={"satellite"} data-value={"satellite"} onClick={handleMapTypeClose}>Satellite</MenuItem>
                <MenuItem key={"hybrid"} data-value={"hybrid"} onClick={handleMapTypeClose}>Hybrid</MenuItem>
                <MenuItem key={"terrain"} data-value={"terrain"} onClick={handleMapTypeClose}>Terrain</MenuItem>
            </Menu>
            <Menu id='overlay-menu'
                anchorEl={anchorOverlayEl}
                open={openOverlay}
                onClose={handleOverlayClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button'}}
            >
                {(_.isArray(schema.getPropertyValue ()) && schema.getPropertyValue ().length>0) ? 
                    (schema.getPropertyValue () || []).map ((v, i) => { 
                        return (<MenuItem key={v.id} onClick={() => handleOverlayClose (v)}>{v.address?.name || v.type + " (Overlay) " + i}</MenuItem>) }
                    ) :
                    <MenuItem key={"none"} disabled>None</MenuItem>
                }
            </Menu>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            >
                <Box sx={{ overflow: "hidden", background: "transparent" }}>
                    <HexColorPicker
                        color={options.strokeColor}
                        onChange={(color) => {
                            setOptions(options => ({...options, fillColor: color, strokeColor: color }))
                        }}
                    />
                </Box>
            </Popover>
        </>}
    </>

  );
};

export default DrawingMap;

DrawingMap.propTypes = {
  data: PropTypes.array,
  schema: PropTypes.object.isRequired,
};
