import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { MathUtils } from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { easing } from 'maath';
import { Grid, useDrag } from './Grid';

function Cube ({ position = [0.5, 0.5, -0.5], round = Math.round, clamp = MathUtils.clamp, ...props }) {
    const ref = useRef()
    const pos = useRef(position)
    const onDrag = useCallback(({ x, z }) => (pos.current = [round(clamp(x, -5, 4)) + 0.5, position[1], round(clamp(z, -5, 4)) + 0.5]), [])
    const [events, active, hovered] = useDrag(onDrag)
    useEffect(() => void (document.body.style.cursor = active ? 'grabbing' : hovered ? 'grab' : 'auto'), [active, hovered])
    useFrame((state, delta) => {
      easing.damp3(ref.current.position, pos.current, 0.1, delta)
      easing.dampC(ref.current.material.color, active ? 'white' : hovered ? 'lightblue' : '#FF7043', 0.1, delta)
    })
    return (
      <mesh ref={ref} castShadow receiveShadow {...events} {...props}>
        <boxGeometry />
        <meshStandardMaterial />
      </mesh>
    )
}

Cube.propTypes = {
    position: PropTypes.any,
    round: PropTypes.any,
    clamp: PropTypes.any,
};

const GeometryModule = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <Box sx={{height:"70vh", width: '100%' }}>
                <Typography sx={{fontSize: 14, mb:schema.settings?.help?1:5}} color='text.secondary' gutterBottom component='div'>
                    {schema.settings.label || ""}
                </Typography>
                <pre>
                    <Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                        {schema.settings.help || ""}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top" arrow><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>
                <Canvas shadows orthographic camera={{ position: [5, 5, 5], zoom: 50 }} >
                    <ambientLight intensity={1} />
                    <pointLight position={[10, 10, 5]} castShadow shadow-mapSize={1024} />
                    <Grid scale={10}>
                        <Cube position={[0.5, 1, -0.5]} scale={[1, 2, 1]} />
                        <Cube position={[2 + 0.5, 0.5, 2 - 0.5]} />
                        <Cube position={[-2 + 0.5, 1.5, 2 + 0.5]} scale={[1, 3, 1]} />
                    </Grid>
                    <OrbitControls makeDefault />
                </Canvas>
            </Box>
            }
        </>
    )
}

export default GeometryModule;

GeometryModule.propTypes = {
    schema: PropTypes.object.isRequired,
};