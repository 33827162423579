import { post, put, get } from 'api/sdk/index';
import _ from "lodash";
import { saveAs } from 'file-saver';

export const callService = (url, body, success, failure) => {
  return () => {
    post (url, body).then((data) => {
      if (data?.headers && data?.headers["content-disposition"]){
        var filename = null;
        var disposition = data.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        if (filename){
          let blob = new Blob([data.data]);
          saveAs(blob, filename);   
        }
      }
      if (_.isFunction (success)) success ({
        data: data.data?.response,
        status: data.status
      });
    })
    .catch((error) => {
      if (_.isFunction (failure)) failure (error.message);
    });
  };
};

export const uploadService = (url, body, success, failure) => {
  return () => {
    put (url, body).then((data) => {
      if (_.isFunction (success)) success ({
        data: data.data?.response,
        status: data.status
      });
    })
    .catch((error) => {
      if (_.isFunction (failure)) failure (error.message);
    });
  };
};

export const getService = (url) => {
  return get(url);
};