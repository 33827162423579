import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridActionsCellItem, gridClasses, GridToolbarContainer, } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import * as _ from "lodash";
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';
import { alpha, styled } from "@mui/material/styles";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
}));

function EditToolbar({handleAdd}) {
  const handleClick = () => handleAdd({ id: uuid()});
  return (
    <GridToolbarContainer>
      <Button color="secondary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export default function EditableGrid ({schema}) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();
  const {theme} = useThemeContext();
  const [columnVisible, setColumnVisible] = useState({});
  const [style, setStyle] = useState({
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: schema.settings?.header?.backgroundColor || theme.palette.primary.main,
      color: schema.settings?.header?.color || theme.palette.primary.contrastText
    }
  });
  useEffect(() => {
    (schema?.settings?.style) && setStyle (_.assign (style, schema?.settings?.style));
    if (schema?.settings?.columns){
      schema.settings.columns.map ((col)=> {
        col.editable = !schema.behaviour.readonly;
        if (col.field.includes (".")){
          col.valueGetter = (params) => {
              return _.get (params.row, params.field)
          }
        }
        if (col.href ){
          let url=col.href; 
          let col_model = null;
          if (_.isObject (col.href)){
              url = col.href.id || null;
              col_model = col.href.model || null
          }
          // let url = _.isObject (schema.href)?"/api/"+schema.href.id:schema.href;
          dispatch (callService (url, col_model, (success) => {
              if (_.isArray(success.data)){
                  col.valueOptions=success.data;
                  setColumns (schema.settings.columns);
              }
          }, (failure)=> {
              console.log (failure)
          }));
        }
      })
      if (!schema.behaviour.readonly && schema.settings.action && _.filter(schema.settings.columns, a => a.field === 'actions').length === 0){
        schema.settings.columns.push({
          field: "actions",
          type: "actions",
          headerName: schema.settings.action.headerName || "Operations",
          width: schema.settings.action.width || 150,
          getActions: (params) => {
            const userActions = [];
            schema.settings.action.actions.forEach ((action) => {
              !action.hidden && userActions.push (
                <GridActionsCellItem
                  icon={<Tooltip title={action.label} placement="top"><FontAwesomeIcon icon={action.icon?.code} color={action.icon?.color || theme.palette.primary.main}/></Tooltip>}
                  label={action.label}
                  onClick={() => (action.event && schema[action.event] && schema[action.event] (params.row),1)}
                />
                )
            });
            return userActions;
          }
        });
      }
      setColumns(schema.settings.columns);
      if (schema.datasource instanceof Array) setRows (schema.datasource);
      setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
      if (schema.href)
          dispatch (callService (schema.href, null, (success) => {
            if (_.isArray(success.data)){
              setRows (success.data);
              schema.getParent ().__setDataSource__ (schema.__name, success.data);
            }
          }, (failure)=> {
              console.log (failure)
          }));
      if (schema.settings?.columnVisibilityModel && !_.isEqual(schema.settings?.columnVisibilityModel, columnVisible)){
          setColumnVisible (schema.settings?.columnVisibilityModel);
      }
      schema.getParent ().__setSettings__ (schema.__name, schema.settings);
      if (schema.settings.isApplyValueToDataSource) {
        setTimeout (()=>{
          schema.getParent ().__setDataSource__ (schema.__name, schema.getPropertyValue () || [])
          setRows (schema.getPropertyValue () || [])
        })
      }
    }
  },[]);
  useEffect(() => {
    _.map (schema.settings.columns, (col) => {
      if (col.type && col.type == "singleSelect")
      col.valueFormatter = ({ value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find((p) => p.value === value);
        return option?.label || "";
      }
    });
    setColumns(schema.settings.columns);
  },[schema.settings]);

  useEffect(() => {
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(rows).xorWith(schema.datasource, _.isEqual).isEmpty()) {
      schema.datasource = _.uniqBy (schema.datasource, "id");
      setRows (schema.datasource);
      // This is to hold the change in datasource every time
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  },[schema.datasource]);

  const handleAdd = (row) => {
    schema.onAdd && schema.onAdd (row)
  }
  const handleChange = (row) => {
    _.map(_.cloneDeep(schema.datasource), function(a) {
      return a.id === row.id ? row : a;
    });
    return row;
  };

  const handleRowClick = (params) => {
    schema.onRowClick && schema.onRowClick (params.row);
  };

  return (
    <Box sx={schema.settings.sx || {height:"80vh", width: "100%", m:1, p:1, mb:20}}>
      <Typography variant='h5' gutterBottom color='text.secondary'>
      {schema.settings.label}
      </Typography>
      <Typography variant='h6' gutterBottom color='text.secondary'>
      {schema.settings.help}
      </Typography>
      <StripedDataGrid
        sx={{
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: schema.settings?.header?.backgroundColor || theme.palette.primary.main,
            color: schema.settings?.header?.color || theme.palette.primary.contrastText
          },
        }}
        rows={rows} 
        columns={columns}
        rowHeight={38}
        onRowClick={handleRowClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={handleChange}
        onCellEditStart={(params) => {
          params.row.isEditing = true;
        }}
        initialState={{ pinnedColumns: schema.settings.pinnedColumns || {} }}
        onCellEditStop={(params) => {
          params.row.isEditing = false;
        }}
        components={(schema.settings.hideToolbar || schema.behaviour.readonly)?null:{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { handleAdd },
        }}
      />
    </Box>
  );
}

EditableGrid.propTypes = {
  schema: PropTypes.object.isRequired
};