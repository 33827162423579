import React, { useEffect, useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import './styles.css';

const JoditComponent = ({schema}) => {
    const [value, setValue] = useState("");
    const editor = useRef(null);
    const config = useMemo(
		() => ({
			readonly: schema.behaviour.readonly, 
            toolbar: !schema.behaviour.readonly,
            spellcheck: true,
            toolbarButtonSize: 'large',
            removeButtons: ["fullsize", "about", "file"],
            toolbarAdaptive: true,
            useSearch: true,
            globalFullSize: false,
            statusbar: !schema.behaviour.readonly && !schema.settings.disableStatusbar,
            placeholder: 'Start typings...',
            toolbarSticky: true,
            toolbarStickyOffset: 75,
            style: { font: "16px Arial" }
		}),
		[]
	);
    useEffect (() => {
        setValue (schema.getPropertyValue() || "");
        setTimeout (()=> {  schema.onInit && schema.onInit ((update)=>setValue (update));  }, 100);
    }, []);
    
    const onChange = (content) => {
        setValue (content);
        schema.setPropertyValue (content);
    }
    
    return (
        <>
            {!schema.behaviour?.hidden && 
                <Box m={1}>
                    {!schema.behaviour.readonly && schema.settings.label && <Typography variant='h5' gutterBottom component='div' sx={{mb:2}}>
                        {schema.settings.label}
                    </Typography>}
                    {!schema.behaviour.readonly && schema.settings.help && 
                        <Typography sx={{fontSize: 12}} color='text.secondary' gutterBottom>
                            {schema.settings.help} 
                            {schema.settings.helplink && 
                                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                </IconButton>
                            }
                        </Typography>
                    }
                    <Box sx={(!schema.behaviour.readonly)?{
                        border: 1,
                        borderRadius: 1,
                        borderColor: 'grey.500'
                    }:null}>
                        <JoditEditor
                            ref={editor}
                            value={value}
                            tabIndex={-1}
                            config={config}
                            onBlur={onChange}
                        />
                    </Box>
                    {schema.error && <pre><Typography sx={{fontSize: 12, color: "#ef5350"}}>{schema.error}</Typography></pre>}
                </Box>
            }
        </>
    )
}

export default JoditComponent;
JoditComponent.propTypes = {
    schema: PropTypes.object.isRequired
};

