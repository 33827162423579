import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const DrawerComponent = ({schema}) => {
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);
  return (
    <Drawer anchor={schema.settings.anchor || "right"} open={!schema.behaviour?.hidden} disableEnforceFocus>
      {schema.settings.heading && <AppBar position="sticky">
        <Toolbar color="primary" sx={{backgroundColor: (schema.settings.heading.backgroundColor || "#FFF")}}>
          {schema.settings.heading.label && 
          <Typography sx={{ color: (schema.settings.heading.color || "#000"), flexGrow: 1, }} variant="h4" component="span">
            {schema.settings.heading.label}
          </Typography>}
          {schema.settings.heading.actions && <Stack direction="row" spacing={0}>
            {schema.settings.heading.actions.map ((a, index)=> {
              if (a.icon){
                  if (!a.icon.code.startsWith ("fas")) a.icon.code = "fas fa-" + a.icon.code;
                  return (
                    <IconButton key={"_"+index} size={a.icon.size || "xs"} sx={{color: a.icon.color}}
                        onClick={() => a.event && schema [a.event]  && schema [a.event] () }
                    >
                        <Tooltip title={a.help || "Click Me"} placement="left" arrow><FontAwesomeIcon icon={a.icon.code} /></Tooltip>
                    </IconButton>)
                }
              }
            )}
          </Stack>}
        </Toolbar>
      </AppBar>}
      <Box sx={{width: schema.settings.width, p:5}} role="presentation">
        {Registry.createElement("layout.grid", {schema: schema, model: schema.getPropertyValue ()})}
      </Box>
    </Drawer>
  );
}

export default DrawerComponent;

DrawerComponent.propTypes = {
  schema: PropTypes.object.isRequired,
};
