import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const PaperComponent = ({schema}) => {
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
                <Paper elevation={schema.settings?.elevation || 1}
                    sx={{m:2, p:2}}
                >
                    <>
                        <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                            {schema.settings.label || ""}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color={(schema.error)?"red":"text.secondary"} gutterBottom component={'span'} variant={'body2'}>
                            <pre>{(schema.settings.help || "") + " " + (schema.error || "") }</pre>
                        </Typography>
                        {(schema.getPropertyValue() || []).map ((v, i)=>{
                            return (<Typography sx={{fontSize: 12, mt:3}} color={(schema.error)?"red":"#e65100"} gutterBottom key={i}>
                                    <pre>{v}</pre>
                                </Typography>)
                        })}
                    </>
                </Paper>
            }
        </>
    )
}

export default PaperComponent;

PaperComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};
