import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ReactFlow, { MiniMap, Background, useNodesState, useEdgesState, addEdge, updateEdge, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import Rectangle from "./Nodes/Rectangle";
import Bezier from './Edges/Bezier';
  
const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
    { id: "3", type: "rectangle", data: { label: "Node 1" }, position: { x: 250, y: 5 }, isConnectable: true, 
        style: { background: '#fff', border: '1px solid black', padding: 5, borderRadius: 2 }, }
  ];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2', type: 'bezierLabel', data: { label: "Sample" }, animated: true,}];

const nodeTypes = {
    rectangle: Rectangle,
};
const edgeTypes = {
    bezierLabel: Bezier,
};


const FlowModule = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);

    const [nodes, , onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = useCallback((params) => setEdges((eds) => { params.type = "bezierLabel"; params.data = {}; return addEdge(params, eds)}), [setEdges]);
    const onEdgeUpdate = useCallback((oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)), []);

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <Box>
                <Typography sx={{fontSize: 14, mb:schema.settings?.help?1:5}} color='text.secondary' gutterBottom component='div'>
                    {schema.settings.label || ""}
                </Typography>
                <pre>
                    <Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                        {schema.settings.help || ""}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top" arrow><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>
                <div style={{ width: '100%', height: '70vh' }}>
                    <ReactFlowProvider>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onEdgeUpdate={onEdgeUpdate}
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            fitView
                        >
                            <MiniMap />
                            <Background variant="dots" gap={12} size={1} />
                        </ReactFlow>
                    </ReactFlowProvider>
                </div>
            </Box>
            }
        </>
    )
}

export default FlowModule;

FlowModule.propTypes = {
    schema: PropTypes.object.isRequired,
};