import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import * as _ from "lodash";
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import ImageList from '@mui/material/ImageList';
import ImageItem from './ImageItem';
import { useDispatch } from 'react-redux';
import UploadModern from '../components/UploadModern';
import {useDropzone} from 'react-dropzone';
import { uploadService } from 'redux/actions';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Editor from './editor';
import uuid from 'react-uuid';
import Compressor from 'compressorjs';

const ImageEditorComponent = ({schema}) => {
    const [images, setImages] = useState([]);
    const [error, setError] = useState("");
    const [editing, setEditing] = useState (null);
    const dispatch = useDispatch();    
    const max_size = schema.settings.maxSize || 10;
    const dropzone = useDropzone({
        accept: 'image/*', multiple: false,  maxSize: max_size * 1024 * 1024,
        onDropRejected: () => { setError ("Maximum File size limit " + max_size + "mb"); },
        onDrop: (acceptedFiles) => {
            setError ("")
            acceptedFiles.forEach ((acceptedFile) => {
                onUpload (acceptedFile);
            })
        },
    });

    const onUpload = (file, id) => {
        schema.getParent().__backdrop__ ({ isOpen: true, message: "Uploading ... "});
        new Compressor(file, {
            success (result){
                let form_data = new FormData();
                form_data.append("file", result, result.name);
                form_data.append("cache", "true");
                id && form_data.append("id", id);
                dispatch (uploadService ("__upload__", form_data, 
                    (success) => {
                        let info = success.data;
                        info.uri = "/app/getImage?id=" + info.id;
                        info.__isAttachment = true;
                        let idx = _.findIndex (images, { id: info.id })
                        if (idx == -1) images.splice (0,0,info);
                        else{
                            info.uri = info.uri + "&__random=" + uuid ();
                            images.splice (idx,1,info);
                        }
                        setImages (_.cloneDeep (images));
                        schema.getParent().__backdrop__ ({ isOpen: false});
                        schema.setPropertyValue (images);
                    }, (failure)=> {
                        schema.getParent().__backdrop__ ({ isOpen: false});
                        console.log (failure)
                        setError ("File not uploaded ... Something went wrong")
                    }));
            }
        });
    }
    useEffect (()=>{
        _.isArray (schema.getPropertyValue ()) && setImages (schema.getPropertyValue ()); 
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(images).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setImages (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const handleAction = (type, item) => {
        if (type == "edit"){
            setEditing (item);
        }
        if (type == "delete"){
            onDelete (item);
        }
    }

    const onDelete = async (image) => {
        try {
            schema.getParent().__confirmation__ ({
                title: "Warning",  
                message: "Are sure you want to delete this image?",
                events: [{ 
                    label: "Cancel"
                },  {
                    label: "Yes, Please",
                    onClick: () => {
                        _.set (_.find(images, {id: image.id}), '__hasDeleted', true);
                        let final = _.cloneDeep (images);
                        setImages (final);
                        schema.getParent ().__setValue__ (schema.__name, final);
                    }
                }]
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    const onSave = (file, id) => {
        onUpload (file, id);
        setEditing (null);
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                <Box sx={{width: '100%'}}>
                    {schema.settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom component='div'>{schema.settings.label}</Typography>}
                    <pre><Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                            {schema.settings.help || ""}
                            {schema.settings.helplink && 
                                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                </IconButton>
                            }
                        </Typography>
                    </pre>
                    {!schema.behaviour.readonly && <section className='container' style={{cursor: 'pointer'}}>
                        {<UploadModern uploadText="Drag n drop some files here, or click to select files" dropzone={dropzone}/>}
                        {error && <Typography sx={{fontSize: 12}} color="#ef5350" gutterBottom >{error}</Typography>}
                    </section>}
                    <ImageList variant={"standard"} cols={6} gaps={4}>
                        {images.map((item) => {
                            if (!item.__hasDeleted) return (<ImageItem key={item.id} item={item} schema={schema} onClick={handleAction}/>
                        )}
                    )}
                    </ImageList>
                </Box>
                <Drawer anchor={"right"} open={editing?true:false} disableEnforceFocus>
                    <AppBar position="sticky">
                        <Toolbar sx={{backgroundColor: "#FFF"}}>
                            <Typography sx={{ color: "#000", flexGrow: 1, }} variant="h4" component="span">Image Editor</Typography>
                            <Stack direction="row" spacing={0}>
                                <IconButton key={"close"} size={"xs"} sx={{color: "#B71C1C" }} onClick={() => setEditing (null) }>
                                    <Tooltip title={"Close"} placement="bottom"><FontAwesomeIcon icon={"fas fa-xmark"} /></Tooltip>
                                </IconButton>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                    {editing && <Box sx={{width: {"sm": "100%", "lg": "80vw"}, p:5}} role="presentation">
                        <Editor item={editing} onSave={onSave} onCancel={()=>setEditing (null)}/>
                    </Box>}
                </Drawer>
            </>}
        </>
    );
};

export default ImageEditorComponent;
ImageEditorComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};