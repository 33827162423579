import React from 'react';
import PropsTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as _ from "lodash";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const LayoutItem = ({item, schema}) => {
    return (
        <>
        {item && <Item>
            {
                schema.settings.action && 
                <Stack 
                    direction={schema.settings.action.direction || "row"} 
                    spacing={schema.settings.action.spacing || 2} 
                    alignItems={schema.settings.action.alignItems || "center"}>
                        <Typography noWrap sx={{ ml:5, mr: 5}}>{item.name}</Typography>
                        <Box sx={{ flexGrow: 1, }}/>
                        {schema.settings.action.actions.map ((action, index) => {
                            if (!action.hidden)
                            return (
                                <IconButton key={index}
                                    sx={{ color: action.icon.color || 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about`}
                                    onClick={() => { action.event && schema[action.event] && schema[action.event] (_.cloneDeep (item)) }}
                                >
                                    <Tooltip title={action.help || action.label} placement="top"><FontAwesomeIcon icon={action.icon.code} size={action.icon.size || "xs"}/></Tooltip>
                                </IconButton>
                            )
                        })}
                </Stack>
            }
        </Item>}
        </>
    );
};

export default LayoutItem;
LayoutItem.propTypes = {
  item: PropsTypes.object.isRequired,
  schema: PropsTypes.object.isRequired
};