import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import List from '@mui/material/List';
import {useAuthUser} from '@crema/utility/AuthHooks';

const HorizontalNav = () => {
  const {menu} = useAuthUser();

  return (
    <List className='navbarNav'>
      {menu?.map((item) => 
      (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && <HorizontalItem item={item} nestedLevel={0} />}
        </React.Fragment>
      )
      )}
    </List>
  );
};

export default HorizontalNav;
