import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppCard from '@crema/core/AppCard';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function InfoWidgetCard2 ({schema}) {
    const [data, setData] = useState({});
    useEffect (()=>{
      setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    useEffect (() => {
      if (schema.settings.__hasDataSourceUpdated && (schema.datasource || []).length >0){
        setData (schema.datasource[0]);
        schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
      }
    },[schema.datasource]);

    return (
        <>
            <Box sx={{width: '100%'}}>
                {schema.settings.label && <Typography variant='h5' gutterBottom component='div'>
                    {schema.settings.label}
                </Typography>}
                {schema.settings.help && <Typography variant='h6' gutterBottom component='div'>
                    {schema.settings.help}
                </Typography>}
            </Box>
            <AppCard
                sxStyle={{height: schema.settings?.height || 1, backgroundColor: schema.settings?.backgroundColor}}
                contentStyle={{px: 2}}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                    {data.image && <Avatar sx={{height: 80, width: 80}} src={data.image} />}
                    {data.label && <Box sx={{ whiteSpace: 'pre-line', pt: 3, mt: 2, mb:5, color:(theme) => `${theme.palette.primary.main}`}} component='h1'>{data.label}</Box>}
                    {data.help && <Box component='h6' sx={{ whiteSpace: 'pre-line', mb: 5, color:(theme) => `${theme.palette.secondary.main}`}}>{data.help}</Box>}
                    {data.info?.map ((info, i) => {
                        return (<Box component='h6' sx={{ whiteSpace: 'pre-line', mb: 1, color:(theme) => `${theme.palette.grey[600]}`}} key={i}>{info}</Box>)
                    })}
                </Box>
            </AppCard>
        </>
    )
}

InfoWidgetCard2.propTypes = {
    schema: PropTypes.object.isRequired
};