import React from 'react';
import { Registry } from 'react-registry';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { FormLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const Fieldset = ({fieldset, schema}) => {
    return (
        <Box sx={{display:"flex", mb:5, mt:0}}>
            {fieldset.settings?.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{fieldset.settings.label}</FormLabel>}
            <Box sx={{width: '100%', border: fieldset.settings?.label?1:0, borderRadius: 2, borderColor: 'grey.400', p:fieldset.settings?.label?5:0, pb:0}}> 
                <pre><Typography sx={{fontSize: 12, mb:5}} color="text.secondary" gutterBottom >
                    {fieldset.settings?.help || ""}
                    {fieldset.settings?.helplink && 
                        <IconButton size='small' href={fieldset.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                            <Tooltip title={fieldset.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                        </IconButton>
                    }
                </Typography></pre>
                {fieldset.fields?.map ((field) => {
                    if (schema.properties [field])
                    return (
                        <Box key={schema.properties [field].__id} sx={{mb:5}}> 
                            {Registry.createElement(schema.properties [field].widget, {schema: schema.properties [field], model: schema.properties [field].value})}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default Fieldset;

Fieldset.propTypes = {
    fieldset: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
};