import React, { useEffect, useState } from 'react';
import _ from "lodash";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Backdrop from '@mui/material/Backdrop';

const FabButtonComponent = ({schema}) => {
  const {theme} = useThemeContext();
  const [actions, setActions] = useState ([]);
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);

  useEffect (()=>{
    if (schema.settings.__hasSettingsUpdated && _.isArray (schema.settings.actions) && !_(actions).xorWith(schema.settings.actions, _.isEqual).isEmpty()){
      let actions = [];
      _.map (schema.settings.actions || [], (action)=>{
        if (action.icon && action.icon.code && !action.hidden){
          if (!action.icon.code.startsWith ("fas")) action.icon.code = "fas fa-" + action.icon.code;
          actions.unshift (
            <SpeedDialAction
              key={action.label + action.event}
              icon={<FontAwesomeIcon icon={action.icon.code} size={"lg"} color={action.style?.backgroundColor || "#F04F47"}/>}
              tooltipTitle={action.label}
              tooltipOpen
              sx={{ '.MuiSpeedDialAction-staticTooltipLabel':{ cursor: 'pointer', backgroundColor: "white", color: action.style?.backgroundColor || "#F04F47", whiteSpace: "nowrap", maxWidth: "none", }}}
              onClick={()=>{ schema [action.event] && schema [action.event] () }}
            />  
          )
        }
      });
      setActions (actions);
      // This is to hold the change in datasource every time
      schema.getParent ().__setSettings__ (schema.__name, {__hasSettingsUpdated: false});
    }
  }, [schema.settings]);

  return (
    <>
      {schema && !schema.behaviour?.hidden && 
      <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 16, right: 16 }}>
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={
            <SpeedDialIcon
              icon = {<FontAwesomeIcon 
                icon={!schema.settings.icon.close.startsWith ("fas")?"fas fa-" + schema.settings.icon.close:schema.settings.icon.close} 
                size={schema.settings.icon.size || "xl"} 
                color={schema.settings.icon.color || theme.palette.primary.contrastText}/>}
              openIcon = {<FontAwesomeIcon 
                icon={!schema.settings.icon.open.startsWith ("fas")?"fas fa-" + schema.settings.icon.open:schema.settings.icon.open} 
                size={schema.settings.icon.size || "xl"} 
                color={schema.settings.icon.color || theme.palette.primary.contrastText}/>}
            />}
            onClick={handleClick}
            open={open}
        >
          {actions}
        </SpeedDial>
      </Box>
    }</>
  );
}

export default FabButtonComponent;

FabButtonComponent.propTypes = {
  schema: PropTypes.object.isRequired,
};
