import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Label from '../Label';
import Help from '../Help';
import Placeholder from '../Placeholder';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import Variant from '../Variant';

const DropdownWidget = ({settings, onChange}) => {
    const onPropertyChange = (name, value) => {
        settings[name]=value;
        onChange (settings);
    }

    return (
        <>
            <Box sx={{m:1, width: '100%'}}>
                <Typography variant='h5' gutterBottom component='div'>
                Dropdown Widget Settings
                </Typography>
                <Typography variant='h6' gutterBottom component='div'>
                Downdown widget properties, which will be applied on the widget
                </Typography>
            </Box>
            <Label value={settings.label || "SET_ME"} onChange={onPropertyChange}/>
            <Help value={settings.help || ""} onChange={onPropertyChange}/>
            <Placeholder value={settings.placeholder || ""} onChange={onPropertyChange}/>
            <Variant value={settings.variant || ""} onChange={onPropertyChange}/>
            <Divider />
            <Typography sx={{fontSize: 14}} gutterBottom>
                Datasource Configuration
            </Typography>
            <Typography sx={{fontSize: 12}} color='text.secondary' gutterBottom>
                Datasource is the options displayed in the dropdown. Options can be populated as enumeration or fixed type or via API, for typeahead please choose typeahead type of widget
            </Typography>
            <Box sx={{width: "100%", mt:3, p:3, border: 1,borderRadius: 2, borderColor: 'grey.400'}}>
                <FormLabel component='legend'>Typeahead / Ondemand loading</FormLabel>
                <FormControlLabel sx={{mt:2}} control={
                    <Switch size='small' 
                        checked={settings.lookup || false}
                        onChange={(event)=>onPropertyChange ([event.target.name], event.target.checked)}
                        name='lookup'
                    />} 
                    label='Is lookup?'
                />  
                <FormHelperText>Switching this parameter ON will call the API with every character typed (min 3 char)</FormHelperText>
            </Box>
        </>
    )
}

export default DropdownWidget;

DropdownWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
  };
