import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import SlideItem from './SlideItem';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as _ from "lodash";
import MediaSlider from './MediaSlider';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const data = [
  {
    id: 1,
    srcImg: '/assets/images/slick-slider/carousel-1.jpg',
    title:
      "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    description:
      'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
  },
  {
    id: 3,
    srcImg: '/assets/images/slick-slider/carousel-3.jpg',
    title:
      "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    description:
      'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
  },
  {
    id: 4,
    srcImg: '/assets/images/slick-slider/carousel-5.jpg',
    title:
      "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    description:
      'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
  },
  {
    id: 2,
    srcImg: '/assets/images/slick-slider/carousel-2.jpg',
    title:
      "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    description:
      'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
  },
  {
    id: 5,
    srcImg: '/assets/images/slick-slider/carousel-6.jpg',
    title:
      "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    description:
      'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
  },
];

const SlideOneComponent = ({schema}) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  useEffect (()=> {
      setTimeout (()=> {  schema.onInit && schema.onInit ((data) => _.isArray(data) && setSlides (data));  }, 100);
      if (schema.datasource instanceof Array) setSlides (schema.datasource);
      if (schema.href){
          let url = _.isObject (schema.href)?"/api/"+schema.href.id:schema.href;
          dispatch (callService (url, null, (success) => {
              if (_.isArray(success.data)){
                  setSlides (success.data);
              }
          }, (failure)=> {
              console.log (failure)
          }));
      }
      setSlides (data)
  }, []);

  useEffect (()=>{
    if (_.isArray (schema.datasource) && schema.settings.__hasDataSourceUpdated && !_(slides).xorWith(schema.datasource, _.isEqual).isEmpty()){
        setSlides (schema.datasource);
        schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
      }
  }, [schema]);

  return (
    <MediaSlider>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <SlideItem key={index} slide={slide} />
        ))}
      </Slider>
    </MediaSlider>
  );
}

export default SlideOneComponent;

SlideOneComponent.propTypes = {
  schema: PropTypes.object.isRequired,
};
