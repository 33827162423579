import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as _ from "lodash";

const ToggleButtonComponent = ({schema}) => {
    const [value, setValue] = useState ("");
    const [options, setOptions] = useState([]);
    useEffect (()=>{
        setValue (schema.getPropertyValue () || schema.settings?.default || "");
        if (_.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty())
            setOptions (schema.datasource);
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);

    useEffect (()=>{
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(options).xorWith(schema.datasource, _.isEqual).isEmpty()){
            schema.datasource = _.uniqBy (schema.datasource, "id");
            setOptions (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    }, [schema.datasource]);
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                {schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>{schema.settings.label}{schema.behaviour?.mandatory?"*":""}</Typography>}
                <ToggleButtonGroup color="primary" aria-label="Toggle" exclusive
                    value={value} 
                    onChange={(e,v)=> {
                        v = v || "";
                        schema.setPropertyValue (v);
                        setValue (v)
                        schema.getParent().__isValid__ ();
                        if (schema.onChange) schema.onChange();
                    }}
                    disabled={schema.behaviour?.readonly}
                >
                    {options.map ((option) => {
                        return (
                            <ToggleButton  value={option.id} key={option.id}>
                                <Tooltip title={option.help || option.name } placement="top" arrow>
                                    <span>{option.name}{option.icon && "  "}{option.icon && <FontAwesomeIcon icon={option.icon.code} size={"sm"}/>}</span>
                                </Tooltip>
                            </ToggleButton>
                        )
                    })
                    }
                </ToggleButtonGroup>
                {(schema.settings?.help || schema.error) && <pre>
                    <Typography sx={{fontSize: 12, mt: 2, mb:5}} color={(schema.error)?"#ef5350":"text.secondary"} gutterBottom component='div'>
                        {(schema.settings.help || "") + "\n" + (schema.error||"")}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top" arrow><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>}
            </>}
        </>
    )
}

export default ToggleButtonComponent;

ToggleButtonComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};