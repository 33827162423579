import { GOOGLE_MAPS_API_KEY } from 'shared/constants/ActionTypes';
  
const initialState = {
  googleMapApiKey: "AIzaSyCpJn_uxEmOBICjJ6h3HomiRvhoiOWRE7c"
};
  
const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_MAPS_API_KEY:
      return {
        ...state,
        googleMapApiKey: action.googleMapApiKey,
      };
    default:
      return state;
  }
};
export default contentReducer;
  
  