import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import {fabric} from "fabric";
import './styles.css';

const deleteIconSrc = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

const DrawingModule = ({schema}) => {
    const fabricContainer = useRef();
    const [canvas, setCanvas] = useState (null);
    const [deleteIcon, setDeleteIcon] = useState (null);
    
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
        const deleteIcon = document.createElement('img');
        deleteIcon.src = deleteIconSrc;
        setDeleteIcon (deleteIcon)
        return () => { try {canvas && canvas.dispose ()}catch (e){ console.log (e);} }
    }, []);

    useEffect (()=>{
        const canvas = new fabric.Canvas(fabricContainer.current, {selection: false});
        canvas.setWidth (1280);
        canvas.setHeight (720);
        canvas.renderAll();
        setCanvas (canvas);
    }, [fabricContainer]);

    useEffect (() => {
        if (!canvas) return;
        canvas.on('mouse:wheel', onMouseWheel);
        canvas.on('mouse:down', onMouseDown);
        canvas.on('mouse:move', onMouseMove);
        canvas.on('mouse:up', onMouseUp);
        // canvas.on('object:scaling', onScaling);
        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5, y: -0.5, offsetY: -16, offsetX: 16, cursorStyle: 'pointer', mouseUpHandler: onDelete, render: renderIcon
        });
    }, [canvas]);

    const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
        var size = 24; ctx.save(); ctx.translate(left, top); ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle)); ctx.drawImage(deleteIcon, -size/2, -size/2, size, size); ctx.restore();
    }

    const onDelete = () => {
		const target = canvas.getActiveObjects ();
        canvas.discardActiveObject();
        canvas.remove(...target);
	}

    const onMouseWheel = (opt) => {
        var delta = opt.e.deltaY;
        var zoom = canvas.getZoom();
        zoom = zoom - delta * 0.01;
        opt.e.preventDefault();
        opt.e.stopPropagation();
        if (zoom >= 20) zoom = 20;
        if (zoom <= 1) zoom = 1;
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        canvas.forEachObject(function(o) { o.setCoords(); });
    }

    const onMouseDown = (opt) => {
        var evt = opt.e;
        if (evt.altKey === true) { canvas.isDragging = true; canvas.selection = false; canvas.lastPosX = evt.clientX; canvas.lastPosY = evt.clientY; }
    }

    const onMouseMove = (opt) => {
        if (canvas.isDragging) {
            var e = opt.e;
            var vpt = canvas.viewportTransform;
            vpt[4] += e.clientX - canvas.lastPosX;
            vpt[5] += e.clientY - canvas.lastPosY;
            canvas.requestRenderAll();
            canvas.lastPosX = e.clientX;
            canvas.lastPosY = e.clientY;
        }
    }

    const onMouseUp = () => {
        canvas.setViewportTransform(canvas.viewportTransform);
        canvas.isDragging = false;
        canvas.selection = true;
    }

    const onAddRectangle = () => {
        canvas.add(new fabric.Rect({ left: 40, top: 40, width: 50, height: 50, objectCaching: false, fill: 'transparent', stroke: 'black', strokeWidth: 1, strokeUniform: true }));
    }

    const onAddCircle = () => {
        canvas.add(new fabric.Circle({ radius: 20, fill: 'transparent', stroke: 'black', strokeWidth: 1, left: 100, top: 100, strokeUniform: true }));
    }

    const onAddTriangle = () => {
        canvas.add(new fabric.Triangle({ width: 50, height: 50, fill: 'transparent', stroke: 'black', strokeWidth: 1, left: 100, top: 100, strokeUniform: true }));
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget);};
    const handleClose = (event) => { 
        setAnchorEl (null);
        const {value} = event.currentTarget.dataset;
        if (!value) return;
        switch (value){
            case "rect":
                onAddRectangle ();
                break;
            case "circle":
                onAddCircle ();
                break;
            case "triangle":
                onAddTriangle ();
                break;
        }
    };

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <Box>
                <Typography sx={{fontSize: 14, mb:schema.settings?.help?1:5}} color='text.secondary' gutterBottom component='div'>
                    {schema.settings.label || ""}
                </Typography>
                <pre>
                    <Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                        {schema.settings.help || ""}
                        {schema.settings.helplink && 
                            <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top" arrow><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>
                <Box sx={{ border: 1, borderColor: "#ECEFF1"}} width={1280}>
                    <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} alignItems={"flex-end"} sx={{ marginTop: "5px", marginRight:"5px", zIndex: 9999}} >
                        <IconButton variant='contained' onClick={handleClick} sx={{ backgroundColor: "#303F9F"}} >
                            <Tooltip title={"Add Shapes to the Canvas"} placement="top" key={"add"} arrow>
                                <FontAwesomeIcon icon={"fas fa-plus"} size={"xs"} color="white"/>
                            </Tooltip>
                        </IconButton>
                    </Stack>
                    <canvas ref={ fabricContainer }/>
                </Box>
                <Menu id='option-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'basic-button'}}
                >
                    <MenuItem key={"circle"} data-value={"circle"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-circle-dashed"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Circle</ListItemText>
                    </MenuItem>
                    <MenuItem key={"rect"} data-value={"rect"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-rectangles-mixed"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Rectangle</ListItemText>
                    </MenuItem>
                    <MenuItem key={"triangle"} data-value={"triangle"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-triangle"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Triangle</ListItemText>
                    </MenuItem>
                    <MenuItem key={"polygon"} data-value={"polygon"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-vector-polygon"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Polygon</ListItemText>
                    </MenuItem>
                    <MenuItem key={"image"} data-value={"image"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-image"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Image</ListItemText>
                    </MenuItem>
                    <MenuItem key={"draw"} data-value={"draw"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-paintbrush-pencil"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Free Draw</ListItemText>
                    </MenuItem>
                    <MenuItem key={"text"} data-value={"text"} onClick={handleClose}>
                        <ListItemIcon><FontAwesomeIcon icon={"fas fa-text-size"} size={"lg"}/></ListItemIcon>
                        <ListItemText>Text</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
            }
        </>
    )
}

export default DrawingModule;

DrawingModule.propTypes = {
    schema: PropTypes.object.isRequired,
};