import React, { useEffect, useState } from 'react';
import Editor from "@monaco-editor/react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const CustomComponent = ({schema}) => {
    const [value, setValue] = useState({});
    useEffect (() => {
        if (!schema.validator) schema.validator = () => {
            schema.error = "";
            return schema.error?false:true;
        }
        if (_.isObject (schema.getPropertyValue()))
            setValue (schema.getPropertyValue());
        else setValue ({ script: " " });
    }, [schema]);
    useEffect (() => {
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
        return () => {
            provider?.dispose ();
        }
    }, []);
    const onChange = (newValue) => {
        value.script = newValue;
        setValue (value);
        schema.setPropertyValue (value);
    }
    const handleEditorValidation = (markers) => {
        value.errors = [];
        markers.forEach(marker => {
            value.errors.push (marker.message);
        });
        setValue (value);
        schema.setPropertyValue (value);
        if (value.errors.length>0){
            schema.getParent ().__setError__ (schema.__name, "Error in the configuration");
        } else schema.getParent ().__setError__ (schema.__name, null);
    }

    const generateSuggestion = (monaco) => {
        let suggestions=[];
        (schema.datasource || []).forEach ((option)=>{
            suggestions.push ({
                label: option.name + " (" + option.id + ")",
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: "{{" + option.id + "}} {# " + option.name + " #}"
            });
        })
        return { suggestions: suggestions };
    }

    let provider;
    const handleEditorDidMount = (editor, monaco) => {
        provider?.dispose ();
        if (schema.settings.language)
        provider = monaco.languages.registerCompletionItemProvider(schema.settings.language, {
            provideCompletionItems: () => generateSuggestion (monaco)
        });
    }

    return (
        <>
            {schema.settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                {schema.settings.label}
            </Typography>}
            {(schema.error || schema.settings.help) && <Typography sx={{fontSize: 12}} color={(schema.error || (value?.errors||[]).length>0)?"red":"text.secondary"} gutterBottom >
                {(schema.settings.help || "") + ((schema.error || (value?.errors||[]).length>0)? ".. Compilation Error occured, please fix":"") }
                {schema.settings.helplink && 
                    <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                        <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                    </IconButton>
                }
            </Typography>}
            {schema && 
            <Box m={1} >
                <Editor
                    theme={schema.settings.theme || "vs-dark"}
                    language={schema.settings.language}
                    value={value.script}
                    height={schema.settings.height || "80vh"}
                    options = {{
                        minimap: { enabled: false },
                        readOnly: schema.behaviour?.readonly 
                    }}
                    onValidate={handleEditorValidation}
                    onChange={onChange}
                    onMount={handleEditorDidMount}
                />
                {schema.error && [schema.error].map ((error, index) => {
                    try{
                        error = JSON.stringify(JSON.parse (error), null, 2);
                    } catch (e){ // Do nothing
                    }
                    return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
                })}
                {(value.errors || []).map ((error, index) => {
                    return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
                })}
            </Box>}
        </>
    )
}

export default CustomComponent;
CustomComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};