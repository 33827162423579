import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import EventDetail from './event';
import * as _ from "lodash";
import EventConfig from './config';
import uuid from 'react-uuid';
// import dayjs from 'dayjs';

const localizer = momentLocalizer(moment);
const BigCalendarComponent = ({schema}) => {
    const [date, setDate] = useState (new Date());
    const [view, setView] = useState (Views.MONTH);
    const [boundary, setBoundary] = useState ({});
    const [holidays, setHolidays] = useState ([]);
    const [weekend, setWeekend] = useState ([]);
    const [settings, setSettings] = useState ({});
    const [config, setConfig] = useState ({});
    const [open, setOpen] = useState (false);
    const [events, setEvents] = useState([])
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);

    useEffect (()=>{
        if (schema.settings.__hasSettingsUpdated){
            let _holidays = []
            _.map (schema.settings.holidays, (date)=>{
                _holidays.push (moment.utc(date).format("L"));
            });
            setHolidays (_holidays);
            setWeekend (schema.settings.weekend);
            setSettings (schema.settings);
            _.map (schema.settings.events, (e)=>{ e.start = moment.utc(e.start).local().toDate(), e.end = moment.utc(e.end).local().toDate() });
            setEvents (schema.settings.events);
            if (schema.settings.defaultDate){
                let date = moment.utc (schema.settings.defaultDate).local ();
                if (!moment ().isBefore (date)) date = moment ();
                setDate (date.toDate ());
            }
            schema.settings.defaultView && setView (schema.settings.defaultView)
            if (schema.settings.boundary){ 
                let start = moment.utc (schema.settings.boundary.start).local ();
                if (!moment ().isBefore (start)) start = moment ();
                let end = moment.utc (schema.settings.boundary.end).local ();
                setBoundary ({ start: start.toDate(), end: end.toDate() });
            }
            // This is to hold the change in datasource every time
            schema.getParent ().__setSettings__ (schema.__name, {__hasSettingsUpdated: false});
        }
    }, [schema.settings]);

    const checkOverlap = useCallback ((start, end) => {
        if (_.has (settings, "allowOverlap") && !settings.allowOverlap){
            let found = _.findIndex (events, (event) => { 
                return event.updatable && ((moment (start).isAfter (moment (event.start)) && moment (start).isBefore (moment (event.end))) || 
                (moment (end).isAfter (moment (event.start)) && moment (end).isBefore (moment (event.end))) || 
                (moment (start).isSameOrBefore (moment (event.start)) && moment (end).isSameOrAfter (moment (event.end))))
            });
            if (found !== -1){
                schema.getParent ().__snackbar__ ({open: true, message: "Overlapping slots not allowed"});
                return true;
            }
        }
        return false;
    }, [settings, events]);

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            if ((weekend).includes (moment(start).day()) && !settings.allowWeekend) return;
            if ((holidays).includes (moment(start).format("L")) && !settings.allowHoliday) return;
            if (moment(start).isBefore (moment().subtract(1, 'days'))) return;
            if (settings.maxEvents <= _.filter(events,"updatable").length){ 
                schema.getParent ().__snackbar__ ({open: true, message: "Maximum " + settings.maxEvents + " event(s) is allowed"})
                return;
            }
            if (boundary.start && boundary.end){
                if (moment (start).isBefore (moment (boundary.start))){
                    schema.getParent ().__snackbar__ ({open: true, message: "Past Start DateTime event is not allowed"});
                    return;
                }
                if (settings.checkBoundary &&  moment (end).isAfter (moment (boundary.end))){
                    schema.getParent ().__snackbar__ ({open: true, message: "Future End DateTime event is not allowed"});
                    return;
                }
            }
            if (checkOverlap (start, end)) return;

            setConfig ({ id: uuid(), start: start, end: end, updatable: true, duration: settings.duration });
            setOpen (true);
    }, [events, settings, boundary])

    const handleSelectEvent = useCallback((event) => {
        setConfig (event);
        setOpen (true);
    }, [])

    const eventStyleGetter = useCallback((event) => {
        let color = "#1976D2";
        if (event.updatable) color = "#00796B";
        if (event.loacked) color = "#D32F2F";
        var style = { backgroundColor: color, display: 'block'};
        return { style: style };
    }, []);

    const slotStyleGetter  = useCallback(
        (date) => ({
            className: 'slotDefault',
                ...(moment(date).hour() < 8 && {
                    style: { backgroundColor: 'powderblue', color: 'black', },
                }),
                ...(moment(date).hour() > 16 && {
                    style: { backgroundColor: 'powderblue', color: 'black', },
                }),
            }),
    []);
    
    const dayStyleGetter = useCallback(
        (date) => ({
            ...((weekend).includes (moment(date).day()) && { style: settings.weekendStyle || { backgroundColor: '#e6e6e6' } }),
            ...((holidays).includes (moment(date).format("L")) && { style: settings.holidayStyle || { backgroundColor: '#F04F47' } }),
            ...((moment(date).isBefore (moment().subtract(1, 'days'))) && { style: { backgroundColor: '#e6e6e6' } })
        }),
    [holidays]);

    const updateEvent = (cancelledId) => {
        if (cancelledId){
            _.remove (events, (n) => n.id == cancelledId);
            setEvents (_.cloneDeep (events));
            schema.setPropertyValue (_.filter(events,"updatable"));
            return;
        }
        config.end = moment (config.start).add (config.duration, "hour").toDate ();
        if (checkOverlap (config.start, config.end)) return;
        let idx = _.findIndex(events, { 'id': config.id });
        if (idx == -1) events.push (config)
        else events.splice (idx, 1, config);
        setEvents (_.cloneDeep (events));
        schema.setPropertyValue (_.filter(events,"updatable"));
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <Box sx={{width: '100%'}}>
                {settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom component='div'>{settings.label}</Typography>}
                <pre><Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                        {settings.help || ""}
                        {settings.helplink && 
                            <IconButton size='small' href={settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                <Tooltip title={settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                            </IconButton>
                        }
                    </Typography>
                </pre>
                <Calendar
                    defaultView={schema.settings.defaultView || Views.MONTH} 
                    view={view}
                    onView={setView}
                    date={date}
                    onNavigate={(date) => setDate (moment (date).toDate ())}
                    views={ {month: true, week: true, day: true} }
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    selectable
                    style={{ height: "70vh" }}
                    components={{
                        event: EventDetail,
                    }}
                    eventPropGetter={eventStyleGetter}
                    slotPropGetter={slotStyleGetter}
                    dayPropGetter={dayStyleGetter}
                    step={60}
                    timeslots={1}
                />
                <EventConfig open={open} setOpen={setOpen} config={config} setConfig={setConfig} settings={settings} updateEvent={updateEvent} schema={schema}/>
            </Box>}
        </>
    )
}

export default BigCalendarComponent;

BigCalendarComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};
