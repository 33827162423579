import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const TimeComponent = ({schema}) => {
    const ref = useRef();
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);   
    }, []);
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <LocalizationProvider dateAdapter={AdapterDayjs} ref={ref}>
                <TimePicker
                    label={schema.settings.label}
                    value={schema.getPropertyValue()}
                    disabled={schema.behaviour?.readonly}
                    onChange={(newValue) => {
                        schema.setPropertyValue (newValue);
                        schema.getParent().__isValid__ ();
                        if (schema.onChange) schema.onChange();
                    }}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            sx={{
                                width: '100%',
                                position: 'relative',
                                transition: 'all 0.5s ease'
                            }}
                            
                            value={schema.getPropertyValue()}
                            variant={schema.settings.variant || 'outlined'}
                            label={schema.settings.label}
                            placeholder={schema.settings.placeholder}
                            helperText={(schema.settings.help || "") + (schema.error || "")}
                            required={schema.behaviour?.mandatory}
                            error={schema.error?true:false}
                        />
                    }
                />
            </LocalizationProvider>}
        </>
    )
}

export default TimeComponent;

TimeComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};