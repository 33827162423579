import React, { useState } from 'react';
import orange from '@mui/material/colors/orange';
import {Box} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import {useAuthMethod, useAuthUser} from '@crema/utility/AuthHooks';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import _ from "lodash";

const UserInfo = ({ color }) => {
  const { updateUser } = useAuthMethod();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{ py: 3, px: 3, display: 'flex', alignItems: 'center', cursor: 'pointer', }}
        className='user-info-view'
      >
        {user?.avatar && 
        <Box sx={{ py: 0.5 }}>
          <Avatar  sx={{ height: 40, width: 40, fontSize: 24, backgroundColor: orange[500], }}
            src={user.avatar}
          />
        </Box>
        }
        <Box sx={{ width: { xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)' }, ml: 4, color: color, }}
          className='user-info'
        >
          <Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >
            <Box sx={{ mb: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: 16, fontWeight: Fonts.MEDIUM, color: 'inherit', }}
              component='span' >
              {user?.name}
            </Box>
            <Box sx={{ ml: 3, color: 'inherit', display: 'flex', }} >
              <ExpandMoreIcon />
            </Box>
          </Box>
          <Box sx={{ mt: -0.5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'inherit', overflow: 'hidden', }}>
            {user.description}
          </Box>
        </Box>
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
      >
        {user.id !== "0" &&
          <MenuItem onClick={() => { handleClose(); navigate('/page/getPartyProfilePageSpecification'); }}>My Profile</MenuItem>
        }
        {user.id !== "0" &&
          <MenuItem onClick={() => { handleClose(); updateUser (_.assign ({}, user, { status: -3 })); }} >Sign Out</MenuItem>
        }
        {user.id === "0" &&
          <MenuItem onClick={() => { handleClose(); updateUser (_.assign ({}, user, { status: -1 })); }} >Sign In</MenuItem>
        }
        {user.id === "0" && 
          <MenuItem onClick={() => { handleClose(); updateUser (_.assign ({}, user, { status: -2 })); }} >Register</MenuItem>
        }
      </Menu>
    </>
  );
};

export default UserInfo;

UserInfo.defaultProps = {
  color: 'text.secondary',
};

UserInfo.propTypes = {
  color: PropTypes.string,
};
