import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import PropTypes from 'prop-types';

export default function Behaviour ({behaviour, onChange}) {
  const handleChange = (event) => {
    onChange ({behaviour: Object.assign (behaviour, {[event.target.name]: event.target.checked})});
  }
  return (
    <Box sx={{width: "100%", mt:3, p:3, border: 1,borderRadius: 2, borderColor: 'grey.400'}}>
      <FormLabel component='legend'>Attribute Behaviour</FormLabel>
      <FormGroup aria-label='position' row sx={{mt:2}}>
        <FormControlLabel control={
          <Switch size='small' 
            checked={behaviour?.mandatory}
            onChange={handleChange}
            name='mandatory'
          />} 
          label='Is Mandatory?'
        />
        <FormControlLabel control={
          <Switch size='small' color="default"
          checked={behaviour?.readonly}
            onChange={handleChange}
          />} 
          label='Is Readonly?'
          name='readonly'
        />
        <FormControlLabel control={
          <Switch size='small' color="warning"
            checked={behaviour?.hidden}
            onChange={handleChange}
          />} 
          label='Is Hidden?'
          name='hidden'
        />
      </FormGroup>
      <FormHelperText>Switch on/off to change attribute behaviour</FormHelperText>
    </Box>
  );
}

Behaviour.propTypes = {
  behaviour: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};