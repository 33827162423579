import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import {useThemeContext} from '@crema/utility/AppContextProvider/ThemeContextProvider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@mui/material/Link';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';

const styles = require('shared/vendors/mapStyles.json')

const SimpleMap = ({data, schema}) => {
    const googleMapApiKey = useSelector(({content}) => content.googleMapApiKey);
    const [position, setPosition] = useState ("");
    const [center] = useState (schema.settings?.map?.center || { lat: -37.7006775, lng: 144.7995576});
    const {theme} = useThemeContext();
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapApiKey,
        libraries: ["places", "drawing"]
    });

    return (
        isLoaded ? (
            <GoogleMap
                mapContainerStyle={schema.settings?.map?.style || { width: '100%', height: '80vh'}}
                center={center}
                zoom={schema.settings?.map?.zoom || 10}
                options={{
                    disableDefaultUI: true, // disable default map UI
                    draggable: true, // make map draggable
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    styles: Object.assign (styles, schema.settings?.styles || []) // change default map styles
                }}
            >
                <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
                    {(clusterer) => data.map((d) => {
                        if (d.location) return (
                            <Marker
                                position={{lat: Number(d.location[1]), lng: Number(d.location[0])}}
                                key={d.id}
                                clusterer={clusterer}
                                onClick={() => setPosition (d)}

                            >
                                {position?.id === d.id && <InfoWindow 
                                    key={position.id}
                                    options={{closeBoxURL: ``, enableEventPropagation: true}} onCloseClick={() => setPosition ("")}
                                    position={{lat: Number(position.location[1]), lng: Number(position.location[0])}}>
                                    <ListItem alignItems='flex-start'>
                                        {position.profile && 
                                        <ListItemAvatar>
                                            <Tooltip title={position.profile.name} key={position.profile.id}>
                                                <Avatar alt={position.profile.name} src={position.profile.avatar || position.profile.name.toUpperCase()} />
                                            </Tooltip>
                                        </ListItemAvatar>}
                                        <ListItemText
                                            sx={{ '.newline': { "whiteSpace": "pre-wrap", "wordWrap": "break-word" }, }}
                                            primary={schema.settings.linkTo?
                                                <a href={schema.settings.linkTo + position.id }>{position.label}</a>:
                                                <Link component='button' onClick={() => { schema.onClick && schema.onClick (data); }}>{position.label}</Link>}
                                            secondary={<>
                                                    {position.modifiedOn && <span className='newline' style={{fontSize: 11, color:theme.palette.text.disabled}}>{ moment.utc(position.modifiedOn).format('D/MM/YYYY, h:mm:ss a').split(',') + "\n" }</span>}
                                                    {position.description && <span className='newline' style={{color:theme.palette.secondary.main}}>{schema.settings.item?.description?.icon?.code && <><FontAwesomeIcon color={theme.palette.secondary.main} icon={"fas fa-"+schema.settings.item.description.icon.code}/>&nbsp;</>}<span className='newline'>{position.description + "\n\n"}</span></span>}
                                                    {position.help && <span className='newline' style={{color:theme.palette.primary.main}}>{schema.settings.item?.help?.icon?.code && <><FontAwesomeIcon color={theme.palette.primary.main} icon={"fas fa-"+schema.settings.item.help.icon.code}/>&nbsp;</>}<span className='newline'>{position.help + "\n"}</span></span>}
                                                    {position.moreInfo?.map ((d, i) => <span className='newline' style={{fontSize: 11, color:theme.palette.text.primary}} key={i}>{d + "\n"}</span>)}
                                                </>
                                            }
                                        />
                                        
                                        {position.image && <Box sx={{ xs: -1, xl: -2, height: {xs: 140}, display: 'flex', flexDirection: 'column', 
                                                alignItems: 'center', justifyContent: 'top', '& img': { maxHeight: '100%', maxWidth: '100%', overflow:"hidden"}, }}>
                                            <img src={position.image} loading="lazy" 
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src="/app/getImage?id=404";
                                                }} />
                                        </Box>}
                                    </ListItem>
                                </InfoWindow>}
                            </Marker>
                        )}
                    )}
                </MarkerClusterer>
            </GoogleMap>
        ) : <></>
  );
};

export default SimpleMap;

SimpleMap.propTypes = {
  data: PropTypes.array,
  schema: PropTypes.object.isRequired,
};
