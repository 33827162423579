import React from 'react';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        paddingLeft: theme.spacing(2),
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        [`& .${treeItemClasses.content}`]: {},
    },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const {
        bgColor,
        color,
        labelIcon,
        labelText,
        data,
        onClick,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, }} onClick={()=>{
                        data && onClick && onClick(data)
                    }}>
                    {labelIcon && (
                        <Box component='span' color="inherit">
                            <IconButton size='small' color="inherit"><FontAwesomeIcon icon={labelIcon}/></IconButton>
                        </Box>
                    )}
                    <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }}>{labelText}</Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});
StyledTreeItem.propTypes = {
    bgColor:PropTypes.any,
    color:PropTypes.any,
    labelIcon:PropTypes.any,
    labelText:PropTypes.any,
    data:PropTypes.any,
    onClick: PropTypes.func
};

export default function SimpleTreeItem ({item, onClick}) {
    return (
        <StyledTreeItem
            nodeId = {item.id}
            labelText={item.label}
            labelIcon={item.icon}
            color={item.color}
            bgColor={item.bgColor}
            data={item.data}
            onClick={onClick}
        >
            {item.children?.map((child) => {
                return (<SimpleTreeItem item={child} key={child.id} onClick={onClick}/>)
            })}
        </StyledTreeItem>
    );
}

SimpleTreeItem.propTypes = {
    item: PropTypes.any,
    onClick: PropTypes.func
};