import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { FormLabel } from '@mui/material';

const SwitchComponent = ({schema}) => {
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                {!schema.settings?.labelPlacement && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings?.label || ""}</FormLabel>}
                <Box sx={{width: '100%', border: (!schema.settings?.variant || schema.settings?.variant=="outlined")?1:0, borderRadius: 2, borderColor: 'grey.400', mt:5, p:3, pl:5, pr:5}}>
                    <FormControlLabel 
                        control={
                            <Switch size='small' 
                                checked={schema.getPropertyValue()==""?false:schema.getPropertyValue()}
                                disabled={schema.behaviour?.readonly}
                                onChange={(e) => {
                                    schema.setPropertyValue (e.target.checked);
                                    if (schema.onChange) schema.onChange();
                                }}
                            />
                        } 
                        label={schema.settings?.placeholder || schema.settings?.label}
                        labelPlacement={schema.settings?.labelPlacement}
                    />
                    <FormHelperText sx={{color: (schema.error)?"#ef5350":null}}>{schema.settings?.help + " " + (schema.error||"")}</FormHelperText>
                </Box>
            </>}
        </>
    )
}

export default SwitchComponent;

SwitchComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};