import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Registry } from 'react-registry';
import Selection from '../Stepper/Selection';
import * as _ from "lodash";
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import SyncIcon from '@mui/icons-material/Sync';

export default function Section ({schema}) {
    const [loaded, setLoaded] = useState (false);
    const [auto, setAuto] = useState(false);
    const [manual, setManual] = useState(false);
    const dispatch = useDispatch();

    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();}, 100);
        organizeSubforms ();
    }, []);

    useEffect (()=>{
        let missing = validateCardinality (schema.settings.items, schema.properties);
        schema.getParent().__setSettings__ (schema.__name, schema.settings);
        setTimeout (()=> {  loaded && missing.length>0 && organizeSubforms (); }, 100);
    }, [schema.properties.length]);

    const organizeSubforms = () => {
        // Preparing the forms based on the cardinality
        if (_.isArray (schema.settings.items) && schema.settings.items.length>0){
            // If it is preloaded then skip to organize
            if (schema.settings.items[0].id) organize (_.cloneDeep (schema.settings.items));
            else { // Loading the schema ids
                dispatch (callService (schema.settings.href.getName, {
                    model: _.map(schema.settings.items, "name")
                }, (success) => {
                    if (_.isArray(success.data)){
                        let its = _.values (_.merge(_.keyBy(schema.settings.items, 'name'), _.keyBy(success.data, 'identifier')));
                        organize (its);
                        setLoaded (true);
                    }
                }, (failure)=> { console.log (failure) }
                ));
            }
        }
    }

    const organize = (its) => {
        let missing = validateCardinality (its, schema.properties);
        let toBePreLoaded = _.map (missing, (o)=> o.id);
        _.remove (schema.settings.items);
        its.forEach ((i) => schema.settings.items.push (i));
        if (toBePreLoaded.length>0) handleSelection (toBePreLoaded, its);
        else schema.doRefresh ();
    }

    const validateCardinality = (its, props) => {
        let missing = [];
        its.map ((i) => {
            let pc = _.filter (props || [], (c) => c.identifier == i.identifier).length;
            let missingCount = i.cardinality.min - pc;
            // Missing count is based on cardanality
            if ((i.cardinality.min || 0) > pc) missing.push ({
                id: i.id,
                name: i.name,
                missing: missingCount,
                cardinality: i.cardinality 
            });
            // Check Maximum
            i.disabled = (i.cardinality.max <= pc || i.cardinality?.max === 0);
        });
        setAuto (missing.length>0);
        setManual (_.filter (its, (i)=>!i.disabled).length>0);
        return missing;
    }


    const handleSelection = (id, its=(schema.settings.items||[])) => {
        if (schema.getSchema) schema.getSchema (id, (sch) => { loadSchema (id, its, sch ) });
        else dispatch (callService (schema.settings.href.getSchema, { model: _.isArray(id)?id:[id] }, 
            (response) => { loadSchema (id, its, response.data) }));
    }

    const loadSchema = (id, its, response) => {
        let sch = _.cloneDeep (schema);
        (_.isArray(id)?id:[id]).map ((id) => {
            // This is a patch to keep the order as database in query was changing the order
            let s = _.find (response, ["id", id]);
            if (!s) return;
            if (!sch.properties) sch.properties = [];
            let i = _.find (its, { identifier: s.identifier});
            let p = _.filter (sch.properties, (c) => c.identifier == s.identifier).length;
            // Check min number reached
            if (i.cardinality?.min > 0 && p < i.cardinality?.min)
                _.times (i.cardinality?.min - p, () => sch.properties.push (s));
            else sch.properties.push (s);

            p = _.filter (sch.properties, (c) => c.identifier == s.identifier).length;
            // No check missing based on configured value
            if (_.isArray (schema.getPropertyValue()) && schema.getPropertyValue().length > 0){
                let noOfValues = _.filter (schema.getPropertyValue(), (c) => _.has (c, s.identifier)).length;
                if (noOfValues > p) _.times (noOfValues - p, () => sch.properties.push (s));
            }
        });
        schema.__addItem__ (sch.properties);
        setTimeout (()=>{schema.onChange && schema.onChange ()}, 100);
    }

    return (
        <>
            {schema && !schema.behaviour?.hidden &&
            <Box sx={schema.settings?.style || {width: '100%', mt:5, p:3, pl:5, pr:5}}>
                {(schema.settings?.help || schema.error) &&
                    <pre><Typography sx={{fontSize: 12}} gutterBottom color={(schema.error)?"#ef5350":"text.secondary"}>
                        {(schema.settings.help || "") || (schema.error || "")}
                    </Typography></pre>
                }
                {auto && 
                <Button startIcon={<SyncIcon />} sx={{float: 'left'}} 
                    aria-controls='subform-menu'
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={organizeSubforms}
                >Auto Organize</Button>}
                {manual && <Selection items={schema.settings.items} handleSelection={handleSelection}/>}
                <Box sx={{width:"100%"}}>
                    {schema.properties?.map((prop) => {
                        prop.settings.showLabel = false;
                        return (<Box key={prop.__id}>
                            {Registry.createElement(prop.widget, {schema: prop, model: prop.value})}
                        </Box>)
                    })}
                </Box>
            </Box>}
        </>
    );
}

Section.propTypes = {
  schema: PropTypes.object.isRequired,
};