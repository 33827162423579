import React, { useEffect, useState, useRef, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import './styles.css';
import ResizeModule from "@botom/quill-resize-module";
import { ImageDrop } from 'quill-image-drop-module';
import uuid from 'react-uuid';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";

// Bullet Styling
const Parchment = Quill.import('parchment')
const customFontFamilyAttributor = new Parchment.Attributor.Style('custom-family-attributor', 'font-family')
const customSizeAttributor = new Parchment.Attributor.Style('custom-size-attributor', 'font-size')
const customColorAttributor = new Parchment.Attributor.Style('custom-color-attributor', 'color')
const ListItemBlot = Quill.import('formats/list/item')

class CustomListItem extends ListItemBlot {
  optimize(context) {
    super.optimize(context);
    if (this.children.length >= 1) {
      const child = this.children.head
      const attributes = child?.attributes?.attributes
      if (attributes) {
        for (const key in attributes) {
          const element = attributes[key]
          let name = element.keyName
          const value = element.value(child.domNode)

          if (name === 'color') super.format('custom-color-attributor', value)
          else if (name === 'font-family') super.format('custom-family-attributor', value)
          else if (name === 'font-size') super.format('custom-size-attributor', value)
        }
      } else {
        super.format('custom-color-attributor', false)
        super.format('custom-family-attributor', false)
        super.format('custom-size-attributor', false)
      }
    }
  }
}

Quill.register(customColorAttributor, true);
Quill.register(customFontFamilyAttributor, true);
Quill.register(customSizeAttributor, true);
Quill.register(CustomListItem, true);

// Table Binding

const Font = Quill.import('formats/font');
Font.whitelist = ['arial',  'calibri',  'courier', 'georgia', 'lucida', 'open', 'roboto',  'tahoma',  'times',  'trebuchet',  'verdana' ];
const fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = ['Normal', '12px','14px','16px','18px','20px','22px','24px','26px','28px','30px','36px','42px','48px','56px','64px','72px','80px','100px','150px','200px'];
Quill.register(fontSizeStyle, true);
Quill.register(Font, true);
Quill.register("modules/resize", ResizeModule);
Quill.register('modules/imageDrop', ImageDrop);

const IMAGEATTRIBUTES = [ 'alt', 'height', 'width', 'style' ];
const ParchmentEmbed = Quill.import('blots/block/embed');
class ImageWithStyle extends ParchmentEmbed {
    static value(domNode) { 
        let val = domNode.getAttribute('src');
        return val + (val.includes ("?")?"&":"#")+ uuid (); 
    }
    static create(value) {
        let node = super.create(value);
        if (typeof value === 'string') node.setAttribute('src', value);
        return node;
    }

    static formats(domNode) {
        return IMAGEATTRIBUTES.reduce(function(formats, attribute) {
        if (domNode.hasAttribute(attribute)) formats[attribute] = domNode.getAttribute(attribute);
        return formats;
        }, {});
    }
    format(name, value) {
        if (IMAGEATTRIBUTES.indexOf(name) > -1) {
            if (value) this.domNode.setAttribute(name, value);
            else this.domNode.removeAttribute(name);
        } else super.format(name, value);
    }
}
ImageWithStyle.blotName = 'image';
ImageWithStyle.tagName = 'IMG';
Quill.register(ImageWithStyle, true);

const VIDEOATTRIBUTES = [ 'height', 'width', 'style' ];
class VideoBlot extends ParchmentEmbed {
    static value(node) { return node.getAttribute('src'); }
    static create(url) {
        let node = super.create();
        node.setAttribute('src', url);
        // Set non-format related attributes with static values
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        return node;
    }
  
    static formats(domNode) {
        return VIDEOATTRIBUTES.reduce(function(formats, attribute) {
          if (domNode.hasAttribute(attribute)) formats[attribute] = domNode.getAttribute(attribute);
          return formats;
        }, {});
    }
    
    format(name, value) {
        if (VIDEOATTRIBUTES.indexOf(name) > -1) {
            if (value) this.domNode.setAttribute(name, value);
            else this.domNode.removeAttribute(name);
        } else super.format(name, value);
    }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
Quill.register(VideoBlot, true);

// Emoji 
Quill.register({
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    }, true);


// const formats = [
//     "header", "font", "size",
//     "bold", "italic", "underline", "strike", "blockquote", "code-block",
//     "color", "background", "float",
//     "align", "code", "script",
//     "list", "bullet", "indent",
//     "link", "image", "video",
//     "width", "height", "style"
// ];
const QuillComponent = ({schema}) => {
    const [value, setValue] = useState("");
    const quill = useRef();
    useEffect (() => {
        setValue (schema.getPropertyValue() || "");
        setTimeout (()=> {  schema.onInit && schema.onInit ((update)=>setValue (update));  }, 100);
    }, []);
    
    const onChange = (content) => {
        setValue (content);
        schema.setPropertyValue (content);
    }

    const imageHandler = () => {
        if (!quill.current) return
        const editor = quill.current.getEditor();
        const tooltip = editor.theme.tooltip;
        const originalSave = tooltip.save;
        const originalHide = tooltip.hide;
        tooltip.save = function() {
          const range = editor.getSelection(true);
          const value = this.textbox.value;
          if (value) {
            editor.insertEmbed(range.index, 'image', value, 'user');
          }
        };
        // Called on hide and save.
        tooltip.hide = function () {
           tooltip.save = originalSave;
           tooltip.hide = originalHide;
           tooltip.hide();
        };
        tooltip.edit('image');
        tooltip.textbox.placeholder = "Embed URL";
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ header: 1 }, { header: 2 }, { font: Font.whitelist }, { size: fontSizeStyle.whitelist }],
                    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    [{ script: "sub" }, { script: "super" }],
                    ["emoji"],
                    ['clean']
                ],
                handlers: {
                    image: imageHandler
                },
            },
              
            resize: {
                toolbar: {
                  alingTools: true,
                },
                locale: {
                    altTip: "Hold down the alt key to zoom",
                    floatLeft: "Left",
                    floatRight: "Right",
                    center: "Center",
                    restore: "Restore",
                },
            },
            imageDrop: true,
            "emoji-toolbar": true,
        }),
        [],
    )
    
    return (
        <>
            {!schema.behaviour?.hidden && 
                <Box m={1}>
                    {schema.settings.help && 
                        <Typography sx={{fontSize: 12}} color='text.secondary' gutterBottom>
                            {schema.settings.help} 
                            {schema.settings.helplink && 
                                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                </IconButton>
                            }
                        </Typography>
                    }
                    <Box sx={(!schema.behaviour.readonly)?{
                        border: 1,
                        borderRadius: 1,
                        borderColor: 'grey.500'
                    }:null}>
                        <ReactQuill theme="snow" 
                            ref={quill}
                            value={value} 
                            readOnly={schema.behaviour.readonly}
                            onChange={onChange} 
                            modules={(!schema.behaviour.readonly)?modules:{ toolbar: false}}
                            // formats={formats}
                        />
                    </Box>
                </Box>
            }
        </>
    )
}

export default QuillComponent;
QuillComponent.propTypes = {
    schema: PropTypes.object.isRequired,
    model: PropTypes.any
};

