import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import * as _ from "lodash";
import uuid from 'react-uuid';

function EditToolbar({handleAdd}) {
  const handleClick = () => handleAdd({ id: uuid()});
  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export default function Enumeration ({value, onChange}) {  
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows (value || []);
  }, [value]);
  const handleAdd = (row) => onChange ({datasource: _.concat (rows, [row])})
  const handleDelete = (id) => {
    value = _.cloneDeep(value).filter((row) => row.id !== id)
    onChange ({datasource: value})
  }
  const handleChange = (newRow) => {
    value = _.map(_.cloneDeep(value), function(a) {
      return a.id === newRow.id ? newRow : a;
    });
    onChange ({datasource: value})
    return newRow;
  };
  const columns = [
    { field: 'name', headerName: 'Key / Name', flex: 1, editable: true },
    { field: 'description', headerName: 'Display Text', flex: 1, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Delete Row',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key="delete"
            icon={<DeleteIcon />}
            label="Delete"
            color="inherit"
            onClick={() => handleDelete (params.row?.id)}
          />,
        ];
      },
    }
  ];
  return (
    <Box sx={{width: "100%", height:"50vh", mt:3, p:3, pb:15, border: 1,borderRadius: 2, borderColor: 'grey.400'}}>
      <Typography variant='h5' gutterBottom color='text.secondary'>
      Static Datasource / Enumeration
      </Typography>
      <Typography variant='h6' gutterBottom color='text.secondary'>
      Please add Key/Name which will pass as value of the datasource and Display text; which will used for display. Please note, key should be unique
      </Typography>
      <DataGridPro
        rows={rows}
        columns={columns}
        rowHeight={38}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={handleChange}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { handleAdd },
        }}
      />
    </Box>
  );
}

Enumeration.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array
};