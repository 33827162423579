import axios from 'axios';

const plainAxios = axios.create({
  baseURL: "/app/",
  headers: {
    'Content-Type': 'application/json'
  },
});

plainAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  },
);

export default plainAxios;
