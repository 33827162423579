import React from 'react';
import PropsTypes from 'prop-types';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

const ImageItem = ({item, schema, onClick}) => {
    return (
        <ImageListItem>
            <img
                src={item.uri}
                alt={item.alt || "Default"}
                loading="lazy"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/app/getImage?id=404";
                }}
            />
            {!schema.behaviour.readonly && <ImageListItemBar
                actionIcon={schema.settings.controls && 
                    <Stack 
                        direction={"row"} 
                        spacing={2} 
                        justifyContent={"center"} 
                        alignItems={"center"}>
                        {schema.settings.controls.map ((action, index) => {
                            return (
                                <IconButton key={index}
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about`}
                                    onClick={() => onClick (action.type, item)}
                                >
                                    <Tooltip title={action.help || action.label} placement="top"><FontAwesomeIcon icon={action.icon.code} size={action.icon.size || "xs"}/></Tooltip>
                                </IconButton>
                            )
                        })}
                    </Stack>
                }
            />}
        </ImageListItem>
    );
};

export default ImageItem;
ImageItem.propTypes = {
  item: PropsTypes.object.isRequired,
  schema: PropsTypes.object.isRequired,
  onClick: PropsTypes.func.isRequired
};