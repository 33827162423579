import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Fieldset from './fieldset';
import * as _ from "lodash";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const ObjectComponent = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);
    return (
        <>
            {schema.settings && !schema.behaviour?.hidden &&
                <>
                    {(!_.has (schema.settings, "showLabel") || schema.settings?.showLabel) && schema.settings?.label && 
                    <Typography sx={{fontSize: 14, mb:schema.settings?.help?1:5}} color='text.secondary' gutterBottom component='div'>
                        {schema.settings.label || ""}
                    </Typography>}
                    {schema.settings?.help && <pre>
                        <Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                            {schema.settings.help || ""}
                            {schema.settings.helplink && 
                                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                </IconButton>
                            }
                        </Typography>
                    </pre>}
                </>
            }
            {!schema.behaviour?.hidden && schema.fieldsets?.map((fieldset, index) => {
                return (
                    <Fieldset fieldset={fieldset} schema={schema} key={schema.__id + "-" + index} />    
                )
            })}
        </>
    )
}

export default ObjectComponent;

ObjectComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};