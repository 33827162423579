import React from 'react';
import { Registry } from 'react-registry';
import ObjectComponent from "./container/Object";
import GridLayoutContainer from './container/Grid';

import StringComponent from "./input/TextField";
import NumberComponent from './input/NumberField';
import ButtonComponent from "./input/button/Sidebar";
import FabButtonComponent from './input/button/fab';
import BasicGrid from "./datagrid/BasicGrid";
import EditableGrid from "./datagrid/EditableGrid";
import HierarchicalGrid from './datagrid/HierarchicalGrid';
import BasicTreeComponent from './datagrid/TreeView/Selection';
import LazyGrid from './datagrid/LazyGrid';
import WidgetComponent from "./WidgetComponent";
import EditorComponent from "./editor/EditorComponent";
import ServiceComponent from "./editor/ServiceComponent";
// import BlocklyComponent from "./editor/BlocklyComponent";
import CustomComponent from "./editor/CustomComponent";
import BasicDropdownComponent from "./input/dropdown/Basic";
import PaperComponent from "./display/surface/Paper";
import TextPad from "./display/surface/TextPad";
import TabComponent from "./navigation/TabComponent";
import SearchBar from "./input/SearchBar";
import VerticalLinearStepper from "./navigation/stepper/verticallinear"
// import HorizontalLinearStepper from './navigation/stepper/horizontallinear';
import PageNavigation from './navigation/Pagination';
import SwitchComponent from "./input/Switch/basic";
import SimpleList from "./display/list/Simple";
import SlideOneComponent from "./thirdparty/slick/WithBottomText";
import Layout from "./looknfeel/Layout";
import Theme from "./looknfeel/Themes";
import NotificationBar from './display/feedback/NotificationBar';
// import BasicMap from './thirdparty/map/BasicMap';
import DrawingMap from './thirdparty/map/Drawing';
import BasicChart from './thirdparty/chart/BasicChart';
import DateComponent from './input/datetime/Date';
import TimeComponent from './input/datetime/Time';
import CardType1 from "./display/cards/CardType1";
import InfoWidgetCard1 from './display/cards/InfoWidget1';
import PreviewImageUploadComponent from './thirdparty/image/Previews';
import BarcodeComponent from './input/Barcode';

import AddToListComponent from './input/AddToList';
import ButtonBarComponent from './input/button/bar';
import TimelineComponent from './display/timeline';
import InfoWidgetCard2 from './display/cards/InfoWidget2';
import SimpleUploadComponent from './thirdparty/image/SimpleUpload';
import QuillComponent from './thirdparty/richtext/Quill';
import ImagePortfolio from './thirdparty/image/portfolio';
import ImageDropdownComponent from './input/dropdown/Image';
import ReactImageGallery from './thirdparty/image/gallery/ReactImageGallery';
import CardSlider from './thirdparty/slider/CardSlider';
import HorizontalCard from './thirdparty/card/Horizontal';
import VerticalCard from './thirdparty/card/Vertical';
import JoditComponent from './thirdparty/richtext/Jodit';
import Scrumboard from './thirdparty/scrumboard';
import SimpleTree from './thirdparty/tree/Simple';
import ColorPicker from './input/ColorPicker';
import CommentComponent from './display/comment';
import BPMNModelerEditor from './editor/bpmn/modeler';
import BPMNViewer from './editor/bpmn/viewer';
import BasicTabView from './navigation/tabs/Basic';
import CardListView from './display/list/Card';
import AddressSearch from './thirdparty/map/AddressSearch';
import ActivityFormBuilder from './editor/activity/FormBuilder';
import ActivityServiceBuilder from './editor/activity/ServiceBuilder';
import AttributeBuilder from './editor/activity/AttributeBuilder';
// Subforms
import Subform from "./subform/Stepper";
import Array from "./subform/Array";
import Section from './subform/Flat';
import DrawerComponent from './subform/Drawer';
import LayoutOrganizer from './subform/LayoutOrganizer';
import LayoutRenderer from './subform/LayoutRenderer';
import BigCalendarComponent from './input/datetime/BigCalendar';
import ImageEditorComponent from './thirdparty/image/editor';
import ToggleButtonComponent from './input/button/ToggleButton';
import ListLayoutContainer from './container/list';
import GeometryModule from './thirdparty/drawing/3d';
import DrawingModule from './thirdparty/drawing/2d';
import FlowModule from './thirdparty/drawing/flow';

// Dynamic Container Layout
Registry.register(GridLayoutContainer, "layout.grid");
Registry.register(ListLayoutContainer, "layout.list");
Registry.register(ObjectComponent, "object");

// Subform Container
Registry.register(Subform, "subform");
Registry.register(Array, "array");
Registry.register(Section, "section");
Registry.register(DrawerComponent, "drawer");
Registry.register(LayoutOrganizer, "layout.organizer");
Registry.register(LayoutRenderer, "layout.renderer");

// Input Attributes
Registry.register(StringComponent, "string");
Registry.register(NumberComponent, "number");
Registry.register(DateComponent, "date");
Registry.register(TimeComponent, "time");
Registry.register(BigCalendarComponent, "calendar.scheduling");
Registry.register(ButtonComponent, "button");
Registry.register(FabButtonComponent, "button.fab");
Registry.register(ButtonBarComponent, "button.bar");
Registry.register(BasicGrid, "grid");
Registry.register(LazyGrid, "grid.lazy");
Registry.register(EditableGrid, "egrid");
Registry.register(HierarchicalGrid, "grid.hierarchical");
Registry.register(WidgetComponent, "widget");
Registry.register(SwitchComponent, "switch");
Registry.register(SearchBar, "search");
Registry.register(AddToListComponent, "addToList");
Registry.register(ColorPicker, "colorPicker");
Registry.register(ToggleButtonComponent, "button.toggle");

// Dropdown Variation
Registry.register(BasicDropdownComponent, "dropdown");
Registry.register(ImageDropdownComponent, "dropdown.image");

// Navigation
Registry.register(TabComponent, "tab");
Registry.register(BasicTabView, "tabs.basic");
// Navigation Stepper
Registry.register(VerticalLinearStepper, "navigation.stepper.vertical");
// Registry.register(HorizontalLinearStepper, "navigation.stepper.horizontal");
Registry.register(PageNavigation, "navigation.pagination");
// Editors
Registry.register(EditorComponent, "editor");
Registry.register(ServiceComponent, "service");
// Registry.register(BlocklyComponent, "blockly");
Registry.register(ActivityFormBuilder, "activity.formbuilder");
Registry.register(ActivityServiceBuilder, "activity.servicebuilder");
Registry.register(AttributeBuilder, "activity.attributebuilder");
Registry.register(CustomComponent, "custom");
Registry.register(CustomComponent, "editor.custom");
Registry.register(BPMNModelerEditor, "editor.bpmn.modeler");
Registry.register(BPMNViewer, "editor.bpmn.viewer");

// Display Components
Registry.register(SimpleList, "display.list.modern");
Registry.register(NotificationBar, "display.feedback.notification");
Registry.register(BasicTreeComponent, "display.tree.selection");
Registry.register(TimelineComponent, "display.timeline");
Registry.register(CommentComponent, "display.comment");
Registry.register(CardListView, "display.list.card");

// Multiple Types if Information Card (Mainly used for summary)
Registry.register(CardType1, "display.card.type1");
Registry.register(InfoWidgetCard1, "display.card.infowidget1");
Registry.register(InfoWidgetCard2, "display.card.infowidget2");
// Surface Components
Registry.register(PaperComponent, "surface.paper");
Registry.register(TextPad, "surface.textpad");

// Look and Feel Component
Registry.register(Layout, "looknfeel.layout");
Registry.register(Theme, "looknfeel.theme");

// Third party - e.g. React Slick or carousel Components
// Registry.register(BasicMap, "thirdparty.map.basic");
Registry.register(AddressSearch, "thirdparty.map.search");
Registry.register(DrawingMap, "thirdparty.map.drawing");
Registry.register(BasicChart, "thirdparty.chart.basic");
Registry.register(SimpleUploadComponent, "thirdparty.file.preview");
Registry.register(BarcodeComponent, "thirdparty.barcode");
Registry.register(QuillComponent, "thirdparty.richtext.quill");
Registry.register(JoditComponent, "thirdparty.richtext.jodit");
Registry.register(ImagePortfolio, "thirdparty.image.portfolio");
Registry.register(ImageEditorComponent, "thirdparty.image.editor");
Registry.register(Scrumboard, "thirdparty.scrumboard");
Registry.register(SimpleTree, "thirdparty.tree");
Registry.register(GeometryModule, "thirdparty.drawing.2d");
Registry.register(DrawingModule, "thirdparty.drawing.3d");
Registry.register(FlowModule, "thirdparty.drawing.flow");

// Thirdpart images
Registry.register(PreviewImageUploadComponent, "thirdparty.image.preview");
Registry.register(SlideOneComponent, "thirdparty.slick.slideone");
Registry.register(ReactImageGallery, "thirdparty.image.image_gallery_01");
Registry.register(CardSlider, "thirdparty.slider.card_slider_01");

// Content Element
Registry.register(HorizontalCard, "thirdparty.card.card_section_01");
Registry.register(VerticalCard, "thirdparty.card.card_section_02");

const Dynamic = React.lazy(()=> import("."));

export const dynamicPagesConfigs = [
    {
      path: ['/page/:sid', "/page/:sid/:iid"],
      element: <Dynamic/>,
    }
];