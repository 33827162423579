import  plainAxios  from '../request/index';

export const get = (url) => {
  return plainAxios.get (url, {
    responseType: "blob"
  });
};

export const post = (url, body) => {
  return plainAxios.post(url, body);
};

export const put = (url, body) => {
  return plainAxios.post(url, body, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  });
};

export const getBaseURL = () => {
  return plainAxios.defaults.baseURL ;
};