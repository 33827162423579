import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import * as _ from "lodash";

const NumberComponent = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);     
    }, []);
    return (
        <>
            {schema && !schema.behaviour?.hidden && <TextField
                sx={{ width: '100%', position: 'relative', transition: 'all 0.5s ease' }}
                type="number"
                required={schema.behaviour?.mandatory}
                disabled={schema.behaviour?.readonly}
                id={schema.__id}
                label={schema.settings.label}
                helperText={(schema.settings.help || "") + (schema.error || "") }
                value={schema.getPropertyValue()}
                placeholder={schema.settings.placeholder || ""}
                onChange={(e) => {
                    let value = e.target.value;
                    if (_.isString (value) && value.length>0) value = Number (value);
                    else value = "";
                    schema.setPropertyValue (value);
                    schema.getParent().__isValid__ ();
                    if (schema.onChange) schema.onChange();
                }}
                variant={schema.settings.variant || 'outlined'}
                error={schema.error?true:false}
                inputProps={{ inputMode: 'numeric', max: schema.settings.max, min: schema.settings.min }}
            />}
        </>
    )
}

export default NumberComponent;

NumberComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};