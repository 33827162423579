import React, { useEffect, useState } from 'react';
import {Card} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const VerticalCard = ({schema}) => {
  const [content, setContent] = useState();
  useEffect (()=>{
    setTimeout (() => { schema.onInit && schema.onInit ((data)=>{ setContent (data);  }); });
  }, []);
  return (
    <>
      {content && 
        <Card sx={{ maxWidth: 345, m:1 }}>
          {content.cover_image && <CardMedia
            sx={{ height: 140 }}
            image={content.cover_image}
            title={content.title}
            alt={content.title}
          />}
          <CardContent>
            {content.linkTo ? 
              <Link sx={{ mb: 2, }} href={content.linkTo} underline="hover">
                <Typography variant='h1' gutterBottom component='div' color="text.secondary">
                  {content.title}
                </Typography>
              </Link> :
              <Box sx={{ mb: 5, }} component='div'>
                <Typography variant='h1' gutterBottom component='div' color="text.secondary">
                  {content.title}
                </Typography>
              </Box>
            }
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', }} >
              <Box component='p'>{content.description}</Box>
            </Box>
          </CardContent>
        </Card>
      }
    </>
  );
};

export default VerticalCard;

VerticalCard.propTypes = {
  schema: PropTypes.object.isRequired
};
