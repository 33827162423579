import {
    BACKDROP, SKELETON, SNACKBAR, CONFIRMATION
  } from 'shared/constants/ActionTypes';
  
  const initialState = {
    backdrop: {
      isOpen:false
    },
    confirmation: {
      isOpen:false
    },
    skeleton: [],
    snackbar: {}
  };
  
  const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
      case BACKDROP:
        return {
          ...state,
          backdrop: action.payload,
        };
      case SKELETON:
          return {
            ...state,
            skeleton: action.payload,
          };
      case SNACKBAR:
        return {
          ...state,
          snackbar: action.payload,
        };
      case CONFIRMATION:
        return {
          ...state,
          confirmation: action.payload,
        };
    
      default:
        return state;
    }
  };
  export default feedbackReducer;
  