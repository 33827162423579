import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import {toggleNavCollapsed} from 'redux/actions';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch} from 'react-redux';
import AppNotifications from '../../../AppNotifications';
import AppLogo from '../../components/AppLogo';

const AppHeader = () => {
  const dispatch = useDispatch();
  return (
    <AppBar
      position='relative'
      color='inherit'
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: 'background.paper',
        width: {
          xs: '100%',
        },
      }}
      className='app-bar'
    >
      <Toolbar
        sx={{
          boxSizing: 'border-box',
          minHeight: {xs: 56, sm: 70},
          paddingLeft: {xs: 5},
          paddingRight: {xs: 5, md: 7.5, xl: 12.5},
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{color: 'text.secondary'}}
            edge='start'
            className='menu-btn'
            color='inherit'
            aria-label='open drawer'
            onClick={() => dispatch(toggleNavCollapsed())}
            size='large'
          >
            <MenuIcon sx={{ width: 35, height: 35, }} />
          </IconButton>
        </Hidden>
        <AppLogo />
        <Box sx={{ flexGrow: 1, }} />
        <Box sx={{ml: 4}}>
          <Hidden smDown>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: -2, marginRight: -2, }} >
              <Box sx={{ px: 1.85, }} >
                <AppNotifications />
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
