import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Settings from './settings';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function WidgetType({schema}) {
  const handleChange = (event) => { 
    schema.getParent ().__setError__ (schema.__name, null, true);
    schema.setPropertyValue({ widget: event.target.value }) 
  };

  const onChange = (data) => {
    schema.getParent ().__setError__ (schema.__name, null, true);
    if (data.errors?.length>0) schema.getParent ().__setError__ (schema.__name, "- (Invalid Attribute Configuration)", true);
    schema.setPropertyValue(data);
  }
  

  return (
    <>
      <FormControl required error={schema.error?true:false}
        sx={{ width: '100%', mt:3, position: 'relative', transition: 'all 0.5s ease' }}>
        <InputLabel>Select Widget Type</InputLabel>
        <Select
          labelId='type-select-helper-label'
          id="type-select-helper"
          value={schema.getPropertyValue()?.widget || ""}
          label="Select Widget Type"
          inputProps={{ name: 'widget', id: 'widgetSelection',}}
          onChange={handleChange}
          disabled={schema.behaviour?.readonly}
        >
          <MenuItem value={"standalone"}>Standalone</MenuItem>
          <MenuItem value={"composite"}>Composite</MenuItem>
        </Select>
        <FormHelperText>Widget Type is required to define the attribute</FormHelperText>
      </FormControl>
      {schema.getPropertyValue () && <Settings schema={schema.getPropertyValue ()} onChange={onChange} readonly={schema.behaviour?.readonly || false}/>}
      {schema.error && [schema.error].map ((error, index) => {
          return (<Typography variant='h6' key={index} gutterBottom  component='div' color="red"><pre>{error}</pre></Typography>)
      })}
    </>
  );
}

WidgetType.propTypes = {
  schema: PropTypes.object.isRequired,
};