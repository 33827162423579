import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import ThemeFooter from '@crema/core/AppThemeSetting/ThemeFooter';
import ThemeDirection from '@crema/core/AppThemeSetting/ThemeDirection';
import SidebarSettings from '@crema/core/AppThemeSetting/SidebarSettings';
import NavStyles from '@crema/core/AppThemeSetting/NavStyles';
import LayoutTypes from '@crema/core/AppThemeSetting/LayoutTypes';
import ThemeHeader from '@crema/core/AppThemeSetting/ThemeHeader';
import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { 
  // useLayoutActionsContext, 
  useLayoutContext } from '@crema/utility/AppContextProvider/LayoutContextProvider';
// import { useSidebarActionsContext } from '@crema/utility/AppContextProvider/SidebarContextProvider';
// import { useThemeActionsContext, useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';

export default function Layout({schema}) {
  const layout = useLayoutContext();
  // const {
  //   setFooter,
  //   setFooterType,
  //   updateNavStyle,
  //   updateLayoutType,
  //   setHeaderType,
  // } = useLayoutActionsContext();
  // const {updateSidebarBgImage, setSidebarBgImage, updateSidebarColorSet} = useSidebarActionsContext();
  // const {theme} = useThemeContext();
  // const {updateTheme} = useThemeActionsContext();
  useEffect (() => {
    if (!schema.getPropertyValue ()) schema.setPropertyValue (layout);
    else {
      // setFooter (schema.getPropertyValue ().footer);
      // setFooterType (schema.getPropertyValue ().footerType);
      // setHeaderType (schema.getPropertyValue ().headerType);
      // updateLayoutType (schema.getPropertyValue ().layoutType);
      // updateNavStyle (schema.getPropertyValue ().navStyle);
      // schema.getPropertyValue ().isSidebarBgImage && setSidebarBgImage (schema.getPropertyValue ().isSidebarBgImage);
      // schema.getPropertyValue ().sidebarBgImage && updateSidebarBgImage (schema.getPropertyValue ().sidebarBgImage);
      // if (schema.getPropertyValue ().themeDirection){
      //   theme.direction = schema.getPropertyValue ().themeDirection;
      //   updateTheme({...theme});
      // }
      // schema.getPropertyValue ().sidebarColors && updateSidebarColorSet (schema.getPropertyValue ().sidebarColors)
    }
  }, [])

  const onChange = (change) => {
    schema.setPropertyValue (Object.assign ({}, schema.getPropertyValue (), change))
    schema.onChange && schema.onChange ();
  }

  return (
    <>
    {schema && !schema.behaviour?.hidden && 
    <Box sx={{mt:5}}>
      {schema.settings?.label && <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", backgroundColor: "#F4F7FE" }}>{schema.settings.label}</FormLabel>}
      <Box sx={{width: '100%', border: schema.settings?.label?1:0, borderRadius: 2, borderColor: 'grey.400', p:schema.settings?.label?5:0}}> 
          {schema.settings?.help && <pre><Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom >
              {schema.settings.help || ""}
          </Typography></pre>}
        <Box
          sx={{
            padding: {xs: '20px', xl: '28px 22px'},
          }}
        >
          <NavStyles changeHandler={onChange}/>
          <LayoutTypes changeHandler={onChange}/>
          <ThemeDirection changeHandler={onChange}/>
          <ThemeHeader changeHandler={onChange}/>
          <ThemeFooter changeHandler={onChange}/>
          <SidebarSettings changeHandler={onChange}/>
        </Box>
      </Box>
    </Box>}
    </>
  );
}

Layout.propTypes = {
  schema: PropTypes.object.isRequired
};
