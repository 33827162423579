
import * as Blockly from 'blockly';
import * as _ from "lodash";
import {javascriptGenerator} from 'blockly/javascript';
import JSON5 from 'json5';

Blockly.Blocks["schema"] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Specification")
        this.appendDummyInput()
            .appendField("Settings")
            .appendField(new Blockly.FieldMultilineInput(''), 'settings');
        this.appendDummyInput()
            .appendField("Behaviour")
            .appendField(new Blockly.FieldMultilineInput(''), 'behaviour');
        this.appendDummyInput()
            .appendField("Responsive?")
            .appendField(new Blockly.FieldCheckbox(false), 'responsive');
        this.appendDummyInput().appendField("Section(s)");
        this.appendStatementInput('section').setCheck(["object", "attribute", "array"]);
        this.appendDummyInput().appendField("Business Rule(s)");
        this.appendStatementInput('rules').setCheck(['rule']);
        this.setColour(300);
        this.setHelpUrl('/page/getContent/what_is_activity_schema');
    }
}

Blockly.Blocks["object"] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Section Name*")
            .appendField(new Blockly.FieldTextInput('', (value)=>{ return _.camelCase (value)}), 'name');
        this.appendDummyInput()
            .appendField("Settings")
            .appendField(new Blockly.FieldMultilineInput(''), 'settings');
        this.appendDummyInput()
            .appendField("Behaviour")
            .appendField(new Blockly.FieldMultilineInput(''), 'behaviour');
        this.appendDummyInput()
            .appendField("Responsive?")
            .appendField(new Blockly.FieldCheckbox(true), 'responsive')
            .appendField("Collapsible?")
            .appendField(new Blockly.FieldCheckbox(false), 'collapsible');
        this.appendDummyInput().appendField("Attribute(s) / Section(s)");
        this.appendStatementInput('section').setCheck(["attribute", "object", "array"]);
        this.setPreviousStatement (true, ["object", "attribute", "array"]);
        this.setNextStatement (true, ["object", "attribute", "array"]);
        this.appendDummyInput().appendField("Business Rule(s)");
        this.appendStatementInput('rules').setCheck(['rule']);
        this.setColour(200);
        this.setHelpUrl('/page/getContent/what_is_activity_object');
    }
}

Blockly.Blocks["array"] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Array of Sections");
        this.appendDummyInput()
            .appendField("Name")
            .appendField(new Blockly.FieldTextInput('', (value)=>{ return _.camelCase (value)}), 'name');
        this.appendDummyInput()
            .appendField("Settings")
            .appendField(new Blockly.FieldMultilineInput(''), 'settings');
        this.appendDummyInput()
            .appendField("Behaviour")
            .appendField(new Blockly.FieldMultilineInput(''), 'behaviour');
        this.appendDummyInput().appendField("Sub Section(s)");
        this.appendStatementInput('section').setCheck(["composite"]);
        this.setPreviousStatement (true, ["object", "attribute", "array"]);
        this.setNextStatement (true, ["object", "attribute", "array"]);
        this.setColour(250);
        this.setHelpUrl('/page/getContent/what_is_activity_array');
    }
}

const validation = (block) => {
    let value = block.getFieldValue ("name");
    if (value?.length ==0){
        block.setWarningText ("Please give a name");
        return "";
    }

    let settings = block.getFieldValue ("settings");
    try {
        if (settings) settings = JSON5.parse (settings);
        else settings =  {}
    } catch (e){
        block.setWarningText ("Invalid Settings, please use JSON format");
        return "";
    }

    let behaviour = block.getFieldValue ("behaviour");
    try {
        if (behaviour) behaviour = JSON5.parse (behaviour);
        else behaviour =  {}
    } catch (e){
        block.setWarningText ("Invalid Behaviour, please use JSON format (mandatory, readonly and hidden)");
        return "";
    }
    return { "name": value, settings: settings, behaviour: behaviour }
}

const validateSection = (block) => {
    let basic = validation (block);
    if (!basic) return "";
    let section = javascriptGenerator.statementToCode(block, 'section', javascriptGenerator.ORDER_NONE);
    if (!section){
        block.setWarningText ("Please attach attribute / there might be error in following attribute(s)");
        return "";
    }
    if (section.trim().endsWith (",")) section= "[" + section.trim().slice (0,-1) + "]";
    try{
        section = JSON5.parse (section);
    } catch (e){
        block.setWarningText ("Invalid Field structure");
        return "";
    }
    let result = _.uniqBy (section, 'name');
    if (result.length < section.length){
        block.setWarningText ("Duplicate fields are not allowed in same level, please create a section / give a name");
        return "";
    }
    
    // Rules Addition
    let rules = javascriptGenerator.statementToCode(block, 'rules', javascriptGenerator.ORDER_NONE);
    if (rules.trim().endsWith (",")){
        rules= "[" + rules.trim().slice (0,-1) + "]";
        try{
            rules = JSON5.parse (rules);
        } catch (e){
            block.setWarningText ("Invalid rules configuration");
            return "";
        }    
    }
    block.setWarningText (null);
    return _.assign (basic, { "type": "object", "sections": section, "rules": rules || [], responsive: block.getFieldValue ("responsive") });
}

const validateAttribute = (block) => {
    let identifier = block.getFieldValue ("identifier");
    if (!identifier){
        block.setWarningText ("Please select an attribute");
        return "";
    }
    let name = block.getFieldValue ("name");
    if (!name || name?.length == 0){
        name = identifier;
        block.setFieldValue (name, "name");
    }

    let attributeRef = block.getField ("identifier").getText();
    block.setFieldValue (attributeRef, "attributeRef");
    block.getField ("attributeRef").setTooltip (attributeRef);
    let field = block.getField ("attributeRef");
    field.setVisible (false);

    let output = validation (block);
    if (!output) return "";
    return _.assign (output, {attributeRef: attributeRef, type: "attribute"});
}

javascriptGenerator.forBlock ['schema'] = (block) => {
    let config = validateSection (block);
    if (!config) return "";
    config.type = "schema";
    delete config.name;
    return JSON.stringify (config) + ",";
};

javascriptGenerator.forBlock ['object'] = (block) => {
    let config = validateSection (block);
    if (!config) return "";
    config.collapsible = block.getFieldValue ("collapsible");
    return JSON.stringify (config) + ",";
};

javascriptGenerator.forBlock ['array'] = (block) => {
    let config = validateSection (block);
    if (!config) return "";
    config.type = "array";
    return JSON.stringify (config) + ",";
};

javascriptGenerator.forBlock ['attribute'] = function (block) {
    let basic = validateAttribute (block);
    if (!basic) return "";

    block.setWarningText (null);
    return JSON.stringify (_.assign (basic)) + ",";
};

javascriptGenerator.forBlock ['composite'] = function (block) {
    let basic = validateAttribute (block);
    if (!basic) return "";

    let min = block.getFieldValue ("min");
    let max = block.getFieldValue ("max");
    if (max < min){ max = 99; block.setFieldValue (max, "max") }

    block.setWarningText (null);
    return JSON.stringify (_.assign (basic, { cardinality: { min: min, max: max} })) + ",";
};

javascriptGenerator.forBlock ['rule'] = function (block) {
    let value = block.getFieldValue ("name");
    if (!value){
        block.setWarningText ("Please select a Rule");
        return "";
    }
    let event = block.getFieldValue ("event");
    if (!event || event?.length ==0){
        block.setWarningText ("Please select an event for the rule");
        return "";
    }

    let trigger = block.getFieldValue ("triggers");
    if (!trigger || trigger?.length ==0){
        block.setWarningText ("Please select a trigger attribute for the rule");
        return "";
    }
    block.setWarningText (null);
    value = JSON.stringify ({ id: value.trim(), event: event, trigger: _.split (trigger, ",") });
    return value + ",";
};

