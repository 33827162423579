import React, { useEffect, useState } from 'react';

import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import * as _ from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
        const currNode = queue.shift();
        if (currNode.id === targetId) {
        return currNode;
        }
        if (currNode.children) {
        queue.push(...currNode.children);
        }
    }
    return []; // Target node not found
};

const BasicTreeComponent = ({schema}) => {
  const [data, setData] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect (()=>{
      setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
      if (_.isArray (schema.getPropertyValue ()) && schema.getPropertyValue ().length>0)
        setSelectedNodes (schema.getPropertyValue ());
  }, []);

  useEffect (()=>{
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(data).xorWith(schema.datasource, _.isEqual).isEmpty()){
      schema.datasource = _.uniqBy (schema.datasource, "id");
      setData (schema.datasource);
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  }, [schema.datasource]);

  useEffect(() => {
    schema.setPropertyValue (selectedNodes);
  }, [selectedNodes]);

  // Retrieve all ids from node to his children's
  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) node.children.forEach((child) => getAllIds(child, idList));
    return idList;
  }
  // Get IDs of all children from specific node
  const getAllChild = (id) => {
      return getAllIds(bfsSearch(data, id));
  };

  // Get all father IDs from specific node
  const getAllFathers = (id, list = []) => {
      const node = bfsSearch(data, id);
      if (node.parent) {
        list.push(node.parent);
        return getAllFathers(node.parent, list);
      }

      return list;
  };

  function isAllChildrenChecked(node, list) {
      const allChild = getAllChild(node.id);
      const nodeIdIndex = allChild.indexOf(node.id);
      allChild.splice(nodeIdIndex, 1);

      return allChild.every((nodeId) =>
      selectedNodes.concat(list).includes(nodeId)
      );
  }

  const handleNodeSelect = (event, nodeId) => {
      event.stopPropagation();
      const allChild = getAllChild(nodeId);
      const fathers = getAllFathers(nodeId);
      let finalselection = []
      if (selectedNodes.includes(nodeId)) {
        // Need to de-check
        finalselection = selectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
      } else {
        // Need to check
        const ToBeChecked = allChild;
        for (let i = 0; i < fathers.length; ++i) {
          if (isAllChildrenChecked(bfsSearch(data, fathers[i]), ToBeChecked)) {
            ToBeChecked.push(fathers[i]);
          }
        }
        finalselection = [...selectedNodes].concat(ToBeChecked)
      }
      setSelectedNodes(finalselection);
      if (schema.onChange) schema.onChange ({
        name: schema.__name,
        nodes: finalselection
      });
  };

  const onTreeItemClick = (event) => {
      // prevent the click event from propagating to the checkbox
      event.stopPropagation();
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? _.map(data, 'id') : [],
    );
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelectClick = () => {
    _.mixin({
      toPairsDeep: obj => _.flatMap(
          _.toPairs(obj), ([k, v]) =>
              _.isObjectLike(v) ? _.toPairsDeep(v) : [[k, v]])
    });
    let finalselection = selectedNodes.length === 0 ? _(data)
      .toPairsDeep()
      .filter(([k]) => k === 'id')
      .map(1)
      .value() : []
    setSelectedNodes(finalselection);
    if (schema.onChange) schema.onChange ({
      name: schema.__name,
      nodes: finalselection
    });
  };

  const renderTree = (nodes) => (
      <TreeItem
          key={nodes.id}
          nodeId={nodes.id}
          onClick={onTreeItemClick}
          label={
              <>
                  <Checkbox
                      disabled={schema.behaviour?.readonly}
                      checked={selectedNodes.indexOf(nodes.id) !== -1}
                      tabIndex={-1}
                      onClick={(event) => handleNodeSelect(event, nodes.id)}
                  />
                  {nodes.name}
              </>
          }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
  );

  return (
    <>
      {schema && !schema.behaviour?.hidden && 
      <div>
        <Box sx={{width: '100%'}}>
          <Typography variant='h5' gutterBottom component='div'>
            {schema.settings.label}
          </Typography>
          {(schema.settings?.help || schema.error) && <pre>
              <Typography sx={{fontSize: 12}} color={(schema.error)?"#ef5350":"text.secondary"} gutterBottom >
                  {(schema.settings.help || "") + " " + (schema.error||"")}
              </Typography>
          </pre>}
        </Box>
        <Box sx={{ mb: 1 }}>
          <Button onClick={handleExpandClick}>
            {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
          </Button>
          {!schema.behaviour?.readonly &&
          <Button onClick={handleSelectClick}>
            {selectedNodes.length === 0 ? 'Select all' : 'Unselect all'}
          </Button>}
        </Box>
          <TreeView
            sx={{ maxHeight: schema.settings?.maxHeight || "50vh", flexGrow: 1, width: '100%', overflowY: 'auto' }}
            multiSelect
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
            selected={selectedNodes}
          >
              {data.map((node) => renderTree(node))}
          </TreeView>
      </div>}
      </>
  )
}

export default BasicTreeComponent;

BasicTreeComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};