import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import Header from './Header';
import AppsHeader from '@crema/core/AppsContainer/AppsHeader';
import AppsContent from '@crema/core/AppsContainer/AppsContent';
import {alpha, Box} from '@mui/material';
import {useThemeContext} from '@crema/utility/AppContextProvider/ThemeContextProvider';
import AppList from '@crema/core/AppList';
import SimpleListItem from './SimpleListItem';
import SimpleMap from './SimpleMap';
import SimpleGridItem from './SimpleGridItem';
import AppGrid from '@crema/core/AppGrid';
import ListEmptyResult from '@crema/core/AppList/ListEmptyResult';

const CardListView = ({schema}) => {
  const [data, setData] = useState ([]);
  const [modeView, setModeView] = useState(schema.settings.modeView || 'block');
  const {theme} = useThemeContext();
  useEffect(() => {
    setTimeout (()=>{schema.onInit && schema.onInit ()});
  }, []);
  useEffect (()=>{
    if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !(_(data).xorWith(schema.datasource, _.isEqual).isEmpty())){
      setData (schema.datasource);
      schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
    }
  }, [schema.datasource]);
  
  return (
    <>
      <AppsHeader>
        <Header viewType={modeView} onSearch={()=>{}} setModeView={setModeView}/>
      </AppsHeader>
      <AppsContent style={{backgroundColor: alpha(theme.palette.background.default, 0.6)}}>
        <Box sx={{ width: '100%', flex: 1, display: 'flex', height: 1, '& > div': { width: '100%', }, py: 2, px: 4,}}>
          {modeView === "grid" && (
            <AppGrid delay={200} responsive={{ xs: 1, sm: 2, xl: 3}} data={data} style={{overflow:"hidden"}}
              renderRow={(d, index) => <SimpleGridItem data={d} key={d.id || ""+index} schema={schema}/>}
              ListEmptyComponent={<ListEmptyResult content='No information found' />}
            />
          )}
          {modeView === "block" && (
            <AppList data={data} renderRow={(d, index) => 
              (<SimpleListItem data={d} key={d.id || ""+index} schema={schema}/>)
            }/>
          )}
          {modeView === "map" && (
            <SimpleMap data={data} schema={schema}/>
          )}
        </Box>
      </AppsContent>
    </>
  )
}

export default CardListView;

CardListView.propTypes = {
    schema: PropTypes.object.isRequired
};