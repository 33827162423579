import React from 'react';
import PropsTypes from 'prop-types';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

const ImageItem = ({item, schema}) => {
    return (
        <ImageListItem>
            <img
                src={item.url}
                alt={item.alt || "Default"}
                loading="lazy"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/app/getImage?id=404";
                }}
            />
            {schema.settings?.showDetails && <ImageListItemBar
                title={item.name || ""}
                subtitle={item.description || ""}
                actionIcon={
                    schema.settings.action && 
                    <Stack 
                        direction={schema.settings.action.direction || "row"} 
                        spacing={schema.settings.action.spacing || 2} 
                        justifyContent={schema.settings.action.justifyContent || "center"} 
                        alignItems={schema.settings.action.alignItems || "center"}>
                        {schema.settings.action.actions.map ((action, index) => {
                            if (!action.hidden)
                            return (
                                <IconButton key={index}
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about`}
                                    onClick={() => (action.event && schema[action.event] && schema[action.event] (item))}
                                >
                                    <Tooltip title={action.help || action.label} placement="top"><FontAwesomeIcon icon={action.icon.code} size={action.icon.size || "xs"}/></Tooltip>
                                </IconButton>
                            )
                        })}
                    </Stack>
                }
            />}
        </ImageListItem>
    );
};

export default ImageItem;
ImageItem.propTypes = {
  item: PropsTypes.object.isRequired,
  schema: PropsTypes.object.isRequired
};