import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import { callService } from 'redux/actions';
import Autocomplete from '@mui/material/Autocomplete';
import * as _ from "lodash";

export default function Href({value, onChange}) {
  const [option, setOptions] = useState([""]);
  const dispatch = useDispatch();
  useEffect (()=> {
    dispatch (callService ("/getListOfApIs", null, (success) => {
      if (_.isArray(success.data)) setOptions (success.data);
    }, (failure)=> {
        console.log (failure)
    }));
}, []);
  return (
    <Autocomplete
      id="href"
      options={option}
      getOptionLabel={(option) => option.name || option || ""}
      isOptionEqualToValue={(option, value) =>
          value === undefined || value === "" || option.id === value.id
      }
      value={value}
      onChange={(e, newValue) => onChange ({href: newValue})}
      renderInput={(params) => (
        <TextField
            sx={{
                width: '100%',
                mt:3,
                position: 'relative',
                transition: 'all 0.5s ease'
            }}
            {...params}
            variant="outlined"
            label="API Reference"
            placeholder="Select One"
            helperText="Load data using API, this will load the data at onInit of the dropdown"
        />
      )}
    />
  );
}

Href.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};