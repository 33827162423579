import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { Registry } from 'react-registry';
import IconButton from '@mui/material/IconButton';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabView = ({schema}) => {
  const [routes, setRoutes ] = useState([]);
  const [value, setValue] = useState(0);
  useEffect (()=>{
    let r = []
    schema.fieldsets.map ((fieldset) => {
      fieldset.fields.map ((field) => schema.properties [field] && r.push ({ key: field, title: schema.properties [field].settings?.title || "Unknown" }));
    })
    setRoutes (r);
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);

  
  const onTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {schema && !schema.behaviour?.hidden &&
      <Box sx={{ width: '100%', height: schema.settings?.height || "80vh", mb:5 }}>
        {schema.settings?.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom component='div'>
            {schema.settings.label}
        </Typography>}
        {schema.settings.help && <pre><Typography sx={{fontSize: 12}} color={(schema.error)?"#ef5350":"text.secondary"} gutterBottom component='div'>
            {schema.settings.help}
            {schema.settings.helplink && 
                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                </IconButton>
            }
        </Typography></pre>}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={onTabsChange} aria-label='basic tabs'>
            {(routes || []).map((route, index) => (
              <Tab label={route.title} key={schema.properties [route.key].__id} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {routes.map ((route, index) => {
            return (
              <TabPanel key={schema.properties [route.key].__id} index={index} value={value}> 
                {Registry.createElement(schema.properties [route.key].widget, {schema: schema.properties [route.key], model: schema.properties [route.key].value})}
              </TabPanel>
            )
          })
        }
      </Box>}
    </>
  );
};

export default BasicTabView;

BasicTabView.propTypes = {
  schema: PropTypes.object.isRequired,
};
