import * as React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export default function InputProps ({type, minLength, maxLength, min, max, onChange}) {
  return (
    <>
      { type == "string" && <><TextField 
        sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
        }}
        type="number"
        id='label'
        label='Min Length'
        variant='outlined'
        value={minLength}
        onChange={(e)=>onChange ("minLength", Number(e.target.value))}
        helperText="Minimum Length"
      /> 
      <TextField sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
        }}
        type="number"
        id='label'
        label='Max Length'
        variant='outlined'
        value={maxLength}
        onChange={(e)=>onChange ("maxLength", Number (e.target.value))}
        helperText="Maximum Length"
      /></>}
      { type == "number" && <><TextField 
        sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
        }}
        type="number"
        id='label'
        label='Min Value'
        variant='outlined'
        value={min}
        onChange={(e)=>onChange ("min", Number (e.target.value))}
        helperText="Minimum Value"
      /> 
      <TextField sx={{
          width: '100%',
          mt:3,
          position: 'relative',
          transition: 'all 0.5s ease'
        }}
        type="number"
        id='label'
        label='Max Value'
        variant='outlined'
        value={max}
        onChange={(e)=>onChange ("max", Number (e.target.value))}
        helperText="Maximum Value"
      /></>}
    </>
  );
}

InputProps.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  type: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};