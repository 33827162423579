import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import SimpleTreeItem from './SimpleTreeItem';


export default function SimpleTree ({schema}) {
    const [items, setItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    useEffect (()=>{
        setTimeout (() => { schema.onInit && schema.onInit (); });
    }, []);

    useEffect(() => {
        if (schema.settings.__hasDataSourceUpdated && _.isArray (schema.datasource) && !_(items).xorWith(schema.datasource, _.isEqual).isEmpty()){
            setItems (schema.datasource);
            schema.getParent ().__setSettings__ (schema.__name, {__hasDataSourceUpdated: false});
        }
    },[schema.datasource]);

    const onClick = (data) => {
        schema.onClick && schema.onClick (data);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <>
                <Box m={1}>
                    {schema.settings.help && 
                        <Typography sx={{fontSize: 12}} color='text.secondary' gutterBottom>
                            {schema.settings.help} 
                            {schema.settings.helplink && 
                                <IconButton size='small' href={schema.settings.helplink.linkTo} aria-label="help" target="_blank" edge='end'sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                                    <Tooltip title={schema.settings.helplink.tooltip || "User guide"} placement="top"><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                                </IconButton>
                            }
                        </Typography>
                    }
                </Box>
                <Box m={1}>
                <TreeView
                    aria-label="tree"
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    onNodeToggle={handleToggle}
                    expanded={expanded}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    sx={{ flexGrow: 1, overflowY: 'auto', mb:5 }}
                >
                    {items.map((item) => {
                        return (<SimpleTreeItem item={item} key={item.id} onClick={onClick}/>)
                    })}
                    </TreeView>
                </Box>
            </>
            }
        </>
    );
}

SimpleTree.propTypes = {
  schema: PropTypes.object.isRequired,
};