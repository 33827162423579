import CustomContextPad from "./CustomContextPad";
import CustomKeyboard from "./CustomKeyboard";
import CustomTaskPalette from './CustomTaskPalette';
import CustomTaskPad from "./CustomTaskPad";

export default {
    __init__: ["contextPad", "customKeyboard", "customTaskPad", "customTaskPalette"],
    contextPad: ["type", CustomContextPad],
    customKeyboard: ['type', CustomKeyboard],
    customTaskPad: ["type", CustomTaskPad],
    customTaskPalette: [ 'type', CustomTaskPalette ]
};