import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import JSEditor from './jseditor';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { red, pink } from '@mui/material/colors';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      background: isDragging ? "#0277BD" : null,
      // styles we need to apply on draggables
      ...draggableStyle
  }
};

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : null
});

export default function RuleComponent({rules, onAddRule, onUpdateRule, onDeleteRule, onInit, onReOrderRule, readonly=false}) {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[900],
    '&:hover': {
      backgroundColor: pink[700],
    },
  }));

  const onDragEnd = (result) => {
    if (!result.destination) return;
    onReOrderRule (reorder( rules, result.source.index, result.destination.index));
  }

  return (
    <>
      {!readonly && <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button variant="contained" color="primary" sx={{ height: 30 }}  startIcon={<AddIcon />} onClick={onAddRule}>Add Rule</Button>
      </Box>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Box 
            ref={provided.innerRef} 
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}>
              {rules?.map((rule, index) => {
                return (
                  <Draggable key={rule.id} draggableId={rule.id} index={index} isDragDisabled={readonly}>
                    {(provided, snapshot) => {
                        return (<Box m={1}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Accordion key={rule.id}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={rule.id}
                              id={rule.id}
                            >
                              <Typography color={rule.error?"red":""} {...provided.dragHandleProps}>{rule.name} {rule.error?"(Error found in the script)":""}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography variant='h6' gutterBottom component='div' sx={{mb:2}} color='primary'>Rule Id: {rule.id}</Typography>
                              <TextField
                                  sx={{ width: '100%', position: 'relative', mb:2, transition: 'all 0.5s ease', '& .MuiOutlinedInput-root': { padding: '12px 14px',},}}
                                  required
                                  error={rule.name.length<5}
                                  key={rule.id + "_name"}
                                  label="Rule Name"
                                  helperText="Provide a rule name to identify the rule easily (min: 5, max: 20)"
                                  value={rule.name}
                                  InputProps={{ inputProps: { maxLength: 50 } }}
                                  disabled={readonly}
                                  onChange={(e) => {
                                    rule.name = e.target.value;
                                    onUpdateRule ();
                                  }}
                                  variant='standard'
                              />
                              <TextField
                                  sx={{ width: '100%', position: 'relative', mb:2, transition: 'all 0.5s ease', '& .MuiOutlinedInput-root': { padding: '12px 14px',},}}
                                  required
                                  error={rule.description.length<5}
                                  key={rule.id  + "_description"}
                                  label="Rule Description"
                                  helperText="Give a brief description about the rule (min: 5, max: 100)"
                                  value={rule.description}
                                  disabled={readonly}
                                  InputProps={{ inputProps: { minLength: 5, maxLength: 500 } }}
                                  onChange={(e) => {
                                    rule.description = e.target.value;
                                    onUpdateRule ();
                                  }}
                                  multiline
                                  maxRows={4}
                                  variant='standard'
                              />
                              <JSEditor rule={rule} onUpdateRule={onUpdateRule} onInit={onInit} readonly={readonly}/>
                              {!readonly && <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
                                <ColorButton variant="contained" sx={{ height: 30 }}  endIcon={<DeleteIcon />} onClick = {()=>onDeleteRule(rule.id)}>
                                    Delete Rule
                                </ColorButton>
                              </Box>}
                            </AccordionDetails>
                          </Accordion>
                        </Box>)
                      }}
                  </Draggable>
                )
              })
            }
          </Box>
        )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

RuleComponent.propTypes = {
  onInit: PropTypes.func,
  rules: PropTypes.array,
  onAddRule: PropTypes.func.isRequired,
  onUpdateRule: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
  onReOrderRule: PropTypes.func.isRequired,
  readonly: PropTypes.bool
};