import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const DateComponent = ({schema}) => {
    useEffect (()=>{
        setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    }, []);
    return (
        <>
            {schema && !schema.behaviour?.hidden && 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    sx={{
                        width: '100%',
                        position: 'relative',
                        transition: 'all 0.5s ease'
                    }}
                    label={schema.settings.label}
                    value={dayjs (schema.getPropertyValue())}
                    disabled={schema.behaviour?.readonly}
                    onChange={(newValue) => {
                        schema.setPropertyValue (newValue);
                        schema.getParent().__isValid__ ();
                        if (schema.onChange) schema.onChange();
                    }}
                    disablePast={schema.settings.disablePast}
                    slotProps={{
                        textField: {
                            error: !!schema.error,
                            helperText: (schema.settings.help || "") + " " + (schema.error || ""),
                            required: schema.behaviour?.mandatory
                        },
                    }}
                    format={schema.settings.format || "DD/MM/YYYY"}
                />
            </LocalizationProvider>}
        </>
    )
}

export default DateComponent;

DateComponent.propTypes = {
    schema: PropTypes.object.isRequired,
};