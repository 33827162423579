import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import {grey} from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';

const SimpleListItem = ({data,schema}) => {
    return (
    <>
        <ListItem alignItems='flex-start'>
            {data.avatar && <ListItemAvatar>
                <Avatar alt={data.name} src={data.avatar} />
            </ListItemAvatar>}
            <ListItemText
                sx={{
                    '.newline': {
                        "whiteSpace": "pre-wrap",
                        "wordWrap": "break-word"
                    },
                }}
                primary={schema.settings.linkTo?<a href={schema.settings.linkTo + data.id }>{data.name}</a>:
                    schema.settings.href?<Link
                        component='button'
                        onClick={() => {
                            schema.onClick && schema.onClick (data);
                        }}
                    >
                        {data.name}
                    </Link>
                    : data.name
                }
                secondary={
                    <>
                        {data.description && <span className='newline'>{data.description + "\n\n"}</span>}
                        {data.moreInfo?.map ((d, i) => {
                            return (<span key={i}>
                                {d}
                            </span>)
                        })}
                    </>
                }
            />
            {!schema.behaviour.readonly && schema.settings?.action &&
            <Box sx={{ ml: {xs: -4, sm: 'auto'}, mr: {sm: -4}, display: 'flex', alignItems: 'center', justifyContent: {xs: 'space-between', sm: 'flex-end'}, color: 'text.secondary',}} >
              {(schema.settings.action || []).map ((act, index) => {
                return (
                <Box sx={{mr: 5, cursor: 'pointer'}} component='span' key={index} onClick={() => (act.event && schema[act.event] && schema[act.event] (data))}>
                  <Tooltip title={act.label}>
                    <FontAwesomeIcon icon={act.icon?.code} color={act.icon?.color} size={act.icon?.size || "lg"}/>
                  </Tooltip>
                </Box>)
              })}
            </Box>}
            {data.group && <Box  sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: {md: 'center'}, }}>
                <Box sx={{mx: {xs: -1, xl: -2}}}>
                {data.group.map((group, index) => {
                    return (
                    <Chip
                        key={index}
                        label={group.name}
                        sx={{
                            backgroundColor: (theme) =>
                                group.backgroundColor || (theme.palette.type === 'dark'
                                ? theme.palette.grey[700]
                                : theme.palette.grey[200]),
                            color: group.color,
                            padding: '2px',
                            marginTop: 2,
                            marginRight: {sx: 1, xl: 2},
                            marginLeft: {sx: 1, xl: 2},
                            border: '1px solid',
                            borderColor: grey[500],
                            borderRadius: 2,
                        }}
                    />
                    );
                })}
                </Box>
            </Box>}
        </ListItem>
        {data.avatar ? <Divider variant='inset' />: <Divider />}
      </>
  );
};

export default SimpleListItem;

SimpleListItem.propTypes = {
  data: PropTypes.object,
  schema: PropTypes.object.isRequired,
};
